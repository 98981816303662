import React, { useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { useLocation } from "react-router-dom";
import axios from "../../../../axiosinstance";
import Context from "../../../../store/context";
import { useForm } from 'react-hook-form';
import Truliookyc from "./trulioo-kyc";
const KycDetails = () => {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const { register: register2, setValue: setValue2, formState: { errors: errors2 }, handleSubmit: handleSubmit2 } = useForm();
    const location = useLocation();
    let navigate = useNavigate();
    const initialValues = {
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
    };
    const { commonGlobalState } = useContext(Context);
    const { globalState, globalDispatch } = commonGlobalState;
    let [loading, setLoading] = useState(true);
    let [showKyc, setShowKyc] = useState(false);
    let [selfCountry, setSelfCountry] = useState(false);
    const [showActive, setShowActive] = useState();
    const [bottleId, setBottleId] = useState('');
    const [kycToken, setKycToken] = useState('');
    const [conteryError, setConteryError] = useState('');
    const [countryList, setCountryList] = useState([]);
    const [houseAddress, setHouseAddress] = useState([]);
    const [formValues, setFormValues] = useState(initialValues);
    let activeType;
     React.useEffect(() => {
        const [proID, kyc] = location.search.split("?kycToken=");
        const [a, id] = proID.split("?id=");
        setKycToken(kyc)
        let type = localStorage.getItem("type");   
        let oldFormData = localStorage.getItem("formvalue") ? JSON.parse(localStorage.getItem("formvalue")) : "";
       
        activeType=type;
        if (type == 'self') {           
            setValue('country', oldFormData.country)
        }
        if (type == 'delivery') {
            setValue2('address1', oldFormData.address1)
            setValue2('address2', oldFormData.address2)
            setValue2('city', oldFormData.city)
            setValue2('state', oldFormData.state)
            setValue2('country', oldFormData.country)
            setValue2('pincode', oldFormData.pincode)
        }
     
        setShowActive(type);
  
        setBottleId(id);
        getAllCountries();
        getWarehouseAddresses();
        if (kyc == 'undefined') {
            setKycToken('');
            //navigate(location.state ? location.state : `/`);
            navigate(`/kyc?id=${bottleId}`);
            const toasterError = {
                type: "error",
                message: "KYC is not completed. Please Try again ",
            };
            globalDispatch({ type: "TOASTER", state: toasterError });

        }
    }, []);

    function handleOnActive(name) {
        if (!kycToken) {
            setShowActive(name);
            setConteryError('')
        }
    }
    const onSubmit = (data) => {
        submitForm(data, 1)
    }
    const onSubmitDelivery = (data) => {
        submitForm(data, 2)
    };

    const goToKyc = (type) => {
        if (formValues.country) {
            setConteryError('')
            localStorage.setItem("type", type);
            localStorage.setItem("formvalue", JSON.stringify(formValues));
            window.location.href = `/trulioo-kyc?id=${bottleId}`;
        } else {
            setConteryError("Please select the country")
        }

    };

    const submitForm = (data, type) => {
        setLoading(true);
        let payload;
        if (type == 1) {
            payload = {
                kycTransactionId: kycToken,
                addressId: data.country,
                burningRequestType: 1,
                productItemId: Number(bottleId)
            };
        } else {       
            payload = {
                kycTransactionId: kycToken,
                burningRequestType: 2,
                productItemId: Number(bottleId),
                address1: data.address1,
                address2: data.address2,
                city: data.city,
                state: data.state,
                country: data.country,
                pincode: data.pincode,
            };
        }
        axios
            .post(`api/v1/burning`, payload)
            .then((response) => {
                if (response.data.statusCode == 200) {
                    const airDropType =  localStorage.getItem("airDropType")
                    setLoading(false);
                    if(airDropType==1){
                        navigate(`/airdropitem-details?id=${bottleId}`);
                        localStorage.removeItem('airDropType') 
                    }else{
                        navigate(`/item-details?id=${bottleId}`);                   
                    }
                    // navigate(location.state ? location.state : `/`);
                    localStorage.removeItem('type');
                    localStorage.removeItem('formvalue')
                    const toasterError = {
                        type: "success",
                        message: "NFT KYC successfully",
                    };
                    globalDispatch({ type: "TOASTER", state: toasterError });
                }
            })
            .catch((error) => {
                const toasterError = {
                    type: "error",
                    message: error.response.data.message,
                };
                globalDispatch({ type: "TOASTER", state: toasterError });
                setLoading(false);
            });
    }

    const getAllCountries = () => {
        const payload = {
            isShippingEnable: '1'
        };
        axios
            .get(`admin/v1/countries/`, {
                params: payload,
            })
            .then((response) => {
                let data = response.data.data.rows;
                setCountryList(data)
                setLoading(false);
            })
            .catch((error) => {

            });
    };

    const getWarehouseAddresses = () => {
        const payload = {};
        axios
            .get(`api/v1/burning/address/warehouseAddresses`, {
                params: payload,
            })
            .then((response) => {
                let data = response.data.data.rows;
                setHouseAddress(data);
              
                if(activeType=='self'){                  
                    let oldFormData = localStorage.getItem("formvalue") ? JSON.parse(localStorage.getItem("formvalue")) : "";
                    const object = data.filter(obj => Number(obj.id) === Number(oldFormData.country));
                    // setValue(object[0].address1);
                    setSelfCountry(object[0].address1)
                }
                
                setLoading(false);
            })
            .catch((error) => {

            });
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setConteryError('')
    }

    return (
        <>
            <div className="item-details withoutHeader darkGray">
                <div className="tf-section">
                    <div className="smallContainer">
                        <div className="row">
                            <div className="col-6"><a className={`${showActive == "self" ? "btnWrap selected" : "btnWrap"}`} href="javascript:void(0)" onClick={() => handleOnActive('self')}>Self-pickup</a></div>
                            <div className="col-6"><a href="javascript:void(0)" className={`${showActive == "delivery" ? "btnWrap selected" : "btnWrap"}`} onClick={() => handleOnActive('delivery')}>Delivery</a></div>
                        </div>
                        <div className="form-inner kycForm">
                            {showActive === 'self' ? (
                                <>  <form key={1} onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-12 col-md-8">
                                            <div className="form-group position-relative">
                                                <label>Warehouse Address<span className="reqired">*</span></label>
                                                {kycToken ? 
                                                <><input type="text"  value={selfCountry} readOnly/>
                                                <input type="text" name="country" hidden   {...register("country", { required: true })} readOnly /> </>: <select
                                                    disabled={kycToken ? true : false}
                                                    className="w-100 selectDrop"
                                                    name="country"
                                                    placeholder="Select Country"
                                                    {...register("country", { required: true })}
                                                    onChange={(e) => handleChange(e)}
                                                >
                                                    <option value="" selected disabled>Select Country</option>
                                                    {houseAddress &&
                                                        houseAddress.map((item) => (
                                                            <option value={item.id} key={item.id}>
                                                                {item.address1}
                                                            </option>
                                                        ))}
                                                </select>}


                                                {errors.country && <p className="error">Please select the country</p>}
                                                {conteryError && <p className="error">{conteryError}</p>}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="form-group position-relative">
                                                <label>&nbsp;</label>
                                                {kycToken ? <a className="roundBtn roundBtnNew disable">KYC</a> : <a className="roundBtn roundBtnNew" onClick={() => goToKyc('self')}  >KYC</a>}

                                            </div>
                                        </div>
                                    </div>
                                    {kycToken && (<button type='submit'>Submit</button>)}


                                </form>
                                </>
                            ) : ''}
                            {showActive == 'delivery' ? (
                                <form key={2} onSubmit={handleSubmit2(onSubmitDelivery)}>
                                    <p>Currently delivery is available in these countries only. We are adding more countries to the list. If your country is not in the list, please come back and check again.</p>
                                    <div className="row">
                                        <div className="col-12 col-md-8">
                                            <div className="form-group position-relative">
                                                <label>Select Country<span className="reqired">*</span></label>
                                                {kycToken ? <input type="text" name="country"  {...register2("country", { required: true })} readOnly /> : <select
                                                    className="w-100 selectDrop"
                                                    name="country"
                                                    placeholder="Select Country"
                                                    {...register2("country", { required: true })}
                                                    onChange={(e) => handleChange(e)}
                                                >
                                                   <option value="" selected disabled>Select Country</option>
                                                    {countryList &&
                                                        countryList.map((item) => (
                                                            <option value={item.name} key={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                </select>}


                                                {errors2.country && <p className="error">Please select the country</p>}
                                                {conteryError && <p className="error">{conteryError}</p>}
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-4">
                                            <div className="form-group position-relative">
                                                <label>&nbsp;</label>
                                                {kycToken ? <a className="roundBtn roundBtnNew disable">KYC</a> : <a className="roundBtn roundBtnNew" onClick={() => goToKyc('delivery')}  >KYC</a>}

                                            </div>
                                        </div>
                                    </div>
                                    {kycToken ?
                                        <>
                                            <p className="headingKyc"> KYC initiated. Please provide your complete shipping address below</p>
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group position-relative">
                                                        <label>State<span className="reqired">*</span></label>
                                                        <input type="text" name="state"  {...register2("state", { required: true })} onChange={(e) => handleChange(e)} />
                                                        {errors2.state && <p className="error">State is required</p>}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6"> <div className="form-group position-relative">
                                                    <label>City<span className="reqired">*</span></label>
                                                    <input type="text" name="city"  {...register2("city", { required: true })} onChange={(e) => handleChange(e)} />
                                                    {errors2.city && <p className="error">City is required</p>}
                                                </div>
                                                </div>

                                            </div>
                                            <div className="form-group position-relative">
                                                <label>Address 1<span className="reqired">*</span></label>
                                                <input type="text" name="address1"  {...register2("address1", { required: true })} onChange={(e) => handleChange(e)} />
                                                {errors2.address1 && <p className="error">Address1 is required</p>}
                                            </div>
                                            <div className="form-group position-relative">
                                                <label>Address 2<span className="reqired">*</span></label>
                                                <input type="text" name="address2"  {...register2("address2", { required: true })} onChange={(e) => handleChange(e)} />
                                                {errors2.address2 && <p className="error">Address2 is required</p>}
                                            </div>
                                            <div className="form-group position-relative">
                                                <label>Zip / Post Code<span className="reqired">*</span></label>
                                                <input type="text" name="pincode"  {...register2("pincode", { required: true })} onChange={(e) => handleChange(e)} />
                                                {errors2.pincode && <p className="error">Pincode is required</p>}
                                            </div>

                                            <button type='submit'>Submit</button>
                                        </> :
                                        ""
                                    }

                                </form>

                            ) : ''}

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default KycDetails;
