import React, { useState } from "react";
import { Accordion } from "react-bootstrap-accordion";
import axios from "../axiosinstance";
import LoadingOverlay from "react-loading-overlay";
import roadmap from "../assets/images/roadmap/Roadmap.png";

const FaqAccordion = (indexlength) => {
 LoadingOverlay.propTypes = undefined
    const [faqList, setfaqList] = useState([]);
    let [loading, setLoading] = useState(true);
    const [data] = useState([]);

    React.useEffect(() => {
        getFAQ(); 
    }, []);

    const getFAQ = () => {
        axios
            .get(`admin/v1/faq/FAQsByCategoryidGroup`)
            .then((response) => {
              
                if (response.data.data.count > 0) {
                    Object.keys(response.data.data.listing).map((item, i) => {
                        data.push({ key: item, value: response.data.data.listing[item] });
                    });
                    setfaqList(data);
                }
                setLoading(false);
            })
            .catch((error) => {
       
            });
    };

    return (
        <div>
            <LoadingOverlay active={loading} spinner={true} text="Loading...">
                <section className="tf-section wrap-accordion">
                    <div className="themesflat-container">
                        {faqList.length &&
                            faqList.map((item, index) => (
                                <div className="flat-accordion2" key={index}>
                                    {item.value &&
                                        item.value.map((faq, i) => (
                                            <div key={i}>
                                                <Accordion key={i} title={faq.title}>
                                                    {item.key != "Roadmap" && (
                                                        <p>{faq.description}</p>
                                                    )}
                                                    {item.key == "Roadmap" && <img src={roadmap} />}
                                                </Accordion>
                                            </div>
                                        ))}
                                    {/* {index < faqList.length - 1 && (
                                        <div className="saparator"></div>
                                    )} */}
                                </div>
                            ))}
                    </div>
                </section>
            </LoadingOverlay>
        </div>
    );

};

export default FaqAccordion;
