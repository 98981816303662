import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import avt from "../../src/assets/images/box-item/bottle1.jpg";
import PaiverseCommunity from "../components/layouts/home-6/PaiverseCommunity";



const LatestNews = () => {
    const [showModal, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className="darkGray latestNews ">
                <section className=' flat-title-page inner'>
                    <h1 className="heading text-white"> Latest News </h1>
                    <p>Please follow us on social media and keep up to date on the latest news and happenings at Paiverse here.</p>
                </section>

                <div className="themesflat-container flat-title-page">
                    <div className="linkWrap">
                        <h3>What is Paiverse?</h3>
                        <p>
                            <a href="https://medium.com/@paiverse101/what-is-paiverse-c17c36929b6f" target="_blank" className="blueText">https://medium.com/@paiverse101/what-is-paiverse-c17c36929b6f</a>
                        </p>
                    
                    <h3>What is the Paiverse Universe? </h3>
                    <p> <a href="https://medium.com/@paiverse101/what-is-the-paiverse-universe-4a4aec94099c" target="_blank" className="blueText">https://medium.com/@paiverse101/what-is-the-paiverse-universe-4a4aec94099c</a></p>
                   

                    <h3>Twitter Space : AMA with Stephen Davies from Penderyn </h3>
                  
                    <p> <a href="https://twitter.com/_paiverse/status/1591483488834056197" target="_blank" className="blueText">https://twitter.com/_paiverse/status/1591483488834056197</a></p>

                    </div>

                    {/* <h2 className="tf-title pb-24 text-center">Coming Soon</h2>  */}
                    {/* <h2 className="tf-title pb-24 text-left">Paiverse Media</h2> */}
                    {/* <div className='row'>
                            <div className="col-md-4 col-12 mb-5">
                                <div className="productCard">
                                    <div className="d-flex justify-content-center ">
                                        <Link to={''} > <img src={avt} alt='Team Name' onClick={handleShow} /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-12 mb-5">
                                <div className="productCard">
                                    <div className="d-flex justify-content-center ">
                                        <Link to={''} > <img src={avt} alt='Team Name' onClick={handleShow} /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-12 mb-5">
                                <div className="productCard">
                                    <div className="d-flex justify-content-center ">
                                        <Link to={''} > <img src={avt} alt='Team Name' onClick={handleShow} /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>  */}
                    <div className="articles"><br /> <br />
                        {/* <h2 className="tf-title pb-24 text-left">Articles</h2> */}
                        {/* <div className='row'>
                            <div className="col-md-4 col-md-4 col-12 mb-5">
                                <div className="productCard">
                                    <div className="d-flex justify-content-center mb-4">
                                        <Link to={''} > <img src={avt} alt='Team Name' width="422" height="402" onClick={handleShow} /></Link>
                                    </div>
                                    <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis </p>
                                    <p className='date'> 1 October, 2022 </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-md-4 col-12 mb-5">
                                <div className="productCard">
                                    <div className="d-flex justify-content-center mb-4">
                                        <Link to={''} > <img src={avt} alt='Team Name' width="422" height="402" onClick={handleShow} /></Link>
                                    </div>
                                    <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis </p>
                                    <p className='date'> 1 October, 2022 </p>
                                </div>
                            </div>
                            <div className="col-md-4 col-md-4 col-12 mb-5">
                                <div className="productCard">
                                    <div className="d-flex justify-content-center mb-4">
                                        <Link to={''} > <img src={avt} alt='Team Name' width="422" height="402" onClick={handleShow} /></Link>
                                    </div>
                                    <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis </p>
                                    <p className='date'> 1 October, 2022 </p>
                                </div>
                            </div>

                        </div> */}
                    </div>
                </div>
                <div className="tf-section pt-24"><PaiverseCommunity /></div>

            </div>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    {/* <Modal.Title> Latest News Modal </Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <img src={avt} alt='Team Name' width="100%" height="100%" />
                </Modal.Body>
                {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
                Save Changes
            </Button>
            </Modal.Footer> */}
            </Modal>
        </>
    )
}

export default LatestNews