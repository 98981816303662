import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap-accordion'
import PaiverseCommunity from './PaiverseCommunity';
import { Link } from "react-router-dom";

const HomeFaq = () => {
    const [data] = useState(
        [
            {
                key: "0",
                show: "show",
                title: 'What are NFTs?',
                text: 'NFTs (non-fungible tokens) are classes of digital assets stored on a distributed ledger called a blockchain in which each token is unique and identifiable (non-fungible). In our case, each token is backed by a unique, physical asset or collectible and the token is the title and ownership of this physical asset. Because each token is unique, each token also has its own unique value in the marketplace.'
            },
            {
                key: "1",
                title: 'What if I am interested in following my passion with infinite possibilities?',
                text: 'Paiverse gives you an opportunity to own asset-backed NFTs that have been fully verified. You probably already know a rare whisky bottle, a unique piece of art, a timeless classic watch or sports memorabilia has value. We give you the means to own it seamlessly with full industry-standard insurance and logistics provided. The process is simple - you can purchase the NFT, redeem it,  or sell it to someone else.'
            },
            {
                key: "2",
                title: 'Is there financial risk associated with buying and selling NFTs?',
                text: 'YES. Like any asset class, the value of an NFT depends on many factors, including but not limited to, uniqueness, provenance, price, scarcity, and market demand. The value of an NFT may go up and it may go down over time. Before purchasing, all customers participating on the Paiverse platform should do their independent research and due diligence on all NFTs listed here. There is no guarantee that the price you purchased for your NFT will appreciate. It may depreciate. It may stay neutral. Remember, past performance gives no indication of future results.'
            },
            {
                key: "3",
                title: 'How is the NFTs value determined?',
                text: 'As each NFT is bespoke and unique, the value comes from the singularity and exclusive nature of NFTs and the blockchain technology that supports them. Supply and demand dictate what the market/people are willing to pay to buy and sell NFTs which represent tangible REAL assets, while also reducing the probability of fraud and deception.'
            },
            {
                key: "4",
                title: 'NFTs certainly seem pricey, what drives this?',
                text: 'As explained in the previous FAQ, it is the uniqueness and the immutable proof of ownership that makes each NFT exclusive and non-interchangeable. With exclusivity comes great demand in the market, which helps drive the price of an NFT.'
            },
        ]
    )
    return (
        <>
            <section className="tf-section pt-10 wrap-accordion darkGray">
                <div className="themesflat-container">
                    <h2 className="tf-title pb-40">
                        Frequently Asked Questions
                    </h2>
                    <div className="flat-accordion2">
                        {
                            data.map((item, index) => (
                                <Accordion key={index} title={item.title} >
                                    <p>{item.text}</p>
                                </Accordion>
                            ))
                        }
                    </div>
                    <div className="d-flex justify-content-center">
                        <Link to="/faq" className="exploreMore">
                            Explore More
                        </Link>
                    </div>
                </div>
                <div className='mt-40'>
                    <PaiverseCommunity />
                </div>
            </section>
        </>
    )
}

export default HomeFaq