import React, { useState, Fragment, useRef, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SideBar from "../../../components/layouts/home-8/SideBar";
import CardModal from "../CardModal";
import { Dropdown } from "react-bootstrap";
import apiUrl from "../../../store/globalapiurl"
import {s3Url} from "../../../store/baseUrl";
import axios from "../../../axiosinstance";
import imga1 from "../../../assets/images/slider/user.png";
import { ethers } from "ethers";
import Web3 from 'web3';
import Web3Provider from 'react-web3-provider';
import {
  connectMetamask,
  connectCoinbase,
} from "../../../Helpers/contractMethods";
import {
  getContract,
  getContractEscrow,
  contractAddressEscrow,
  getContractSwaping,
  contractAddress,
  ipfsUrl,
  adminAddress
} from "../../../Helpers/contract";
import LoadingOverlay from "react-loading-overlay";
import Context from "../../../store/context";
import SuccessModal from "../SuccessModal";
import MakeOffer from "../MakeOffer";
import LazyMinter from "../../../Helpers/lazyMinter";
import { set } from "react-hook-form";

const ItemContent = () => {
  LoadingOverlay.propTypes = undefined
  const [visible, setVisible] = useState(15);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 5);
  };
  let [releaseBottles, setReleaseBottles] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  const [web3Library, setWeb3Library] = React.useState();
  let [caskFilter, setCaskFilter] = useState([]);
  let [ageFilter, setAgeFilter] = useState([]);
  // let ageFilter=[];
  let [regionFilter, setRegionFilter] = useState([]);
  let [maxPrice, setMaxPrice] = useState([]);
  let [minPrice, setMinPrice] = useState([]);
  let [statusFilter, setStatusFilter] = useState([]);
  let shortByTxt;
  const [shortBy, setShortBy] = useState(0);
  const [web3Account, setWeb3Account] = React.useState();
  const [currentItem, setCurrentItem] = React.useState();
  const [WETHbalance, setWETHbalance] = React.useState(0);
  const [activeFilter, setActiveFilter] = React.useState();
  const [showModal, setShowModal] = React.useState(false);
  let [closeModal, setCloseModal] = React.useState(false);
  let [skip, setSkip] = React.useState(0);
  let [limit, setLimit] = React.useState(9);
  let navigate = useNavigate();
  const location = useLocation();
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch } = commonGlobalState;
  let [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const loginUser = localStorage.getItem("email") || '';

  React.useEffect(() => {
    setLoading(true);
    releaseBottles = [];
    skip = 0;
    setReleaseBottles(releaseBottles);
    setSkip(skip);
    limit = 9;
    setLimit(limit)
    getReleaseBottels();
    ethToDollar();
    return () => {
      releaseBottles = [];
      skip = 0;
      setSkip(skip);
      limit = 9;
      setLimit(limit)
      setReleaseBottles(releaseBottles);
      setShowModal(false);
      setVisible(false);
    };
  }, [location.pathname]);

  const ethToDollar = () => {
    axios
      .get(`api/v1/liveStore/ethtousd`)
      .then((response) => {
        if (response.data.statusCode == 200) {
          localStorage.setItem("priceInETH", response.data.data.price);
          const usdPrice = 1 / response.data.data.price
          localStorage.setItem("priceInUSD", usdPrice);
        }
      })
      .catch((error) => {
      });
  };

  React.useEffect(() => {
    setLoading(true);
    if (globalState.searchQuery || globalState.searchQuery == "") {
      releaseBottles = [];
      skip = 0;
      setReleaseBottles(releaseBottles);
      setSkip(skip);
      setLimit(9)
      //  getReleaseBottels();
    }

    return () => {
      setReleaseBottles([]);
      setSkip(0);
      setLimit(9)
      setShowModal(false);
      setVisible(false);
    };
  }, [globalState.searchQuery]);

  const handleClick = (caskFilterData) => {
    caskFilter = caskFilterData
    setCaskFilter(caskFilter);
    getReleaseBottels();
  };
  // const handleStatus = (status) => {
  //   statusFilter=status;
  //   setStatusFilter()
  //   getReleaseBottels();
  //   // if (status != 3) {
  //   //   statusFilter = status
  //   //   getReleaseBottels();
  //   // }
  // };


  const handleAge = (ageFilterData) => {
    ageFilter = ageFilterData
    setAgeFilter(ageFilter);
    getReleaseBottels();

  };
  const handleRegion = (regionFilterData) => {
    regionFilter = regionFilterData;
    setRegionFilter(regionFilter);
    getReleaseBottels();
  };
  const priceData = (priceFilter) => {
    maxPrice = priceFilter.max ? (priceFilter.max) : ''
    setMaxPrice(maxPrice);
    minPrice = priceFilter.min ? (priceFilter.min) : ''
    setMinPrice(minPrice);
    getReleaseBottels();
  };
  function handleOnActive(name, index) {
    shortByTxt = name
    setShortBy(index);
    getReleaseBottels();
  }
  const getReleaseBottels = (sortDirection, orderby) => {
    setLoading(true);
    const payload = {
      search: globalState.searchQuery ? globalState.searchQuery : "",
      sortDirection: sortDirection,
      skip: 0,
      limit: limit,
    };
    if (caskFilter) payload["cask"] = caskFilter;
    if (ageFilter) payload["age"] = ageFilter;
    if (statusFilter) payload["placeType"] = Number(statusFilter);
    if (regionFilter) payload["region"] = regionFilter;
    if (shortByTxt) payload["orderBy"] = 'sellingAmount';
    if (shortByTxt) payload["sortDirection"] = shortByTxt.toString();
    if (minPrice) payload["minPrice"] = minPrice;
    if (maxPrice) payload["maxPrice"] = maxPrice;
    if (loginUser) {
      payload['email'] = loginUser
    }

    axios
      .get(`api/v1/marketPlace/recentListed`, {
        params: payload,
      })
      .then((response) => {
        setTotalCount(response.data.data.count)
        let data = response.data.data.rows;
        setLoading(false);
        // releaseBottles = [...releaseBottles, ...response.data.data.rows];
        setReleaseBottles(data);
        if (releaseBottles.length < response.data.data.count) {
          setVisible(true);
        } else {
          setVisible(false);
        }
        // let data = response.data.data.rows;
        // setTotalCount(response.data.data.count)
        // setReleaseBottles(...releaseBottles, data);
        if (limit < response.data.data.count) {
          setVisible(true);
        } else {
          setVisible(false);
        }
        window.scrollTo(0, 0)
        setLoading(false);
      })
      .catch((error) => {
      });
  };

  const loadMore = () => {
    skip = skip + 9;
    setSkip(skip);
    limit = limit + 9;
    setLimit(limit);
    getReleaseBottels();
  };

  const approveWETHContract = async (library, account, item, price, days) => {
    try {      
      setLoading(true);
      const myContract = getContractSwaping(library, account);
      console.log("approveWETHContract", approveWETHContract)
      const response = await myContract.approve( 
        adminAddress.toString(),
        ethers.utils.parseEther(price.toString())
      );
      const receipt = await response.wait();
      signatureSign(item, account, price, days);
    } catch (ex) {
      const error = JSON.parse(JSON.stringify(ex));
      if (error.code === "INSUFFICIENT_FUNDS") {
        const toasterError = {
          type: "error",
          message:
            "Your wallet balance is low, please add sufficient ETH to mint",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      } else {
        const toasterError = {
          type: "error",
          message: "User rejected the request.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      }
      setLoading(false);
    }
  };

  const signatureSign = async (item, account, price, days) => {
    let minter = new LazyMinter(contractAddress, account);
    console.log("minter", minter)
    let voucher = await minter.createVoucher(
      item.blockChainNFTId,
      ipfsUrl + item.product.fileHash,
      days,
      ethers.utils.parseEther(price.toString())
    );
    saveBid(item, price, days, voucher, account);
    setLoading(false);
  };

  const saveBid = (item, price, days, voucher, account) => {
    const payload = {
      productItemId: item.id,
      expiredAt: days,
      amount: price,
      signature: voucher ? voucher.signature : undefined,
      fromAddress: account
    };
    axios
      .post(`/api/v1/bid`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          // setShowModal(true);
          setLoading(false);
          navigate(`/marketPlace-item-details?id=${item.id}`);
          const toasterError = {
            type: "success",
            message: "Your offer has been submitted.",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };


  const saveWalletDetails = (walletType, account) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `api/v1/user/connectUserWallet?walletType=${walletType}&walletId=${account}&detail=description`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            localStorage.setItem("activeWallet", walletType);
            resolve(true);
          }
        })
        .catch((error) => {
          const toasterError = {
            type: "error",
            message: error.response.data["message"],
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          setLoading(false);
        });
    });
  };

  const setApprovalForAll = async () => {

    const res = await connectMetamask();
    const myContract = getContract(res.library, res.account);
    const response = myContract.setApprovalForAll(
      // contract address
      adminAddress.toString(),
      "1"
    );
  }


  // bide and mint
  const mintNFT = (item, price, days, proType) => {
    if (globalState.isLoggedIn) {
      const payload = {
        id: item.id,
        productId: item.product.id,
      };
      axios
        .post(`/api/v1/marketPlace/checkOwner`, payload)
        .then((response) => {
          connectWalletHandler(item, price, days, proType);
        })
        .catch((error) => {
          const toasterError = {
            type: "error",
            message: error.response.data["message"],
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          setLoading(false);
        });
    } else {
      navigate(`/login`, { state: location.pathname });
    }
  };

  // mint nft with main contract
  const connectWalletHandler = async (item, price, days, proType) => {
    try {
      const res = await connectMetamask();
      setWeb3Account(res.account);
      setWeb3Library(res.library);
      const resolve = await saveWalletDetails("MetaMask", res.account);
      if (resolve) {
        if (proType == 'buy') {
          setLoading(true);
          buyNFT(res.library, res.account, item);
        }
        else {
       
          approveWETHContract(res.library, res.account, item, price, days);
        }
      }
    } catch (e) {
      const toasterError = {
        type: "error",
        message: "Please connect a different wallet address",
      };
      globalDispatch({ type: "TOASTER", state: toasterError });
    }
  };

  const buyNFT = async (library, account, item) => {
    setLoading(true);
    const web3 = new Web3(Web3.givenProvider);
    try {
      const paymenTransfer = await web3.eth.sendTransaction({ to: adminAddress.toString(), from: account.toString(), value: web3.utils.toWei(item.sellingAmount.toString(), "Ether") });  
      if (paymenTransfer) {
        payment(paymenTransfer, item, item.toAddress, account);     
      }
    } catch (ex) {
      const error = JSON.parse(JSON.stringify(ex));
      setLoading(false);
    }
  };

  // store blockchain transaction in the API
  const payment = (paymentData, item, toAddress, fromAddress) => {
    const payload = {
      id: item.id,
      productId: item.product.id,
      blockChainTransactionId: paymentData.transactionHash,
      blockChainTransactionStatus: paymentData.status ? "SUCCESS" : "FAIL",
      from: fromAddress,
      currency: "ETH",
      to: toAddress,
      gasAmount: paymentData.gasUsed,
      conversionAmount: item.sellingAmount,
    };

    axios
      .post(`/api/v1/user/payment`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          // setShowModal(true);
          setLoading(false);
          navigate("/my-collection");
          const toasterError = {
            type: "success",
            message:
              "Item purchased successfully. Please click on my collection below to view  your purchased product.",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const ETHtoUSDPrice = (value) => {
    // const ethPrice = localStorage.getItem("priceInETH") || 0;
    const ethPrice = localStorage.getItem("priceInUSD") || 0;
    const price = value * ethPrice;
    return price.toFixed(6);
  };

  const getData = (data) => {
    if (data) {
      setModalShow(false);
      mintNFT(currentItem, data.price, data.days, 'bid');
    }
  };

  const closePopup = () => {
    setModalShow(false);
  };

  const bidProduct = (item, balance) => {
    setModalShow(true);
    setCurrentItem(item);
    setWETHbalance(balance.toFixed(4));
    setLoading(false);
  };   


  // bid===
  const ETHtoWETH = async (item) => {
    if (globalState.isLoggedIn) {
      setLoading(true);
      const payload = {
        id: item.id,
        productId: item.product.id,
      };
      axios
        .post(`/api/v1/marketPlace/checkOwner`, payload)
        .then((response) => {
          checkWETH(item)
        })
        .catch((error) => {
          const toasterError = {
            type: "error",
            message: error.response.data["message"],
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          setLoading(false);
        });
    } else {
      navigate(`/login`, { state: `/marketplace` });
    }
  };
  const checkWETH = async (item) => {
    setLoading(true);
    try {
      const res = await connectMetamask();
      setWeb3Account(res.account);
      setWeb3Library(res.library);
      ETHtoWETHContract(res.library, res.account, item);
    } catch (e) {
    }
  }

  const WethBalance = async (myContract, account, item) => {
    const response = await myContract.balanceOf(account);
    const balance = Math.round(
      parseFloat(ethers.utils.formatUnits(response["_hex"], 18)) * 10 ** 18
    );
    bidProduct(item, balance / 1000000000000000000);
  };

  const ETHtoWETHContract = async (library, account, item) => {
    try {
      const myContract = getContractSwaping(library, account);
      const overrides = {
        value: ethers.utils.parseEther("0.1"),
      };
      WethBalance(myContract, account, item);

    } catch (ex) {
      const error = JSON.parse(JSON.stringify(ex));
      if (error.code === "INSUFFICIENT_FUNDS") {
        const toasterError = {
          type: "error",
          message:
            "Your wallet balance is low, please add sufficient ETH to mint",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      } else {
        const toasterError = {
          type: "error",
          message: "User rejected the request.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      }
      setLoading(false);
    }
  };


  return (
    <Fragment>
      <div className="row">
        <div className="col-box-17">
          {/* <SideBar handleFilter={handleFilter} />  handleStatus={handleStatus} */}
          <SideBar handleClick={handleClick} handleAge={handleAge} handleRegion={handleRegion} priceData={priceData}  />
        </div>
        <div className="col-box-83">
          <div className="themesflat-container">
            <div className="d-flex fliterItem">
              <h3>{totalCount != 0 ? totalCount : ''} {totalCount == '0' ? '' : (totalCount == '1' ? 'Item' : 'Items')}  </h3>
              {totalCount != 0 ? <div className="shortByWrap">
                {/* <div className="toggleButton" id="button-13">
                  <input type="checkbox" className="checkbox" /><div className="knobs"><span></span></div><div className="layer"></div></div> */}
                <div className="view show dropdown">
                  <Dropdown align="end" title="Dropdown end" id="dropdown-menu-align-end"  >
                    <Dropdown.Toggle
                      id="dropdown-menu-align-end"
                      className="btn-sort-by dropdown"
                    ><span>Sort BY</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ margin: 0 }} className="sortBymenu">
                      <Dropdown.Item className={shortBy === '1' ? ('sortActive') : ''} onClick={() => handleOnActive('ASC', '1')}>
                        <span className="radioSpan"></span> Price: Low to High</Dropdown.Item>
                      <Dropdown.Item className={shortBy == '2' ? ('sortActive ') : ''} onClick={() => handleOnActive('DESC', '2')}><span className="radioSpan"></span>Price: High to Low</Dropdown.Item>
                      {/* <Dropdown.Item href="#"><span className="radioSpan"></span>Popularity</Dropdown.Item> */}
                      {/* <Dropdown.Item className={shortBy == '3' ? ('sortActive ') : ''} onClick={() => handleOnActive('DESC', '3')}><span className="radioSpan"></span>Recent</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div> : ''}

            </div>
            <LoadingOverlay active={loading} spinner={true} text="Loading...">
              <div className="row">
                {releaseBottles.map((item, key) => (

                  <div key={key} className="col-xl-4 col-sm-6 col-12">
                    <div className="sc-card-product height100">
                      {/* <div className="meta-info style">
                    <button className="wishlist-button heart wishlist-button-release"></button>
                  </div> */}
                      <div className="card-media">
                        <Link to={`/marketPlace-item-details?id=${item.id}`}>
                          <img
                            src={`${s3Url}icons/${item?.product?.imageUrl}`}
                            alt="Axies"
                          />
                        </Link>

                      </div>
                      <div className="card-title d-flex">
                        <div className="left">
                          <h3>
                            <Link
                              to={`/marketPlace-item-details?id=${item.id}`}
                            >
                              {item.product?.brand?.name} {item.product.title}
                            </Link>
                          </h3>
                          {item.placeType == 0 && (
                            <div className="price">Price {item.sellingAmount} ETH
                              {/* <span>({ETHtoUSDPrice(item.sellingAmount)} ETH)</span> */}
                            </div>
                          )}

                        </div>
                        <div className="button-place-bid">

                          {item?.bids != 1 ?
                            <button
                              onClick={() =>
                                item.placeType
                                  ?
                                  //setModalShow(true)
                                  ETHtoWETH(item)
                                  : mintNFT(item, 'price', 'days', 'buy')
                              }

                              className={`tags`}
                            >
                              <span>{item.placeType ? ("BID") : "BUY"} </span>
                            </button>
                            :''
                            }


                          {/* <button
                            onClick={() =>setApprovalForAll()}>
                            set approve all
                          </button> */}

                        </div>
                      </div>
                      <div className="meta-info">
                        <div className="author">
                          <div className="avatar">
                            <img src={item.buyers.profilePic ? (`${s3Url}icons/${item?.buyers?.profilePic}`) : imga1} alt="axies" />
                          </div>
                          <div className="info"><span>Owner</span>Owner
                            <h4>
                              <Link className="firstLetterCap" to={`/marketPlace-item-details?id=${item.id}`}>{item?.buyers.userName}</Link>
                            </h4>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>

                ))}
              </div>
              {visible && (
                <div className="d-flex justify-content-center">
                  <Link
                    to="#"
                    className="exploreMore"
                    onClick={loadMore}
                  >
                    <span>Explore More</span>
                  </Link>
                </div>
              )}

              {releaseBottles && !releaseBottles?.length && !loading && (
                <div className="noData">
                  No result found
                </div>
              )}

              <MakeOffer
                show={modalShow}
                sendPrice={getData}
                closeModal={closePopup}
                balance={WETHbalance}
              />
            </LoadingOverlay>
          </div>
        </div>
      </div>

      <div className="paymentLoader">
        <div className="innerDivr">
          <div className="pLoader"></div>
        </div>
      </div>

    </Fragment>
  );
};

export default ItemContent;
