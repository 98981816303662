import React, { useState, Fragment } from "react";
import prodimg from "./../../../assets/images/product_details/img_bottle2.png"
import { Link } from "react-router-dom";
const HomeActiveity = () => {
    return (
        <Fragment>
            <section className="tf-section darkGray">
                <div className="themesflat-container">
                    <h2 className="tf-title pb-40 text-left">Activity</h2>

                    <p className="text-center">No current activity</p>
                    {/* <div className="tableWrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>NFT</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Event</th>
                                    <th> Price <div className="toggleButton" id="button-13">
                                        <input type="checkbox" className="checkbox" />
                                        <div className="knobs">
                                            <span></span>
                                        </div>
                                        <div className="layer"></div>
                                    </div></th>
                                    <th>Date</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    <td>
                                    <img src={prodimg} />
                                        My Grain #11
                                    </td>
                                    <td>
                                        @cobble
                                    </td>
                                    <td>
                                        @MMIwhiskey
                                    </td>
                                    <td>
                                        @MMIwhiskey
                                    </td>
                                    <td>0.13 ETH</td>
                                    <td>48 minutes ago</td>

                                </tr>
                                <tr >
                                    <td>
                                    <img src={prodimg} />
                                        My Grain #11
                                    </td>
                                    <td>
                                        @cobble
                                    </td>
                                    <td>
                                        @MMIwhiskey
                                    </td>
                                    <td>
                                        @MMIwhiskey
                                    </td>
                                    <td>0.13 ETH</td>
                                    <td>48 minutes ago</td>

                                </tr>
                                <tr >
                                    <td>
                                    <img src={prodimg} />
                                        My Grain #11
                                    </td>
                                    <td>
                                        @cobble
                                    </td>
                                    <td>
                                        @MMIwhiskey
                                    </td>
                                    <td>
                                        @MMIwhiskey
                                    </td>
                                    <td>0.13 ETH</td>
                                    <td>48 minutes ago</td>

                                </tr>
                                <tr >
                                    <td>
                                    <img src={prodimg} />
                                        My Grain #11
                                    </td>
                                    <td>
                                        @cobble
                                    </td>
                                    <td>
                                        @MMIwhiskey
                                    </td>
                                    <td>
                                        @MMIwhiskey
                                    </td>
                                    <td>0.13 ETH</td>
                                    <td>48 minutes ago</td>

                                </tr>
                                <tr >
                                    <td>
                                    <img src={prodimg} />
                                        My Grain #11
                                    </td>
                                    <td>
                                        @cobble
                                    </td>
                                    <td>
                                        @MMIwhiskey
                                    </td>
                                    <td>
                                        @MMIwhiskey
                                    </td>
                                    <td>0.13 ETH</td>
                                    <td>48 minutes ago</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Link to="#" className="exploreMore">
                            Explore More
                        </Link>
                    </div> */}
                </div>
            </section>

        </Fragment>
    );
};

export default HomeActiveity;
