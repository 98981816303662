import React, {
  forwardRef,
  useImperativeHandle,
} from "react";
import { ToastContainer } from "react-toastr";
import "toastr/build/toastr.css";
import "animate.css/animate.css";
const ToasterContainer = forwardRef((props, ref) => {
  let topContainer;

  const notify = (type, msg) => {
    topContainer?.state.toastList.splice(0,1)
    type == "success"
      ? topContainer.success("", msg)
      : topContainer.error("", msg);
  };

  useImperativeHandle(ref, () => ({
    showToast(type, msg) {
      notify(type, msg);
    },
  }));
  return (
    <React.Fragment>
      <ToastContainer
        className="toast-top-right"
        ref={(ref) => (topContainer = ref)}
      />
    </React.Fragment>
  );
});
export default ToasterContainer;
