import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import MarketFaq from "./marketplaceFaq";
import { useLocation } from "react-router-dom";
import imga1 from "../assets/images/slider/user.png";
import { ethers } from "ethers";
import Web3 from 'web3';
import LazyMinter from "../Helpers/lazyMinter";
import Web3Provider from 'react-web3-provider';
import { s3Url } from "../store/baseUrl";
import { connectMetamask, connectCoinbase } from "../Helpers/contractMethods";
import {
  etherScanUrl, contractAddressEscrow, getContract, getContractEscrow, getContractSwaping, contractAddress, adminAddress,
  contractAbi, ipfsUrl
} from "../Helpers/contract";

import axios from "../axiosinstance";
import LoadingOverlay from "react-loading-overlay";
import Context from "../store/context";
import * as moment from "moment";
import ListToMarketPlace from "../components/layouts/ListToMarketplace";
import MakeOffer from "../components/layouts/MakeOffer";

const MarketPlaceItemDetails = () => {
  LoadingOverlay.propTypes = undefined
  const loginUser = localStorage.getItem("email") || '';
  const loginID = localStorage.getItem("userID") || '';
  const location = useLocation();
  let navigate = useNavigate();
  let [releaseBottles, setReleaseBottles] = useState([]);
  let [offerData, setOfferData] = useState([]);
  // let offerData = [];
  const { commonGlobalState } = useContext(Context);
  const [currentItem, setCurrentItem] = React.useState();
  const [WETHbalance, setWETHbalance] = React.useState(0);
  const { globalState, globalDispatch } = commonGlobalState;
  let [loading, setLoading] = useState(true);
  const [web3Library, setWeb3Library] = React.useState();
  const [web3Account, setWeb3Account] = React.useState();
  const [releaseBottleDetail, setReleaseBottleDetails] = useState([]);

  const [bottleId, setBottleId] = useState();
  const [modalShow, setModalShow] = useState(false);

  React.useEffect(() => {
    const [a, id] = location.search.split("?id=");
    setBottleId(id);
    getReleaseBottelsDetails(id);
    getOfferDetails(id);
  }, []);

  const getReleaseBottelsDetails = (id) => {
    const payload = {}
    if (loginUser) {
      payload['email'] = loginUser
    }
    axios
      .get(`api/v1/marketPlace/${id}`, {
        params: payload,
      })
      .then((response) => {
        getOwnerDetails(id, response.data.data.ownerId)
        setReleaseBottleDetails(response.data.data);

      })
      .catch((error) => {

      });
  };

  const getOfferDetails = (id) => {
    setLoading(true);
    const payload = {
      'productItemId': id
    }
    axios
      .get(`api/v1/bid/`, {
        params: payload,
      })
      .then((response) => {
        offerData = response.data.data;
        setOfferData(offerData);

        setLoading(false);
      })
      .catch((error) => {

      });
  };

  const getOwnerDetails = (id, ownerId) => {
    const payload = {
      'ownerId': ownerId,
      'productItemId': id
    };
    axios
      .get(`api/v1/marketPlace/recentListed`, {
        params: payload,
      })
      .then((response) => {
        let data = response.data.data.rows;
        setReleaseBottles(data);

        setLoading(false);

      })
      .catch((error) => {

      });
  };

  const saveWalletDetails = (walletType, account) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `api/v1/user/connectUserWallet?walletType=${walletType}&walletId=${account}&detail=description`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            localStorage.setItem("activeWallet", walletType);
            resolve(true);
          }
        })
        .catch((error) => {
          resolve(true);
        });
    });
  };

  const goToanotherProduct = (id) => {
    setBottleId(id);
    getReleaseBottelsDetails(id);
    getOfferDetails(id);
    navigate(`/marketPlace-item-details?id=${id}`);

  }
  // bid===
  const ETHtoWETH = async (item) => {
    if (globalState.isLoggedIn) {
      setLoading(true);
      const payload = {
        id: item.id,
        productId: item.product.id,
      };
      axios
        .post(`/api/v1/marketPlace/checkOwner`, payload)
        .then((response) => {
          checkWETH(item)
        })
        .catch((error) => {
          const toasterError = {
            type: "error",
            message: error.response.data["message"],
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          setLoading(false);
        });
    } else {
      navigate(`/login`, { state: `/marketPlace-item-details?id=${item.id}` });
    }
  };
  const checkWETH = async (item) => {
    setLoading(true);
    try {
      const res = await connectMetamask();
      setWeb3Account(res.account);
      setWeb3Library(res.library);
      ETHtoWETHContract(res.library, res.account, item);
    } catch (e) {
    }
  }

  const ETHtoWETHContract = async (library, account, item) => {
    try {
      const myContract = getContractSwaping(library, account);
      const overrides = {
        value: ethers.utils.parseEther("0.1"),
      };
      WethBalance(myContract, account, item);

    } catch (ex) {
      const error = JSON.parse(JSON.stringify(ex));
      if (error.code === "INSUFFICIENT_FUNDS") {
        const toasterError = {
          type: "error",
          message:
            "Your wallet balance is low, please add sufficient ETH to mint",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      } else {
        const toasterError = {
          type: "error",
          message: "User rejected the request.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      }
      setLoading(false);
    }
  };
  const WethBalance = async (myContract, account, item) => {
    const response = await myContract.balanceOf(account);
    const balance = Math.round(
      parseFloat(ethers.utils.formatUnits(response["_hex"], 18)) * 10 ** 18
    );
    bidProduct(item, balance / 1000000000000000000);
  };
  const bidProduct = (item, balance) => {
    setModalShow(true);
    setCurrentItem(item);
    setWETHbalance(balance.toFixed(4));
    setLoading(false);
  };

  // mint nft with main contract
  const connectWalletHandler = async (item, price, days, proType) => {
    try {
      const res = await connectMetamask();
      setWeb3Account(res.account);
      setWeb3Library(res.library);
      const resolve = await saveWalletDetails("MetaMask", res.account);
      if (resolve) {
        if (proType == 'buy') {
          setLoading(true);
          buyNFT(res.library, res.account, item);
        }
        else {
          approveWETHContract(res.library, res.account, item, price, days);
        }
      }
    } catch (e) {
      const toasterError = {
        type: "error",
        message: "Please connect a different wallet address",
      };
      globalDispatch({ type: "TOASTER", state: toasterError });
    }
  };
  const approveWETHContract = async (library, account, item, price, days) => {
    const web3 = new Web3(Web3.givenProvider);

    try {
      setLoading(true);
      const myContract = getContractSwaping(library, account);
      // let price = 1;
      const response = await myContract.approve(
        adminAddress.toString(),
        ethers.utils.parseEther(price.toString())
      );
      const receipt = await response.wait();
      signatureSign(item, account, price, days);
    } catch (ex) {
      const error = JSON.parse(JSON.stringify(ex));
      if (error.code === "INSUFFICIENT_FUNDS") {
        const toasterError = {
          type: "error",
          message:
            "Your wallet balance is low, please add sufficient ETH to mint",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      } else {
        const toasterError = {
          type: "error",
          message: "User rejected the request.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      }
      setLoading(false);
    }
  };

  const signatureSign = async (item, account, price, days) => {
    let minter = new LazyMinter(contractAddress, account);
    let voucher = await minter.createVoucher(
      item.blockChainNFTId,
      ipfsUrl + item.product.fileHash,
      days,
      ethers.utils.parseEther(price.toString())
    );
    saveBid(item, price, days, voucher, account);
    setLoading(false);
  };

  const buyNFT = async (library, account, item) => {
    setLoading(true);
    const web3 = new Web3(Web3.givenProvider);
    try {
      const paymenTransfer = await web3.eth.sendTransaction({ to: adminAddress.toString(), from: account.toString(), value: web3.utils.toWei(item.sellingAmount.toString(), "Ether") });

      if (paymenTransfer) {
        payment(paymenTransfer, item, item.toAddress, account);
      }
    } catch (ex) {
      const error = JSON.parse(JSON.stringify(ex));
      setLoading(false);
    }
  };

  // store blockchain transaction in the API
  const payment = (paymentData, item, toAddress, fromAddress) => {
    const payload = {
      id: item.id,
      productId: item.product.id,
      blockChainTransactionId: paymentData.transactionHash,
      blockChainTransactionStatus: paymentData.status ? "SUCCESS" : "FAIL",
      from: fromAddress,
      currency: "ETH",
      to: toAddress,
      gasAmount: paymentData.gasUsed,
      conversionAmount: item.sellingAmount,
    };
    axios
      .post(`/api/v1/user/payment`, payload)
      .then((response) => {

        if (response.data.statusCode == 200) {
          // setShowModal(true);
          setLoading(false);
          navigate("/my-collection");
          const toasterError = {
            type: "success",
            message:
              "Item purchased successfully. Please click on my collection below to view your purchased product.",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const mintNFT = (item, price, days, proType) => {

    if (globalState.isLoggedIn) {
      const payload = {
        id: item.id,
        productId: item.product.id,
      };
      axios
        .post(`/api/v1/marketPlace/checkOwner`, payload)
        .then((response) => {
          connectWalletHandler(item, price, days, proType);
        })
        .catch((error) => {
          const toasterError = {
            type: "error",
            message: error.response.data["message"],
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          setLoading(false);
        });
    } else {
      navigate(`/login`, { state: `/marketPlace-item-details?id=${item.id}` });
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }
  const ETHtoUSDPrice = (value) => {
    const ethPrice = localStorage.getItem("priceInUSD") || 0;
    const price = value * ethPrice;
    return price.toFixed(6);
  };

  const getData = (data) => {
    if (data) {
      setModalShow(false);

      mintNFT(currentItem, data.price, data.days);
    }
  };

  const closePopup = () => {
    setModalShow(false);
  };

  const saveBid = (item, price, days, voucher, account) => {
    const payload = {
      productItemId: item.id,
      expiredAt: days,
      amount: price,
      signature: voucher ? voucher.signature : undefined,
      fromAddress: account
    };
    axios
      .post(`/api/v1/bid`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          // setShowModal(true);
          setLoading(false);
          getReleaseBottelsDetails(bottleId);
          getOfferDetails(item.id);
          // navigate(`/marketPlace-item-details?id=${item.id}`);
          const toasterError = {
            type: "success",
            message: "Your offer has been submitted.",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const cancelBid = async (item) => {
    setLoading(true);
    axios
      .delete(`/api/v1/bid`, { data: { 'bidId': item.id } })
      .then((response) => {
        if (response.data.statusCode == 200) {
          const toasterError = {
            type: "success",
            message: "Bid Cancelled.",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          getReleaseBottelsDetails(bottleId);
          getOfferDetails(bottleId);

        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const acceptBid = async (item) => {
    setLoading(true);
    const payload = {
      bidId: item.id
    };
    axios
      .put(`/api/v1/bid`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {

          bidTransfer(item, response.data?.data?.transaction.id)
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  const bidTransfer = async (item, transactionID) => {
    const res = await connectMetamask();
    const myContract = getContract(res.library, res.account);

    try {
      const tokentTransfer = await myContract['safeTransferFrom(address,address,uint256)'](res.account.toString(), item.fromAddress.toString(), releaseBottleDetail.blockChainNFTId)

      if (tokentTransfer) {
        bidDone(item.id, transactionID, tokentTransfer)
        console.log(tokentTransfer, "tokentTransfer");
        //bidDone() 
      }
    } catch (ex) {
      const error = JSON.parse(JSON.stringify(ex));
      setLoading(false);
    }
  }

  const bidDone = async (id, transactionID, data) => {
    setLoading(true);
    const payload = {
      bidId: id,
      transactionId: transactionID,
      tokenTransferHash: data.hash
    };
    axios
      .post(`/api/v1/bid/transferToken`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          setLoading(false);
          navigate(`/marketplace`);
          const toasterError = {
            type: "success",
            message: "offer has been accepted.",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          getReleaseBottelsDetails(bottleId);
          getOfferDetails(bottleId);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  return (
    <div className="item-details ProductDetailsOne withoutHeader">
      <LoadingOverlay active={loading} spinner={true} text="Loading...">
        <div className="tf-section ">
          <div className="themesflat-container">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="content-left">
                  <div className="imgBox">
                    {/* <video width="750" height="500" autoPlay muted>
                      <source src={video} type="video/mp4" />
                    </video> */}
                    <img
                      src={`${s3Url}icons/${releaseBottleDetail &&
                        releaseBottleDetail.product &&
                        releaseBottleDetail.product.imageUrl
                        }`}
                      alt="Axies"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="contBox">
                  <div className="sc-item-details">
                    <h2 className="style2">
                      {releaseBottleDetail &&
                        releaseBottleDetail.product?.brand
                        && capitalizeFirstLetter(releaseBottleDetail.product?.brand.name)
                      } {releaseBottleDetail &&
                        releaseBottleDetail.product && capitalizeFirstLetter(releaseBottleDetail.product.title)
                      }
                    </h2>

                    <p>
                      {releaseBottleDetail &&
                        releaseBottleDetail.product &&
                        releaseBottleDetail.product.description}
                    </p>
                    <div className="meta-item-details style2">
                      <div className="row">
                        <div className="col-6">
                          <div className="item meta-price w-100">
                            <span className="heading">Price</span>
                            <div className="price">
                              <div className="price-box d-flex align-items-center">
                                <h5>
                                  {releaseBottleDetail &&
                                    releaseBottleDetail.sellingAmount}
                                  &nbsp; ETH
                                </h5>
                                {/* <span>
                              (
                              {ETHtoUSDPrice(
                                releaseBottleDetail &&
                                  releaseBottleDetail.product &&
                                  releaseBottleDetail.product.price
                              )}
                              &nbsp;ETH)
                            </span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="item meta-price w-100">
                            <div className="price">
                              <div className="price-box d-flex align-items-center">
                                <h5>
                                  NFT ID #{releaseBottleDetail.blockChainNFTId}
                                </h5>
                              </div>
                            </div>
                            <a className="viewBtnEth" href={`${etherScanUrl}${contractAddress}?a=${releaseBottleDetail.blockChainNFTId}`} target="_blank" tr> View on Ethersan</a>
                          </div>

                        </div>
                      </div>

                    </div>

                    {releaseBottleDetail?.bids != 1 ? <button
                      // onClick={() => mintNFT(bottleId)}
                      onClick={() =>
                        releaseBottleDetail.placeType
                          ? //setModalShow(true)
                          ETHtoWETH(releaseBottleDetail)
                          : mintNFT(releaseBottleDetail, 'price', 'days', 'buy')
                      }
                      // disabled={true}
                      className="roundBtn width300 mr-5"
                    >
                      <span>
                        {releaseBottleDetail.placeType ? "BID" : "Buy It Now"}
                      </span>
                    </button> :
                      ''
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className='ownerList tf-section'>
          <div className="themesflat-container">
            <div className='row'>
              <div className='col-md-6 col-12'>
                <MarketFaq />
              </div>
              <div className="col-md-6 col-12">
                <div className="marketDetailsListing ">
                  {releaseBottleDetail.placeType == 1 && (<div className="offerWrap ">
                    <h3 className="text-white subheading">Offers</h3>
                    <div className="tableWrapper">
                      <table>
                        <thead>
                          <tr>
                            <th>Owner</th>
                            <th>Price</th>
                            <th>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {offerData.map((item, key) => (
                            <tr key={key}>
                              <td>
                                {capitalizeFirstLetter(item?.productBidUser.userName)}
                              </td>
                              <td>
                                {item.amount} ETH
                              </td>
                              <td>
                                {item?.productItem?.productOwner.id === Number(loginID) ? <button className="offerBtn" onClick={() => acceptBid(item)}>Make an offer</button>
                                  : ''}

                                {item?.productBidUser?.id === Number(loginID) ? <button className="cancelBtn" onClick={() => cancelBid(item)}>Cancel</button>
                                  : ''}
                              </td>
                            </tr>
                          ))}

                        </tbody>
                      </table>
                      {!offerData?.length && !loading && (

                        <div className="noData">
                          No data.
                        </div>
                      )}
                    </div>

                  </div>)}

                  <h3 className="text-white subheading">Listing by the owner</h3>
                  <div className="row">
                    {releaseBottles.map((item, key) => (
                      <div key={key} className="col-sm-4  col-12">
                        <div className="sc-card-product height100">
                          <div className="card-media" >
                            <a className="pointer" onClick={() => goToanotherProduct(item?.id)} >
                              <img
                                src={`${s3Url}icons/${item?.product?.imageUrl}`}
                                alt="Axies"
                              />
                            </a>
                          </div>
                          <div className="card-title d-flex">
                            <a className="pointer" onClick={() => goToanotherProduct(item?.id)} >
                              <div className="left">
                                <h3>
                                  <a>{item.product.title}</a>
                                </h3>
                                {item.placeType == 0 && (
                                  <div class="price">Price {item.sellingAmount} ETH
                                    {/* <span>({ETHtoUSDPrice(item.sellingAmount)} ETH)</span> */}
                                  </div>
                                )}

                              </div>
                            </a>
                            {/* <div className="button-place-bid">
                              <button
                                onClick={() =>
                                  item.placeType
                                    ? 
                                    setModalShow(true)
                                    : mintNFT(item)
                                }

                                className={`tags`}
                              >
                                <span>{item.placeType ? ("BID") : "BUY"} </span>
                              </button>
                            </div> */}
                          </div>
                          <div className="meta-info">
                            <div className="author">
                              <div className="avatar">
                                <img src={item.buyers.profilePic ? (`${s3Url}icons/${item?.buyers?.profilePic}`) : imga1} alt="axies" />
                              </div>
                              <div class="info"><span>Owner</span>Owner
                                <h4>
                                  <a className="firstLetterCap">{item?.buyers.userName}</a>
                                </h4>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    ))}

                  </div>
                  {releaseBottles && !releaseBottles?.length && !loading && (

                    <div className="noData">
                      No data.
                    </div>
                  )}.

                </div>

              </div>

            </div>
          </div>
        </section>

        <MakeOffer
          show={modalShow}
          sendPrice={getData}
          closeModal={closePopup}
          balance={WETHbalance}
        />
      </LoadingOverlay>
    </div>
  );
};

export default MarketPlaceItemDetails;
