import React, { useState, Fragment, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "../../../axiosinstance";
import LoadingOverlay from "react-loading-overlay";
import Context from "../../../store/context";
import prodimg from "./../../../assets/images/product_details/img_bottle2.png";
import {s3Url} from "../../../store/baseUrl";
const MarketPlaceCollection = () => {
   LoadingOverlay.propTypes = undefined
  let [marketPlaceBottles, setMarketPlaceBottles] = React.useState([]);
  let [skip, setSkip] = React.useState(0);
  let navigate = useNavigate();
  const location = useLocation();
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch } = commonGlobalState;
  let [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  React.useEffect(() => {
    setLoading(true);
    marketPlaceBottles = [];
    skip = 0;
    setMarketPlaceBottles(marketPlaceBottles);
    setSkip(skip);
    getMarketplaceCollection();
    return () => {
      marketPlaceBottles = [];
      skip = 0;
      setMarketPlaceBottles(marketPlaceBottles);
      setSkip(skip);
      setVisible(false);
    };
  }, [location.pathname]);

  React.useEffect(() => {
    setLoading(true);
    if (globalState.searchQuery || globalState.searchQuery == "") {
      marketPlaceBottles = [];
      skip = 0;
      setMarketPlaceBottles(marketPlaceBottles);
      setSkip(skip);
      getMarketplaceCollection();
    }

    return () => {
      setSkip(0);
      setMarketPlaceBottles([]);
      setVisible(false);
    };
  }, [globalState.searchQuery]);

  const loadMore = () => {
    skip = skip + 8;
    setSkip(skip);
    getMarketplaceCollection();
  };

  const ETHtoUSDPrice = (value) => {
    const ethPrice = localStorage.getItem("priceInUSD") || 0;
    const price = value * ethPrice;
    return price.toFixed(6);
  };

  const getMarketplaceCollection = () => {
    const payload = {
      search: globalState.searchQuery ? globalState.searchQuery : "",
      listed: 1,
      skip: skip,
      limit: 8,
    //   buyType: "",
    };
    axios
      .get(`api/v1/user/owned`, {
        params: payload,
      })
      .then((response) => {
        marketPlaceBottles = [
          ...marketPlaceBottles,
          ...response.data.data.rows,
        ];
        setMarketPlaceBottles(marketPlaceBottles);
        if (marketPlaceBottles.length < response.data.data.count) {
          setVisible(true);
        } else {
          setVisible(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          const toasterError = {
            type: "error",
            message: error.response.data["message"],
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          localStorage.clear();
          navigate(`/login`);
        }
      });
  };

  return (
    <LoadingOverlay active={loading} spinner={true} text="Loading...">
      <div className="mycollactionHTML collection">
        <div className="row">
          {marketPlaceBottles.map((item, key) => (
            <div key={key} className="col-xl-3 col-md-4 col-sm-6 col-12">
              <div className="sc-card-product">
                <div className="meta-info style">
                  <button className="wishlist-button heart wishlist-button-release"></button>
                </div>
                <div className="card-media">
                  <Link to={`/marketPlace-details?id=${item.id}`}>
                    <img
                      src={`${s3Url}icons/${item.product.imageUrl}`}
                      alt="Axies"
                    />
                  </Link>
                </div>
                <div className="card-title">
                  <h3>
                    <Link to={`/marketPlace-details?id=${item.id}`}>
                      {item.product.title}
                    </Link>
                  </h3>
                  <div className="price">
                    Price {item.sellingAmount} ETH
                    {/* <span>({item.sellingAmount} ETH){" "} 
                    </span>*/}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {visible && (
            <div className="col-md-12 wrap-inner load-more text-center btn-auction item center">
              <Link
                to="#"
                className="sc-button loadmore fl-button pri-3"
                onClick={loadMore}
              >
                <span>Load More</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
};
export default MarketPlaceCollection;

