import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import BannerVideo from "../layouts/home-6/BannerVideo";
import { s3Url } from "../../store/baseUrl";
import apiUrl from "../../store/globalapiurl";
import * as moment from 'moment';
import SwiperCore, { Pagination, Mousewheel, Autoplay, } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import axios from "../../axiosinstance";

SwiperCore.use([Autoplay]);

const SliderStyle2 = () => {
  let navigate = useNavigate();
  let [productID, setProductID] = useState();
  let [bannerData, setBannerData] = useState([]);
  const [showMint, setShowMint] = React.useState(false)
  let deadline = "2022-12-15 11:00:00"
  React.useEffect(() => {
    getList();
    ethToDollar();
    // getReleaseBottels();
  }, []);
  const [data] = useState([
    {
      // mov: videoMov,
      // mp4: videoMp4,
      // ogg: videoOgg
    }

  ]);

  const ethToDollar = () => {
    axios
      .get(`api/v1/liveStore/ethtousd`)
      .then((response) => {
        if (response.data.statusCode == 200) {
          localStorage.setItem("priceInETH", response.data.data.price);
          const usdPrice = 1 / response.data.data.price
          localStorage.setItem("priceInUSD", usdPrice);
        }

      })
      .catch((error) => {
      });
  };
  const gotoRelease = () => {
    navigate(`/releaseDetails/${productID}`);
  }

  const getTimeUntil = (deadline) => {
    let date = new Date().toLocaleString('en-US', { timeZone: 'America/New_York', });
    let newDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    let deadlineDate = moment(deadline).format('YYYY-MM-DD HH:mm:ss');
    let time = Date.parse(deadlineDate) - Date.parse(newDate);
    if (time < 0) {
      setShowMint(true)
    } else {
      setShowMint(false)
    }
  };

  // useEffect(() => {
  //   setInterval(() => {
  //     getTimeUntil(deadline);
  //   }, 1000);
  //   return () => getTimeUntil(deadline);
  // }, [deadline]);

  const getList = () => {
    const payload = {
      skip: 0,
      limit: 2,
      isUpComing: 0
    };
    axios
      .get(apiUrl._liveStore, { params: payload }).then((res) => {
        let data = res.data.data.rows
        setProductID(data[0].id)
        console.log("data", data)
      })
      .catch((error) => {

      });
  };

  const getReleaseBottels = (sortDirection, orderby) => {
    const payload = {
      skip: 0,
      limit: 10,
    };
    axios
      .get(`admin/v1/banner`, {
        params: payload,
      })
      .then((response) => {
        setBannerData(response.data.data.data);
      })
      .catch((error) => {
      });
  };

  return (
    <div>
      <section className="flat-cart-item">
        <div className="mainslider">
          <div className="themesflat-container">
            <div className="row">
              <div className="col-12 col-md-6 ">
                <div className="content">
                  <div className="txtBox">
                    <div className="fs-16">
                      <h2>Asset backed NFTs<br />Explore the MetaPhy<span>Only via Paiverse</span></h2>
                      <p>MetaPhy, where the meta and physical collide to create infinite possibilities</p>

                      <div className="centerAlign">
                        {/* {!showMint && (<>
                          <p>Available Now - For Whitelisted Users</p>
                          <p>Main Mint : 15 December, 11am EST</p>
                          <button onClick={() => gotoRelease()} className='mintButton roundBtn '>
                            Explore
                          </button></>)}

                        {showMint && (
                         
                        )} */}
                        <br/>
                         <button onClick={() => gotoRelease()} className='mintButton roundBtn '>
                            Mint
                          </button>

                      </div>
                    </div>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                {/* <Swiper
                  direction={"vertical"}
                  autoplay={true}
                  key={data.length}
                  loop
                  speed={2100}
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}

                  navigation
                  scrollbar={{ draggable: true }}
                >
                  {data.map((item, index) => (
                    <SwiperSlide key={index}>
                      <SliderItem item={item} />
                    </SwiperSlide>
                  ))}

                </Swiper> */}
                <div className="imgbox">
                  <BannerVideo />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const SliderItem = (props) => (
  <div className="imgbox">
    <img src={props.item.img} alt="Axies" />
    {/* <img
  //                   src={`${s3Url}category_image/${props.item.url}`}
  //                   alt="Axies"
  //                 /> */}

  </div>
  // <div className="swiper-wrapper">
  //   <div className="swiper-slide">
  //     <div className="slider-item">
  //       <div className="cart_item style2 style3">
  //         <div className="inner-cart">



  //         </div>
  //       </div>
  //     </div>
  //   </div>

  // </div>
);

export default SliderStyle2;
