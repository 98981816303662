import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import icon1 from "./../../../assets/images/svg/ic_transparent.svg"
import icon2 from "./../../../assets/images/svg/ic_ethical.svg"
import icon3 from "./../../../assets/images/svg/ic_immutable.svg"
import icon4 from "./../../../assets/images/svg/ic_asset.svg"
import icon5 from "./../../../assets/images/svg/ic_metaphy.svg"
import icon6 from "./../../../assets/images/svg/ic_community.svg"
import video from './../../../assets/images/slider/logovideo.mp4'


const Paiverse = () => {
    const Video = () => {
        return (
            <video autoPlay loop playsInline muted src={video} />

        );
    };

    return (
        <Fragment>
            <section className="tf-section blackBg">
                <div className="themesflat-container">
                    <h2 className="tf-title pb-24 text-center">What is Paiverse?</h2>
                    <div className="videoWrap">
                        <div className="videoDiv">
                            <Video />

                        </div>
                        <div className="row homePaiverse">
                            <div className="col-6 gridBox">
                                <div className="contentBox">
                                    <div className="icon"><img src={icon1} /></div>
                                    <h3>Transparent</h3>
                                </div>
                            </div>
                            <div className="col-6 gridBox">
                                <div className="contentBox">
                                    <div className="icon"><img src={icon2} /></div>
                                    <h3>Ethical</h3>
                                </div>
                            </div>
                            <div className="col-6 gridBox">
                                <div className="contentBox">
                                    <div className="icon"><img src={icon3} /></div>
                                    <h3>Immutable</h3>
                                </div>
                            </div>
                            <div className="col-6 gridBox">
                                <div className="contentBox">
                                    <div className="icon"><img src={icon4} /></div>
                                    <h3>Asset-Backed</h3>
                                </div>
                            </div>
                            <div className="col-6 gridBox">
                                <div className="contentBox">
                                    <div className="icon"><img src={icon5} /></div>
                                    <h3>MetaPhy</h3>
                                </div>

                            </div>
                            <div className="col-6 gridBox">
                                <div className="contentBox">
                                    <div className="icon"><img src={icon6} /></div>
                                    <h3>Community</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default Paiverse;
