// import { getContract } from "./contract";
import { Web3Provider } from "@ethersproject/providers";
// import React from "react";
import WalletLink from "walletlink";

//connect metamask
export const connectMetamask = async () => {
  return new Promise((resolve, reject) => {
    const { ethereum } = window;
    if (!ethereum) {
     alert("Please install Metamask!");
      if (
        window.confirm(
          "You will be redirected to MetaMask now. Do you wish to proceed?"
        )
      ) {
      
        window.open(`https://metamask.io/download.html`, "_blank");
      } else {
        // setLoading(false);
      }
    } else {
      ethereum
        .request({
          method: "eth_requestAccounts",
        })
        .then((accounts) => {
          const account = accounts[0];   
          const library = new Web3Provider(ethereum, "any");         
          // setWeb3Library(library);
          resolve({ account, library });
        })
        .catch((err) => {
 
          reject(err);
        });
    }
  });
};

//connect coinbase
export const connectCoinbase = async () => {
  // Initialize WalletLink
  return new Promise((resolve, reject) => {
    const walletLink = new WalletLink({
      appName: "demo-app",
      darkMode: true,
    });
    // const { ethereum } = window;
      const provider = walletLink.makeWeb3Provider(
        "https://rinkeby.infura.io/v3/55d040fb60064deaa7acc8e320d99bd4",
        4
      );

    provider
      .request({
        method: "eth_requestAccounts",
      })
      .then((accounts) => {
        const account = accounts[0];
        const library = new Web3Provider(provider, "any");
        resolve({ account, library });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// const writeToContract = async () => {
//   try {
//     const randomNumber = Math.floor(Math.random() * 100);
//     const myContract = getContract(web3Library, web3Account);
//     const overrides = {
//       gasLimit: 230000,
//     };
//     const response = await myContract.store(randomNumber, overrides);
//     alert("write " + randomNumber);
//   } catch (ex) {

//     alert(ex);
//   }
// };
