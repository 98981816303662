import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import img1 from "../assets/images/avatar/avt-3.jpg";
import { useLocation } from "react-router-dom";
import { ethers } from "ethers";
import { connectMetamask, connectCoinbase } from "../Helpers/contractMethods";
import { getContract, adminAddress, contractAddress, etherScanUrl, ipfsUrl } from "../Helpers/contract";
import axios from "../axiosinstance";
import LoadingOverlay from "react-loading-overlay";
import Context from "../store/context";
import ListToMarketPlace from "../components/layouts/ListToMarketplace";
import ListToMarketConfirmMsg from "../components/layouts/ListToMarketConfirmMsg";
import LazyMinter from "../Helpers/lazyMinter";
import * as moment from "moment";
import { s3Url } from "../store/baseUrl";
import Web3 from 'web3';
import BurnConfirmMsg from "../components/layouts/burnConfirmMsg";
import Web3Provider from 'react-web3-provider';
const AirdropItemDetails = () => {
  LoadingOverlay.propTypes = undefined
  const [modalShow, setModalShow] = useState(false);
  const location = useLocation();
  let navigate = useNavigate();
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch } = commonGlobalState;
  let [loading, setLoading] = useState(true);
  const [web3Library, setWeb3Library] = React.useState();
  const [web3Account, setWeb3Account] = React.useState();
  const [bottleId, setBottleId] = useState();
  const [confirmPopup, setConfirmPopup] = useState(false);
  let kycToken;
  let productID;
  const [releaseBottleDetail, setReleaseBottleDetails] = useState([]);
  React.useEffect(() => {
    const [proID, kyc] = location.search.split("?kycToken=");
    kycToken = kyc ? kyc : '';
    const [a, id] = proID.split("?id=");
    productID = id;
    setBottleId(id);
    getAirdropBottelsDetails(id);
    if (kycToken) {
      submitKyc(id);
    }
  }, []);
  const getAirdropBottelsDetails = (id) => {
    axios
      .get(`api/v1/user/owned/${id}`)
      .then((response) => {
        setReleaseBottleDetails(response.data.data);
        setLoading(false);
      })
      .catch((error) => {

      });
  };

  const submitKyc = (id) => {
    setLoading(true);
    let payload = {
      productItemId: Number(productID),
      kycTransactionId: kycToken,
    };

    axios
      .post(`api/v1/burning`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          setLoading(false);
          kycToken = "";
          getAirdropBottelsDetails(id);
          navigate(`/airdropitem-details?id=${id}`);
          const toasterError = {
            type: "success",
            message: "NFT burnt successfully. You can check it in the Redeemed Editions section below.",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
        }
      })
      .catch((error) => {

        const toasterError = {
          type: "error",
          message: error.response.data.message,
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
        setLoading(false);
      });
  }


  const saveWalletDetails = (walletType, account) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `api/v1/user/connectUserWallet?walletType=${walletType}&walletId=${account}&detail=description`,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            localStorage.setItem("activeWallet", walletType);
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  // burn smart contract

  const burnNFT = async (item) => {
    const res = await connectMetamask();
    checkAccount(res.account, "2", item);
  }



  const getburnToken = () => {
    setLoading(true);
    const payload = {
      productItemId: Number(bottleId)
    }
    axios
      .post(`api/v1/burning/burnToken/`, payload)
      .then((response) => {

        setLoading(false);
        navigate("/my-collection");
        const toasterError = {
          type: "success",
          message:
            "NFT burnt successfully. You can check it in the Redeemed Editions section below.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      })
      .catch((error) => {
      });
  };




  const burnClaim = async (item) => {
    setLoading(true);
    const res = await connectMetamask();
    const myContract = getContract(res.library, res.account);
    const web3 = new Web3(Web3.givenProvider);
    try {

      let voucher = {
        "nonce": item.burnNonce,
        "uri": ipfsUrl + item.fileHash,
        "minPrice": item.shippingFee.toString(),
        "signature": item.burnSignature
      }
      const response = await myContract.burn(item.blockChainNFTId, voucher, { value: item.shippingFee.toString() })

      if (response) {
        getburnToken();
      }

    } catch (ex) {
      const error = JSON.parse(JSON.stringify(ex));

      if (error.code === "INSUFFICIENT_FUNDS") {
        const toasterError = {
          type: "error",
          message:
            "Your wallet balance is low, please add sufficient ETH to mint",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      } else {
        const toasterError = {
          type: "error",
          message: "User rejected the request.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      }
      setLoading(false);
    }
  };
  const goTokyc = (type) => {
    if (type === 0) {
      localStorage.setItem("airDropType", "1")
      navigate(`/kyc?id=${bottleId}`, { state: `/airdropitem-details?id=${bottleId}` });
    }
    else {
      localStorage.setItem("airDropType", "1")
      window.location.href = `/trulioo-kyc?id=${bottleId}`;
    }
  };


  const claimHandler = async (item) => {
    setLoading(true)
    try {
      const res = await connectMetamask();
      setWeb3Account(res.account);
      setWeb3Library(res.library);
      const resolve = await saveWalletDetails("MetaMask", res.account);

      if (resolve) {
        const payload = {
          buyerAddress: res.account,
          productItemId: Number(bottleId),
          buyType: "AIRDROP"
        }
        axios
          .get(`api/v1/liveStore/priceAndToken/${item?.product.id}`, { params: payload })
          .then((response) => {
            if (response.data.statusCode == 200) {

              redeemNFT(res.library, res.account, item, item.blockChainNFTId, response.data.data);
              // redeemNFT(res.library, res.account, item, response.data.data.token, response.data.data.price.toString(), response.data.data.voucher, response.data.data.ethPrice);

            }
          })
          .catch((error) => {
            setLoading(false);
          });

        // 
      }
    } catch (e) {
      const toasterError = {
        type: "error",
        message: "Please connect a different wallet address",
      };
      globalDispatch({ type: "TOASTER", state: toasterError });
    }
  };




  // claim airdrop NFT with main contract
  const redeemNFT = async (library, account, item, token, backendSign) => {
    setLoading(true);
    const web3 = new Web3(Web3.givenProvider);

    try {
      let voucherData = {
        "nonce": backendSign.voucher.voucher.nonce,
        "minPrice": 0,
        "uri": backendSign.voucher.voucher.uri,
        "signature": backendSign.voucher.sign
      };

      const myContract = getContract(library, account);
      const response = await myContract.safeMint(account, voucherData, { value: backendSign.voucher.voucher.minPrice.toString() });
      const receipt = await response.wait();
      const receiptTokenId = Math.round(
        parseFloat(
          ethers.utils.formatUnits(receipt.events[0].args.tokenId["_hex"], 18)
        ) *
        10 ** 18
      );
      const gasFee = Math.round(
        parseFloat(
          ethers.utils.formatUnits(receipt.gasUsed["_hex"], 18)
        ) *
        10 ** 18
      );
      setLoading(false);

      payment(receipt, item.sellingAmount, receiptTokenId, item, token, gasFee);
    } catch (ex) {

      const error = JSON.parse(JSON.stringify(ex));
      if (error.code === "INSUFFICIENT_FUNDS") {
        const toasterError = {
          type: "error",
          message:
            "Your wallet balance is low, please add sufficient ETH to mint",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      } else {
        const toasterError = {
          type: "error",
          message: "User rejected the request.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      }
      setLoading(false);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }

  const claimNFT = (id) => {
    if (globalState.isLoggedIn) {

      claimHandler(id);
    } else {
      navigate(`/login`, { state: location.pathname });
    }
  };

  const formatTime = (time) => {
    return moment(time).format("MMM DD, yyyy");
  };

  const payment = (paymentData, dollarPrice, token, item, oldNFTToken, gasFee) => {
    const payload = {
      token: oldNFTToken,
      receiptTokenId: token,
      id: item.productId,
      blockChainTransactionId: paymentData.transactionHash,
      blockChainTransactionStatus: paymentData.status ? "SUCCESS" : "FAIL",
      from: paymentData.from,
      currency: "ETH",
      to: paymentData.to,
      gasAmount: gasFee ? gasFee : 2300,
      conversionAmount: dollarPrice,
    };
    axios
      .post(`api/v1/liveStore/redeem`, payload)
      .then((response) => {

        if (response.data.statusCode == 200) {
          const toasterError = {
            type: "success",
            message: "Claimed Successfully",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
        }
        setLoading(false);
        getAirdropBottelsDetails(item.id);

      })
      .catch((error) => {

        setLoading(false);
      });
  };

  const ETHtoUSDPrice = (value) => {
    const ethPrice = localStorage.getItem("priceInUSD") || 0;
    const price = value * ethPrice;
    return price.toFixed(6);
  };

  const mintNFT = async (id, price, days, type, voucher) => {
    if (globalState.isLoggedIn) {
      connectWalletHandler(id, price, days, type, voucher);
    } else {
      navigate(`/login`, { state: "/release" });
    }
  };


  const listToMarket = async (isBurningRequested) => {
    if (isBurningRequested === 1) {
      setConfirmPopup(true);
    } else {
      setLoading(true);
      let item;
      const res = await connectMetamask();
      checkAccount(res.account, "1", item);
    }
  }
  const checkAccount = (token, type, item) => {
    const payload = {
      'productItemId': Number(bottleId),
      'token': token,
    }
    axios
      .post(`api/v1/marketPlace/getTokenDetail`, payload)
      .then((res) => {
        checkApproveAll(type, item)
      })
      .catch((error) => {
        setLoading(false);
        const toasterError = {
          type: "error",
          message: error.response.data.message,
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      });
  }
  const checkApproveAll = async (type, item) => {
    setLoading(true);
    const res = await connectMetamask();
    const payload = {
      token: res.account,
      isApprove: 0,
    };
    axios
      .post(`/api/v1/marketPlace/saveToken`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          if (response.data.data.token) {
            setLoading(false);
            if (type === "1") {
              setModalShow(true);
            } else {
              burnClaim(item);
            }
          } else {
            approveContract(res.library, res.account, type, item);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  const getData = (data) => {
    if (data) {
      if (data.price > 0 && data.days != "" && !data.type) {
        setModalShow(false);
        if (data.type == 1) {
          biddingSignature(data);
        } else {
          mintNFT(bottleId, data.price, data.days, data.type, null);
        }
      }
    }
  };
  const closePopup = () => {
    setModalShow(false);
    setConfirmPopup(false)
  };
  const getConfirmData = async (data) => {
    setConfirmPopup(false);
    setLoading(true);
    let item;
    const res = await connectMetamask();
    checkAccount(res.account, "1", item);
  }

  // bidding
  const biddingSignature = async (data) => {
    setLoading(true);
    const res = await connectMetamask();
    // let price = 0.01;
    let minter = new LazyMinter(contractAddress.toString(), res.account.toString());
    try {
      let voucher = await minter.createVoucher(
        releaseBottleDetail.blockChainNFTId,
        ipfsUrl + releaseBottleDetail.product.fileHash,
        data.days,
        ethers.utils.parseEther(data.price.toString())
      );

      mintNFT(bottleId, data.price, data.days, data.type, voucher);
    } catch (e) {
      setLoading(false);
    }
  };

  const connectWalletHandler = async (id, price, days, type, voucher) => {
    try {
      const res = await connectMetamask();
      setWeb3Account(res.account);
      setWeb3Library(res.library);
      const activeWallet = localStorage.getItem("activeWallet");
      listToMarketApi(price, id, days, type, voucher, res.account);
    } catch (e) {

    }
  };

  // set approval with the escrow conract
  const approveContract = async (library, account, reqType, item) => {
    try {
      setLoading(true);
      const myContract = getContract(library, account);
      //admin toten
      const response = await myContract.setApprovalForAll(
        adminAddress,
        "1"
      );
      const receipt = await response.wait();
      saveApprovalResponse(library, account, reqType, item);
    } catch (ex) {
      const error = JSON.parse(JSON.stringify(ex));

      if (error.code === "INSUFFICIENT_FUNDS") {
        const toasterError = {
          type: "error",
          message:
            "Your wallet balance is low, please add sufficient ETH to mint",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      } else {
        const toasterError = {
          type: "error",
          message: "User rejected the request.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      }
      setLoading(false);
    }
  };

  const saveApprovalResponse = (library, account, type, item) => {
    const payload = {
      token: account,
      isApprove: 1,
    };
    axios
      .post(`/api/v1/marketPlace/saveToken`, payload)
      .then((response) => {

        if (response.data.statusCode == 200) {
          if (response.data.data.token) {
            if (type === "1") {
              setModalShow(true);
            } else {
              burnClaim(item);
            }
            //payment(price, id, days, type, voucher, account);
          }
        }
      })
      .catch((error) => {

        setLoading(false);
      });
  };

  // store blockchain transaction
  const listToMarketApi = (price, id, days, type, voucher, account) => {
    const payload = {
      productItemId: id,
      amount: price,
      expiredAt: days,
      placeType: type,
      bidSignature: voucher ? voucher.signature : undefined,
      toAddress: account
    };
    axios
      .put(`/api/v1/user/owned/listToMarketPlace`, payload)
      .then((response) => {

        setLoading(false);
        navigate("/my-collection");
        const toasterError = {
          type: "success",
          message: `NFT ID #${releaseBottleDetail.blockChainNFTId} listed on marketplace successfully.`,
        };
        globalDispatch({ type: "TOASTER", state: toasterError });

        // getReleaseBottelsDetails(id);
      })
      .catch((error) => {

        setLoading(false);
      });
  };

  return (
    <div className="item-details ProductDetailsOne withoutHeader">
      <LoadingOverlay active={loading} spinner={true} text="Loading...">
        <div className="tf-section">
          <div className="themesflat-container">
            <div className="row">
              <div className="col-12 col-md-6 ">
                <div className="content-left">
                  <div className="imgBox">
                    {/* <video width="750" height="500" autoPlay muted>
                      <source src={video} type="video/mp4" />
                    </video> */}
                    <img
                      src={`${s3Url}icons/${releaseBottleDetail &&
                        releaseBottleDetail.product &&
                        releaseBottleDetail.product.imageUrl
                        }`}
                      alt="Axies"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="contBox ">
                  <div className="sc-item-details">
                    <h2 className="style2">
                      {releaseBottleDetail &&
                        releaseBottleDetail.product && capitalizeFirstLetter(releaseBottleDetail.product.title)
                      }
                    </h2>
                    <p>
                      {releaseBottleDetail &&
                        releaseBottleDetail.product &&
                        releaseBottleDetail.product.description}
                    </p>
                    <div className="item meta-price w-100">
                      <div className="price">

                        {releaseBottleDetail.isMinted != 0 ?
                          <>                          <div className="price-box d-flex align-items-center">
                            <h5>
                              NFT ID #{releaseBottleDetail.blockChainNFTId}
                            </h5>
                          </div>
                            <a className="viewBtnEth" href={`${etherScanUrl}${contractAddress}?a=${releaseBottleDetail.blockChainNFTId}`} target="_blank" tr> View on Ethersan</a></>

                          : ''}

                      </div>

                    </div> <br />
                    {releaseBottleDetail.isMinted == 0 ? <button
                      onClick={() => claimNFT(releaseBottleDetail)}
                      disabled={releaseBottleDetail.isMinted > 0}
                      className={`roundBtn widthAuto mr-5 ${releaseBottleDetail.isMinted > 0 && `color-red`
                        }`}
                    >Claim</button> : <>
                      <p className="color-red">Claimed</p>
                      <button onClick={() => listToMarket(releaseBottleDetail.isBurningRequested)}
                        className=" roundBtn widthAuto mr-5"
                      >
                        List on Marketplace
                      </button>

                      {releaseBottleDetail.burningResponse == 1 ?
                        <button onClick={() => burnNFT(releaseBottleDetail)} className="roundBtn roundBtnNew">Burn</button> :
                        <>{releaseBottleDetail.isBurningRequested === 1 ?
                          <Link className="roundBtn roundBtnNew disable">Redeem</Link> :
                          <button className="roundBtn roundBtnNew" onClick={() => goTokyc(releaseBottleDetail?.product.isUpComing)}>Redeem</button>
                        }</>
                      }
                    </>

                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ListToMarketPlace
          show={modalShow}
          sendPrice={getData}
          closeModal={closePopup}

        />
        <ListToMarketConfirmMsg show={confirmPopup}
          confirm={getConfirmData}
          closeModal={closePopup} />
      </LoadingOverlay>
    </div>
  );
};

export default AirdropItemDetails;
