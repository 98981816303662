import React, { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import PaiverseCommunity from "../components/layouts/home-6/PaiverseCommunity";
import FaqAccordion from "./FaqAccordion";

const FAQ = () => {
  LoadingOverlay.propTypes = undefined
  let [loading] = useState(true);
  return (
    <div>

      <section className="flat-title-page inner">
        <h1 className="heading text-white"> Frequently Asked Questions</h1>
        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p> */}
      </section>
      <FaqAccordion count='auto' />
      <div className="darkGray tf-section pt-10 ">
          <PaiverseCommunity />
        </div>

    </div>
  );
};

export default FAQ;
