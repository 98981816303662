
const LoginMenu = [
    {
        id: 80,
        name: "Sign Up",
        links: "/sign-up",
    },
    {
        id: 70,
        name: "Login",
        links: "/login",
    },
];

const menus = [{
    id: 90,
    name: "Releases",
    links: "/release",
},
{
    id: 30,
    name: "Marketplace",
    links: "/marketplace",
},
{
    id: 2,
    name: "Brands",  
},
{
    id: 27,
    name: "About Us",
    namesub: [
        {
            id: 3,
            sub: 'Our Story',
            links: '/about-us'
        },
        {
            id: 4,
            sub: 'Roadmap',
            links: '/roadmap'
        },

    ],
},
{
    id: 77,
    name: "FAQs",
    links: "/faq",
},
{
    id: 2,
    name: "Latest News",
    links: "/latest-news",
},
];

const Profilemenu = [{
    id: 2,
    name: "My Profile",
    links: "#",
    namesub: [{
        id: 5,
        sub: "Profile",
        links: "/profile",
    },
    {
        id: 3,
        sub: "Connect Wallet",
        links: "/wallet-connect",
    },

    {
        id: 7,
        sub: "My Collection",
        links: "/my-collection",
    },
    // {
    //     id: 8,
    //     sub: "Favorites",
    //     links: "/favorites",
    // },
    ],
},
];



export { menus, Profilemenu, LoginMenu, };