import React, { useState, useEffect, useContext, useRef } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import Context from "../store/context";

export default function ReactTimer() {
  let navigate = useNavigate();
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch} = commonGlobalState;

  const timeout = 1200000;
  const [remaining, setRemaining] = useState(timeout);

  const handleOnIdle = () => {

    if (globalState.isLoggedIn) {

      const toasterError = {
        type: "error",
        message: "Logged out successfullly",
      };
      globalDispatch({ type: "TOASTER", state: toasterError });
      localStorage.clear();
      globalDispatch({ type: "LOGOUT" });
      navigate(`/login`);
    }
  };

  const { getRemainingTime } = useIdleTimer({ timeout, onIdle: handleOnIdle }); // automatic logout inactive user after 5 min

  useEffect(() => {
    if (globalState.isLoggedIn) {
      setRemaining(globalState.isLoggedIn ? getRemainingTime() : "");
      setInterval(() => {
        setRemaining(globalState.isLoggedIn ? getRemainingTime() : "");
      }, 1000);
    }
  }, []);

  return (
    <div>
      {/* <div>
        <ul>
          <li>Timeout: {timeout}ms</li>
          <li>Time Remaining: {remaining}</li>
        </ul>
      </div> */}
    </div>
  );
}
