import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import img1 from "../assets/images/avatar/avt-3.jpg";
import { ethers } from "ethers";
import { useLocation } from "react-router-dom";
import { s3Url } from "../store/baseUrl";
import { connectMetamask, connectCoinbase } from "../Helpers/contractMethods";
import {
  contractAddressEscrow, getContract, etherScanUrl, getContractEscrow, getContractSwaping,
  adminAddress, contractAddress, contractAbi, ipfsUrl
} from "../Helpers/contract";
import axios from "../axiosinstance";
import LoadingOverlay from "react-loading-overlay";
import Context from "../store/context";
import Web3 from 'web3';
import Web3Provider from 'react-web3-provider';
import * as moment from "moment";
import ListToMarketPlace from "../components/layouts/ListToMarketplace";
import ListToMarketConfirmMsg from "../components/layouts/ListToMarketConfirmMsg";
import BurnConfirmMsg from "../components/layouts/burnConfirmMsg";
import LazyMinter from "../Helpers/lazyMinter";
const ItemDetails = () => {
  LoadingOverlay.propTypes = undefined
  const location = useLocation();
  let navigate = useNavigate();
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch } = commonGlobalState;
  let [loading, setLoading] = useState(true);
  const [web3Library, setWeb3Library] = React.useState();
  const [web3Account, setWeb3Account] = React.useState();
  const [releaseBottleDetail, setReleaseBottleDetails] = useState([]);
  const [bottleId, setBottleId] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmBurnPopup, setConfirmBurnPopup] = useState(false);
  const [burnType, setBurnType] = useState('');
  let userAccont;

  React.useEffect(() => {
    const [proID, burnedType] = location.search.split("?burned=");
    const [a, id] = proID.split("?id=");
    setBottleId(id);
    setBurnType(burnedType)
    getReleaseBottelsDetails(id);
  }, []);

  const getReleaseBottelsDetails = (id) => {
    axios
      .get(`api/v1/user/owned/${id}`)
      .then((response) => {
        setReleaseBottleDetails(response.data.data);
        setLoading(false);
      })
      .catch((error) => {

      });
  };

  const saveWalletDetails = (walletType, account) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `api/v1/user/connectUserWallet?walletType=${walletType}&walletId=${account}&detail=description`,
          {}
        )
        .then((response) => {

          if (response.data.statusCode == 200) {
            localStorage.setItem("activeWallet", walletType);
            resolve(true);
          }
        })
        .catch((error) => {
          resolve(true);
        });
    });
  };

  const connectWalletHandler = async (id, price, days, type, voucher) => {
    setLoading(true);
    try {
      const res = await connectMetamask();
      setWeb3Account(res.account);
      setWeb3Library(res.library);
      const activeWallet = localStorage.getItem("activeWallet");
      payment(price, id, days, type, voucher, res.account);
      // const payload = {
      //   token: res.account,
      //   isApprove: 0,
      // };
      // axios
      //   .post(`/api/v1/marketPlace/saveToken`, payload)
      //   .then((response) => {
      //     if (response.data.statusCode == 200) {
      //       if (response.data.data.token) {
      //         payment(price, id, days, type, voucher, res.account);
      //       } else {
      //         approveContract(res.library, res.account, id, price, days, type, voucher);
      //       }
      //     }
      //   })
      //   .catch((error) => {
      //     setLoading(false);
      //   });
    } catch (e) {

    }
  };

  const ETHtoWETH = async () => {

    try {
      const res = await connectMetamask();
      setWeb3Account(res.account);
      setWeb3Library(res.library);
      ETHtoWETHContract(res.library, res.account);
    } catch (e) {

    }
  };

  // get WETH balance
  const WethBalance = async (myContract, account) => {
    const response = await myContract.balanceOf(account);
    const balance = Math.round(
      parseFloat(ethers.utils.formatUnits(response["_hex"], 18)) * 10 ** 18
    );
  };

  const ETHtoWETHContract = async (library, account) => {
    setLoading(true);
    try {
      const myContract = getContractSwaping(library, account);
      const overrides = {
        value: ethers.utils.parseEther("0.1"),
      };
      WethBalance(myContract, account);
      setLoading(false);
    } catch (ex) {
      const error = JSON.parse(JSON.stringify(ex));

      if (error.code === "INSUFFICIENT_FUNDS") {
        const toasterError = {
          type: "error",
          message:
            "Your wallet balance is low, please add sufficient ETH to mint",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      } else {
        const toasterError = {
          type: "error",
          message: "User rejected the request.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      }
      setLoading(false);
    }
  };

  // set approval with the escrow conract
  const approveContract = async (library, account, reqType, item) => {
    try {
      setLoading(true);
      const myContract = getContract(library, account);
      //admin toten
      const response = await myContract.setApprovalForAll(
        adminAddress,
        "1"
      );
      const receipt = await response.wait();
      saveApprovalResponse(library, account, reqType, item);
    } catch (ex) {
      const error = JSON.parse(JSON.stringify(ex));

      if (error.code === "INSUFFICIENT_FUNDS") {
        const toasterError = {
          type: "error",
          message:
            "Your wallet balance is low, please add sufficient ETH to mint",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      } else {
        const toasterError = {
          type: "error",
          message: "User rejected the request.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      }
      setLoading(false);
    }
  };
  const listToMarket = async (isBurningRequested) => {
    if (isBurningRequested === 1) {
      setConfirmPopup(true);
    } else {
      setLoading(true);
      let item;
      const res = await connectMetamask();
      checkAccount(res.account, "1", item);
    }
  }
  const getConfirmData = async (data) => {
    setConfirmPopup(false);
    setLoading(true);
    let item;
    const res = await connectMetamask();
    checkAccount(res.account, "1", item);
  }

  const checkAccount = (token, type, item) => {
    const payload = {
      'productItemId': Number(bottleId),
      'token': token,
    }
    axios
      .post(`api/v1/marketPlace/getTokenDetail`, payload)
      .then((res) => {
        // setModalShow(true);
        checkApproveAll(type, item)

      })
      .catch((error) => {
        setLoading(false);
        const toasterError = {
          type: "error",
          message: error.response.data.message,
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      });
  }
  //
  const checkApproveAll = async (type, item) => {
    setLoading(true);
    const res = await connectMetamask();
    const payload = {
      token: res.account,
      isApprove: 0,
    };
    axios
      .post(`/api/v1/marketPlace/saveToken`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          if (response.data.data.token) {
            setLoading(false);
            if (type === "1") {
              setModalShow(true);
            } else {
              burnTheNFT(item);
            }
          } else {
            approveContract(res.library, res.account, type, item);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const saveApprovalResponse = (library, account, type, item) => {
    const payload = {
      token: account,
      isApprove: 1,
    };
    axios
      .post(`/api/v1/marketPlace/saveToken`, payload)
      .then((response) => {

        if (response.data.statusCode == 200) {
          if (response.data.data.token) {
            if (type === "1") {
              setModalShow(true);
            } else {
              burnTheNFT(item);
            }
            //payment(price, id, days, type, voucher, account);
          }
        }
      })
      .catch((error) => {

        setLoading(false);
      });
  };

  const mintNFT = async (id, price, days, type, voucher) => {
    if (globalState.isLoggedIn) {
      connectWalletHandler(id, price, days, type, voucher);
    } else {
      navigate(`/login`, { state: `/item-details?id=${id}` });
    }
  };

  // store blockchain transaction
  const payment = (price, id, days, type, voucher, account) => {
    setLoading(true);
    const payload = {
      productItemId: id,
      amount: price,
      expiredAt: days,
      placeType: type,
      bidSignature: voucher ? voucher.signature : undefined,
      toAddress: account
    };
    axios
      .put(`/api/v1/user/owned/listToMarketPlace`, payload)
      .then((response) => {
        setLoading(false);
        navigate("/my-collection");
        const toasterError = {
          type: "success",
          message: `NFT ID #${releaseBottleDetail.blockChainNFTId} listed on marketplace successfully.`,
        };
        globalDispatch({ type: "TOASTER", state: toasterError });

        // getReleaseBottelsDetails(id);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getData = (data) => {
    if (data) {
      if(data.price>0 && data.days!=""){
        console.log("voucher", LazyMinter)
        setModalShow(false);
        if (data.type == 1) {
          biddingSignature(data);
        } else {
          mintNFT(bottleId, data.price, data.days, data.type, null);
        }
      }
    }
  };


  const biddingSignature = async (data) => {
    setLoading(true);
    console.log("voucher", LazyMinter)
    const res = await connectMetamask();   
    // let price = 0.01;    
    let minter = new LazyMinter(contractAddress.toString(), res.account.toString());
 
    try {
      let voucher = await minter.createVoucher(
        releaseBottleDetail.blockChainNFTId,
        ipfsUrl + releaseBottleDetail.product.fileHash,
        data.days,
        ethers.utils.parseEther(data.price.toString())
      );
      console.log("voucher", voucher)
      mintNFT(bottleId, data.price, data.days, data.type, voucher);
    } catch (e) {
      setLoading(false);
    }
  };

  const closePopup = () => {
    // sendPrice("")
    setModalShow(false);
    setConfirmPopup(false);
  };

  const getBurnData = async (data) => {
    setConfirmPopup(true);

  }
  // burn smart contract
  const burnNFT = async (item) => {
    // setConfirmBurnPopup(true);
    const res = await connectMetamask();
    checkAccount(res.account, "2", item);
  }

  const burnTheNFT = async (item) => {
    setLoading(true);
    const res = await connectMetamask();
    const myContract = getContract(res.library, res.account);
    const web3 = new Web3(Web3.givenProvider);
    try {
      const price = web3.utils.toWei(item.shippingFee.toString(), "Ether");

      let voucher = {
        "nonce": item.burnNonce,
        "minPrice": item.shippingFee.toString(),
        "uri": ipfsUrl + item.fileHash,
        "signature": item.burnSignature
      }
    
      const response = await myContract.burn(item.blockChainNFTId, voucher, { value: item.shippingFee.toString() })

      if (response) {
        getburnToken();
      }

    } catch (ex) {     
      const error = JSON.parse(JSON.stringify(ex));

      if (error.code === "INSUFFICIENT_FUNDS") {
        const toasterError = {
          type: "error",
          message:
            "Your wallet balance is low, please add sufficient ETH to mint",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      } else {
        const toasterError = {
          type: "error",
          message: "User rejected the request.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      }
      setLoading(false);
    }
  };

  const getburnToken = () => {
    setLoading(true);
    const payload = {
      productItemId: Number(bottleId)
    }
    axios
      .post(`api/v1/burning/burnToken/`, payload)
      .then((response) => {

        setLoading(false);
        navigate("/my-collection");
        const toasterError = {
          type: "success",
          message:
            "NFT burnt successfully. You can check it in the Redeemed Editions section below.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      })
      .catch((error) => {
      });
  };

  const goTokyc = (item) => {
    navigate(`/kyc?id=${bottleId}`, { state: `/item-details?id=${bottleId}` });
  };



  return (
    <div className="item-details ProductDetailsOne withoutHeader">
      <LoadingOverlay active={loading} spinner={true} text="Loading...">

        <div className="tf-section">
          <div className="themesflat-container">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="content-left">
                  <div className="imgBox">
                    {/* <video width="750" height="500" autoPlay muted>
                      <source src={video} type="video/mp4" />
                    </video> */}
                    {releaseBottleDetail &&
                      releaseBottleDetail.product &&
                      releaseBottleDetail.product.imageUrl
                      ? <img
                        src={`${s3Url}icons/${releaseBottleDetail &&
                          releaseBottleDetail.product &&
                          releaseBottleDetail.product.imageUrl
                          }`}
                        alt="Axies"
                      /> : ""}



                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="contBox">
                  <div className="sc-item-details">
                    <h2 >
                      {releaseBottleDetail &&
                        releaseBottleDetail.product &&
                        releaseBottleDetail.product.brandName} {releaseBottleDetail &&
                          releaseBottleDetail.product &&
                          releaseBottleDetail.product.title}
                    </h2>

                    <p>
                      {releaseBottleDetail &&
                        releaseBottleDetail.product &&
                        releaseBottleDetail.product.description}
                    </p>


                    <div className="item meta-price w-100">
                      <div className="price">
                        <div className="price-box d-flex align-items-center">
                          <h5>
                            NFT ID #{releaseBottleDetail.blockChainNFTId}
                          </h5>
                        </div>
                      </div>
                      <a className="viewBtnEth" href={`${etherScanUrl}${contractAddress}?a=${releaseBottleDetail.blockChainNFTId}`} target="_blank" tr> View on Ethersan</a>
                    </div>

                    {burnType ?
                      <p className="grayOut">Redeemed</p>
                      : <>
                        <button onClick={() => listToMarket(releaseBottleDetail.isBurningRequested)}
                          className=" roundBtn widthAuto mr-5"
                        >
                          List on Marketplace
                        </button>
                        {releaseBottleDetail.burningResponse == 1 ?
                          <button onClick={() => burnNFT(releaseBottleDetail)} className="roundBtn roundBtnNew">Burn</button> :
                          <>{releaseBottleDetail.isBurningRequested === 1 ?
                            <button className="roundBtn roundBtnNew disable">Redeem</button> : <button className="roundBtn roundBtnNew" onClick={() => goTokyc()}>Redeem</button>
                          }</>
                        }
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ListToMarketPlace
          show={modalShow}
          sendPrice={getData}
          closeModal={closePopup}
        // show={modalShow}
        // onHide={() => setModalShow(false)}
        />
        <ListToMarketConfirmMsg show={confirmPopup}
          confirm={getConfirmData}
          closeModal={closePopup} />

        <BurnConfirmMsg show={confirmBurnPopup}
          confirm={getBurnData}
          closeModal={closePopup} />
      </LoadingOverlay >



    </div >
  );
};

export default ItemDetails;
