import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import * as moment from "moment";
import { connectMetamask } from "../../Helpers/contractMethods";
import { getContractSwaping } from "../../Helpers/contract";
import { ethers } from "ethers";
import LoadingOverlay from "react-loading-overlay";

const MakeOffer = (props) => {
  LoadingOverlay.propTypes = undefined
  const initialValues = {
    price: "",
    days: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [isSubmit, setIsSubmit] = useState(false);
  const [makeOfferDisable, setMakeOfferDisable] = useState(false);
  const [WETHbalance, setWETHbalance] = React.useState(0);
  const [addWETHprice, setAddWETHprice] = React.useState(0);
  const [minBidDate, setMinBidDate] = React.useState(moment(new Date).format("yyyy-MM-DD"));
  let [loading, setLoading] = useState(false);
  let date = new Date().toLocaleString('en-US', { timeZone: 'America/New_York', });
  let newDate = moment(date).add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
  let minDate = moment(newDate).format("YYYY-MM-DD");

  React.useEffect(() => {
    setWETHbalance(props?.balance);
  }, [props?.balance]);

  React.useEffect(() => {
    calcPrice(formValues.price, WETHbalance);
  }, [WETHbalance]);

  const handlePriceInput = (e) => {
    const { name, value } = e.target;
    calcPrice(value, WETHbalance);
    setFormValues({ ...formValues, [name]: value });
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  function isPositive(num) {
    if (Math.sign(num) === 1) {
      return true;
    }

    return false;
  }

  const priceCheck = (value, WETHbalance) => {
    if (value) {
      const WETHprice = parseFloat(value).toFixed(4) - WETHbalance;
      setAddWETHprice(WETHprice.toFixed(2));
      setLoading(false);
      if (
        parseFloat(WETHprice) === 0.0 ||
        WETHprice > value ||
        !isPositive(WETHprice)
      ) {
        setMakeOfferDisable(false);
      } else {
        setMakeOfferDisable(true);
      }
    }
  };

  const calcPrice = useCallback(debounce(priceCheck), []);
  const handleDaysInput = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const priceError = (values) => {
    let error = "";
    if (isSubmit || values.price) {
      if (!values.price) {
        error = "Price is required.";
      }
      if (makeOfferDisable) {
        error = "You don't have enough WETH.";
      }
      return error ? <p className="error m-0">{error}</p> : "";
    }
  };

  const handleSubmit = (e) => {
    setIsSubmit(true);
    // setFormErrors(validate(formValues));
    if (formValues.price && !daysError(formValues)) {   
      props.sendPrice({
        price: formValues.price,
        days: formValues.days,
      });
      // signUpSubmit();
    }
  };

  const daysError = (values) => {
 
    if (isSubmit || values.days) {
      let error = "";
      if (!values.days) {
        error = "Bid expiration is required.";
      }
      return error ? <p className="error m-0">{error}</p> : "";
    }
  };

  // used to convert the ETH To WETH
  const addWETH = async () => {
    // setLoading(true);

    try {
      const res = await connectMetamask();
      ETHtoWETHContract(res.library, res.account);
    } catch (e) {
     
    }
  };

  // get WETH balance with swapping contract
  const WethBalance = async (myContract, account) => {
 
    const response = await myContract.balanceOf(account);
   
    const balance = Math.round(
      parseFloat(ethers.utils.formatUnits(response["_hex"], 18)) * 10 ** 18
    );
    
    setWETHbalance((balance / 1000000000000000000).toFixed(4));
  };

  // deposit WETH
  const ETHtoWETHContract = async (library, account) => {
    setLoading(true);
    try {
      const myContract = getContractSwaping(library, account);
      const overrides = {
        value: ethers.utils.parseEther(addWETHprice.toString()),
      };
      const response = await myContract.deposit(overrides);      
      const receipt = await response.wait();
      WethBalance(myContract, account);     
    } catch (ex) {
      setLoading(false);
      const error = JSON.parse(JSON.stringify(ex));
      if (error.code === "INSUFFICIENT_FUNDS") {       
      }
    }
  };

  return (
    // <Modal show={props.show} onHide={props.onHide}>
    <Modal show={props.show} onHide={props.closeModal}>
      <Modal.Header closeButton></Modal.Header>
      <LoadingOverlay active={loading} spinner={true} text="Loading...">
        <form id="marketPlaceForm">
          <div className="modal-body space-y-20 pd-40 pt-0">
            <h3>Make an offer</h3>
            <p>Price</p>
            <input
              name="price"
              type="text"
              className="form-control"
              value={formValues.price}
              onChange={handlePriceInput}
              placeholder="00.00 WETH"
            />
            <div className="d-flex align-items-center justify-content-between m-0">
              {priceError(formValues)}
              <div className="weth-balance ml-auto mt-2">
                {" "}
                Balance: {WETHbalance} WETH{" "}
              </div>
            </div>
            <p>Expiry</p>
            <input
              name="days"
              type="date"
              min={minDate}
              value={formValues.days}
              onChange={handleDaysInput}
              id="bid_expiration_date"
              className="form-control"
              //min={minBidDate}
            />
            {daysError(formValues)}
            <div className="hr"></div>
            <div className="d-flex">
              <button
                className="btn btn-primary mr-2"
                type="button"
                disabled={makeOfferDisable}
                onClick={() => handleSubmit()}
              >
                MAKE OFFER
              </button>
              {makeOfferDisable && (
                <button
                  className="btn btn-primary ml-2"
                  type="button"
                  onClick={() => addWETH()}
                >
                  ADD WETH
                </button>
              )}
            </div>
          </div>
        </form>
      </LoadingOverlay>
    </Modal>
  );
};

export default MakeOffer;
