import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import roadmapImg from "../../src/assets/images/slider/paiverseroad.png";
import minus from "../../src/assets/images/slider/ic_minus.svg";
import zoom from "../../src/assets/images/slider/ic_zoom.svg";
import arrow from "../../src/assets/images/slider/noun-plus.svg";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import PaiverseCommunity from "../components/layouts/home-6/PaiverseCommunity";
const RoadMap = () => {
    const [isActive, setIsActive] = useState(false);

    function resetTransform() {    
        setIsActive(current => !current);
        window.addEventListener("load",function() {
            setTimeout(function(){
                // This hides the address bar:
                window.scrollTo(0, 1);
            }, 0);
        });
      };
    const [showModal, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className="darkGray roadmap ">
                <section className=' flat-title-page inner'>
                    <h1 className="heading text-white">Paiverse Road Map </h1>

                </section>
                <div className="tf-section">
                    <div className="themesflat-container ">
                        <Link to={''} > <img src={roadmapImg} alt='roadmapImg' onClick={handleShow} /></Link>
                     
                    </div>
                    <div className="mt-40"><PaiverseCommunity /></div>
                </div>


            </div>

            <Modal show={showModal} onHide={handleClose}  className={isActive ? 'fullPopup fullWidth' : 'fullWidth'}>
                <Modal.Header closeButton>
                    {/* <Modal.Title> Latest News Modal </Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <TransformWrapper
                        initialScale={1}
                    >
                        {({ zoomIn, zoomOut, resetTransform1, ...rest }) => (
                            <React.Fragment>
                                <TransformComponent>
                                    <img src={roadmapImg} alt="test" />
                                    <div>Example text</div>
                                </TransformComponent>
                                <div className="zoomOutBtn">
                                    <a href="javascript:void(0)" onClick={() => zoomIn()}><img src={arrow} /></a>
                                    <a href="javascript:void(0)" onClick={() => zoomOut()}><img src={minus} /></a>
                                    <a href="javascript:void(0)" onClick={resetTransform}><img src={zoom} /></a>
                                </div>
                            </React.Fragment>
                        )}  
                    </TransformWrapper>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default RoadMap