import React, { useRef, useState, useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { menus, Profilemenu } from "../../pages/menu";
import logodark from "../../assets/images/logo/logo.png";
import avt from "../../assets/images/avatar/avt-2.jpg";
import coin from "../../assets/images/logo/coin.svg";
import Context from "../../store/context";
import apiUrl from "../../store/globalapiurl"
import axios from "../../axiosinstance";

const HeaderStyle2 = () => {
  let navigate = useNavigate();
  const [activeClass, setactiveClass] = useState([])
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch } = commonGlobalState;
  const [globalHeaderMenu, setGlobalHeaderMenu] = useState([]);
  const [userName, setUserName] = useState([]);
  let { pathname } = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [isShowSearch, setShowSearch] = useState(true);
  const headerRef = useRef(null);
  const dropDwon = [{
    id: 22,
    sub: 'Penderyn',
    links: '/brands/15'
  }]
  useEffect(async () => {
    menus.map(item => {
      if (item.name === "Brands") {
        item['namesub'] = dropDwon
      }
    });
    setGlobalHeaderMenu(menus);
    // getBrandList();
    setSearchQuery(globalState.searchQuery);

  }, [globalState]);


 

  const getBrandList = () => {
    const payload = {};
    axios
      .get(apiUrl._getBrand, {
        params: payload,
      }).then((res) => {
        const menuData = [];
        res.data.data.map(item => {
          menuData.push({ 'id': item.id, 'sub': item.name, 'links': `/brands/${item.id}` })
        });
        // menus.map(item => {
        //   if(item.name==="Brands"){
        //     item['namesub']=menuData
        //   }      
        // });
        //setGlobalHeaderMenu(menus);

      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const userName = localStorage.getItem("userName");
    if (accessToken) {
      globalDispatch({ type: "LOGIN" });
    } else {
      globalDispatch({ type: "LOGOUT" });
      globalDispatch({ type: "search", state: "" });
    }
    setUserName(userName);
    if (pathname == "/bottle-details") {
      pathname = "/release";
    }
    if (pathname == "/marketPlace-item-details") {
      pathname = "/marketplace";
    }
    if (pathname == "/item-details") {
      pathname = "/my-collection";
    }
    if (pathname == "/releaseDetails/:id") {
      pathname = "/release";
    }
    if (pathname == "/item-details") {
      pathname = "/my-collection";
    }
    if (pathname == "/airdropitem-details") {
      pathname = "/my-collection";
    }
    if (
      pathname == "/latest-news" ||
      pathname == "/faq" ||
      pathname == "/about-us" ||
      pathname == "/brands" ||
      pathname == "/wallet-connect" ||
      pathname == "/profile" || pathname == "/kyc"
    ) {
      setShowSearch(false);
    } else {
      setShowSearch(true);
    }
    setActiveIndex(pathname);



  }, [pathname]);

  const handleChange = (e) => {
    if (e.key === "Enter") {
      globalDispatch({ type: "search", state: e.target.value });
    }
  };

  const setValue = (e) => {
    setSearchQuery(e.target.value);
  };

  const search = () => {
    globalDispatch({ type: "search", state: searchQuery });
  };

  const isSticky = (e) => {
    const header = document.querySelector(".js-header");
    const scrollTop = window.scrollY;
    // scrollTop >= 300
    //   ? header.classList.add("is-fixed")
    //   : header.classList.remove("is-fixed");
    // scrollTop >= 400
    //   ? header.classList.add("is-small")
    //   : header.classList.remove("is-small");
  };

  const menuLeft = useRef(null);
  const btnToggle = useRef(null);
  const stopPropagation = (e) => {
    e.stopPropagation();
    // const element = document.getElementsByClassName("subMenuTitle");
    // element.classList.remove('showSubmenu')
    e.currentTarget.classList.toggle('showSubmenu');

  };
  const menuToggle = () => {
    menuLeft.current.classList.toggle("active");
    btnToggle.current.classList.toggle("active");
    setactiveClass('')
  };
  const [activeIndex, setActiveIndex] = useState(null);
  const handleOnClick = (index) => {
    setActiveIndex(index);
  };

  const logout = () => {
    localStorage.clear();
    setActiveIndex("/login");
    globalDispatch({ type: "LOGOUT" });
    globalDispatch({ type: "search", state: "" });
    navigate(`/login`);
  };



  return (
    <>
      <header
        id="header_main"
        className="header_1 header_2 style2 js-header"
        ref={headerRef}
      >
        <div className="themesflat-container">
          <div id="site-header-inner final">
            <div className="wrap-box flex">
              <div id="site-logo" className="clearfix">
                <div id="site-logo-inner">
                  <Link to="/" rel="home" className="main-logo">
                    <img id="logo_header" src={logodark} alt="nft-gaming" />
                  </Link>
                </div>
              </div>
              <div
                className="mobile-button"
                ref={btnToggle}
                onClick={menuToggle}
              >
                <span></span>
              </div>
              <nav id="main-nav" className="main-nav navigationWrap" ref={menuLeft} onClick={menuToggle}>
                <ul id="menu-primary-menu" className="menu" >
                  {globalHeaderMenu.map((data, index) => (
                    <li
                      key={index}
                      onClick={() => handleOnClick(data.links)}
                      className={`menu-item ${data.namesub ? "menu-item-has-children" : ""
                        } ${data.namesub ? "" : activeIndex === data.links ? "active" : ''} `}
                    >
                      {data.namesub && (
                        <a className="subMenuTitle" href="#" onClick={stopPropagation} >{data.name} </a>
                      )}
                      {!data.namesub && (
                        <Link to={data.links}>{data.name} </Link>
                      )}
                      {data.namesub && (
                        <ul className="sub-menu">
                          {data.namesub.map((submenu) => (
                            <li
                              key={submenu.id}
                              className={
                                pathname === submenu.links
                                  ? "menu-item current-item "
                                  : "menu-item"
                              }
                            >
                              {/* onClick={(e)=>e.target.parentElement.parentElement.parentElement.classList.add('active')} */}
                              <Link to={submenu.links}>{submenu.sub}</Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
              {/* {isShowSearch && (
                <div className="question-form">
                  <form
                    action="#"
                    method="get"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Type to search..."
                      value={searchQuery}
                      onChange={setValue}
                      onKeyUp={handleChange}
                    />

                    <button type="submit" onClick={search}>
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_334_638"
                          maskUnits="userSpaceOnUse"
                          x="1"
                          y="1"
                          width="18"
                          height="17"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.66699 1.66666H17.6862V17.3322H1.66699V1.66666Z"
                            fill="#999999"
                            stroke="#999999"
                          />
                        </mask>
                        <g mask="url(#mask0_334_638)">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.67711 2.87312C5.9406 2.87312 2.90072 5.84505 2.90072 9.49903C2.90072 13.153 5.9406 16.1257 9.67711 16.1257C13.4128 16.1257 16.4527 13.153 16.4527 9.49903C16.4527 5.84505 13.4128 2.87312 9.67711 2.87312ZM9.67709 17.3322C5.26039 17.3322 1.66699 13.8182 1.66699 9.49902C1.66699 5.17988 5.26039 1.66666 9.67709 1.66666C14.0938 1.66666 17.6864 5.17988 17.6864 9.49902C17.6864 13.8182 14.0938 17.3322 9.67709 17.3322Z"
                            fill="#999999"
                          />
                          <path
                            d="M9.67711 2.37312C5.67512 2.37312 2.40072 5.55836 2.40072 9.49903H3.40072C3.40072 6.13174 6.20607 3.37312 9.67711 3.37312V2.37312ZM2.40072 9.49903C2.40072 13.4396 5.67504 16.6257 9.67711 16.6257V15.6257C6.20615 15.6257 3.40072 12.8664 3.40072 9.49903H2.40072ZM9.67711 16.6257C13.6784 16.6257 16.9527 13.4396 16.9527 9.49903H15.9527C15.9527 12.8664 13.1472 15.6257 9.67711 15.6257V16.6257ZM16.9527 9.49903C16.9527 5.5584 13.6783 2.37312 9.67711 2.37312V3.37312C13.1473 3.37312 15.9527 6.1317 15.9527 9.49903H16.9527ZM9.67709 16.8322C5.52595 16.8322 2.16699 13.5316 2.16699 9.49902H1.16699C1.16699 14.1048 4.99484 17.8322 9.67709 17.8322V16.8322ZM2.16699 9.49902C2.16699 5.46656 5.52588 2.16666 9.67709 2.16666V1.16666C4.9949 1.16666 1.16699 4.8932 1.16699 9.49902H2.16699ZM9.67709 2.16666C13.8282 2.16666 17.1864 5.46649 17.1864 9.49902H18.1864C18.1864 4.89327 14.3593 1.16666 9.67709 1.16666V2.16666ZM17.1864 9.49902C17.1864 13.5316 13.8282 16.8322 9.67709 16.8322V17.8322C14.3594 17.8322 18.1864 14.1047 18.1864 9.49902H17.1864Z"
                            fill="#999999"
                          />
                        </g>
                        <mask
                          id="mask1_334_638"
                          maskUnits="userSpaceOnUse"
                          x="13"
                          y="13"
                          width="6"
                          height="6"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.2012 14.2999H18.3333V18.3333H14.2012V14.2999Z"
                            fill="#999999"
                            stroke="#999999"
                          />
                        </mask>
                        <g mask="url(#mask1_334_638)">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.7166 18.3333C17.5596 18.3333 17.4016 18.2746 17.2807 18.1572L14.3823 15.3308C14.1413 15.0952 14.1405 14.7131 14.3815 14.4774C14.6217 14.2402 15.0123 14.2418 15.2541 14.4758L18.1526 17.303C18.3935 17.5387 18.3944 17.9199 18.1534 18.1556C18.0333 18.2746 17.8746 18.3333 17.7166 18.3333Z"
                            fill="#999999"
                          />
                          <path
                            d="M17.7166 18.3333C17.5595 18.3333 17.4016 18.2746 17.2807 18.1572L14.3823 15.3308C14.1413 15.0952 14.1405 14.7131 14.3815 14.4774C14.6217 14.2402 15.0123 14.2418 15.2541 14.4758L18.1526 17.303C18.3935 17.5387 18.3944 17.9199 18.1534 18.1556C18.0333 18.2746 17.8746 18.3333 17.7166 18.3333"
                            stroke="#999999"
                          />
                        </g>
                      </svg>
                    </button>
                  </form>
                </div>
              )} */}
              <div className="flat-search-btn flex">
                {globalState && globalState.isLoggedIn ? (
                  <nav id="main-nav" className="main-nav profile-nav">
                    <ul id="menu-primary-menu" className="menu">
                      {Profilemenu.map((data, index) => (
                        <li
                          key={index}
                          onClick={() => handleOnClick(data.links)}
                          className={`menu-item ${data.namesub ? "menu-item-has-children" : ""
                            } ${activeIndex === data.links ? "current-item" : ""
                            } `}
                        >
                          <Link to="" onClick={() => setactiveClass(activeClass ? ('') : globalState.userName)} className={`userInfo ${activeClass == globalState.userName && 'showSubmenu'}`}>
                            {globalState && globalState.userName}{" "}
                          </Link>
                          {data.namesub && (
                            <ul className="sub-menu">
                              {data.namesub.map((submenu) => (
                                <li
                                  key={submenu.id}
                                  className={
                                    pathname === submenu.links
                                      ? "menu-item current-item"
                                      : "menu-item"
                                  }
                                >
                                  <Link to={submenu.links} onClick={() => setactiveClass('')}>
                                    {submenu.sub}
                                  </Link>
                                </li>
                              ))}
                              <li>
                                <a className="pointer" onClick={logout}>
                                  Logout
                                </a>
                              </li>
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                ) : (
                  <nav id="main-nav" className="main-nav profile-nav guestUser">
                    <ul id="menu-primary-menu" className="menu">
                      <li
                        key="2"
                        onClick={() => handleOnClick("/sign-up")}
                        className={`menu-item ${activeIndex === "/sign-up" ? "active" : ""
                          } `}
                      >
                        <Link to="/sign-up">Signup</Link>
                      </li>
                      <li><a>/</a></li>
                      <li
                        key="1"
                        onClick={() => handleOnClick("/login")}
                        className={`menu-item ${activeIndex === "/login" ? "active" : ""
                          } `}
                      >
                        <Link to="/login">Login</Link>
                      </li>
                    </ul>
                  </nav>
                )}

                <div className="admin_active" id="header_admin">
                  <div className="header_avatar">
                    <div className="price">
                      <span>
                        2.45 <strong>ETH</strong>{" "}
                      </span>
                    </div>
                    <img className="avatar" src={avt} alt="avatar" />
                    <div className="avatar_popup mt-20">
                      <div className="d-flex align-items-center copy-text justify-content-between">
                        <span> 13b9ebda035r178... </span>
                        <Link to="/" className="ml-2">
                          <i className="fal fa-copy"></i>
                        </Link>
                      </div>
                      <div className="d-flex align-items-center mt-10">
                        <img className="coin" src={coin} alt="/" />
                        <div className="info ml-10">
                          <p className="text-sm font-book text-gray-400">
                            Balance
                          </p>
                          <p className="w-full text-sm font-bold text-green-500">
                            16.58 ETH
                          </p>
                        </div>
                      </div>
                      <div className="hr"></div>
                      <div className="links mt-20">
                        <Link to="#">
                          <i className="fab fa-accusoft"></i>{" "}
                          <span> My items</span>
                        </Link>
                        <a className="mt-10" href="/profile">
                          <i className="fas fa-pencil-alt"></i>{" "}
                          <span> Edit Profile</span>
                        </a>
                        <a className="mt-10" href="/login" id="logout">
                          <i className="fal fa-sign-out"></i>{" "}
                          <span> Logout</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </header>

      {/* <div className="mobPopup">
        <div className="txt">
          Mobile version coming soon! Our site is best optimized for the desktop version for now.
        </div>
      </div> */}
    </>
  );
};

export default HeaderStyle2;
