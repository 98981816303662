import React, { useState,useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { EMAIL_REGEX } from "../services/contants";
import axios from "./../axiosinstance";
import Context from "../store/context";

const ForgotPassword = (props) => {
  const { commonGlobalState } = useContext(Context);
  const { globalDispatch } = commonGlobalState;
  let navigate = useNavigate();
  const initialValues = {
    email: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  // const [verificationPage, setVerificationPage] = useState("forgot-password");

  React.useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      forgotPasswordSubmit();
    }
  }, [formErrors]);

  const forgotPasswordSubmit = () => {
    const payload = {
      email: formValues.email,
    };
    axios
      .put(`api/v1/user/forgotPassword`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          navigate("/verify");
        }
      })
      .catch((error) => {
        const toasterError = {
          type: "error",
          message:error.response.data.message,
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      });
  };

  const ShowError = (values) => {
    if (isSubmit) {
      let error = "";
      if (!values.email) {
        error = "Email is required.";
      } else if (!EMAIL_REGEX.test(values.email)) {
        error = "Please enter valid email address.";
      }
      return <p className="error">{error}</p>;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value.replace(/\s/g, "").length || value == "")
      setFormValues({ ...formValues, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is required.";
    } else if (!EMAIL_REGEX.test(values.email)) {
      errors.email = "Please enter valid email address.";
    }
    return errors;
  };

  // const getData = (data) => {
  //   // setPageType(data);
  //   props.changeType("login");
  // };

  return (
    <React.Fragment>
      <div style={{height: "calc(100% - 500px)" }} >
        <section className="flat-title-page inner">
          <div className="overlay"></div>
          <div className="themesflat-container">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title-heading mg-bt-12">
                  <h1 className="heading text-center">
                    Forgot Password
                    {/* {verificationPage === "forgot-password"
                      ? "Forgot Password"
                      : "Reset Password"} */}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
          <section className="tf-login tf-section">
            <div className="themesflat-container">
              <div className="row">
                <div className="col-12">
                  <div className="flat-form box-login-email">
                    <div className="form-inner">
                      <form id="contactform" onSubmit={handleSubmit}>
                        <div className="form-group">
                          <input
                            id="email"
                            name="email"
                            tabIndex="2"
                            autoFocus
                            type="text"
                            placeholder="Email Address"
                            value={formValues.email}
                            onChange={handleChange}
                          />
                          {ShowError(formValues)}
                        </div>
                        <div className="row-form style-1">
                          <label></label>
                          <Link
                            className="forgot-pass"
                            to=""
                            onClick={() => props.changeType("login")}
                          >
                            Login?
                          </Link>
                        </div>

                        <button className="submit">Submit</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
      </div>
    </React.Fragment>
  );
};
export default ForgotPassword;
