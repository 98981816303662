import React, { useEffect, useState, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { EMAIL_REGEX } from "../services/contants";
import { PASSWORD_REGEX } from "../services/contants";
import axios from "./../axiosinstance";
import LoadingOverlay from "react-loading-overlay";
import VerifyEmailOrPassword from "./VerifyEmailOrPassword";
import Context from "../store/context";

const SignUp = () => {
  LoadingOverlay.propTypes = undefined
  const { commonGlobalState } = useContext(Context);
  const { globalDispatch } = commonGlobalState;
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    country: "",
    username: "",
    termsAndConditions: false,
  };
  const initialValuesBlur = {
    firstNameBlur: false,
    lastNameBlur: false,
    emailBlur: false,
    passwordBlur: false,
    confirmPasswordBlur: false,
    countryBlur: false,
    usernameBlur: false,
    termsAndConditions: false,
  };
  let [countryName, setCountryName] = useState();
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  let [loading, setLoading] = useState(false);
  const [isValidUserName, checkUsername] = useState();
  const [isValidEmail, checkEmail] = useState();
  const [verificationPage, setVerificationPage] = useState("signup");
  let [passwordShow, setPasswordShow] = useState(false);
  let [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  let [blurField, setBlurField] = useState(initialValuesBlur);
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    return () => {
      setFormErrors({});
    };
  }, []);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      signUpSubmit();
    }
  }, [formErrors]);

  React.useEffect(() => {
    getAllCountries();
  },[]);
  const handleChangeSelect = (e) => {
    countryName = e.target.value;
    setCountryName(countryName)

  };

  const getAllCountries = () => {
    const payload = {};
    axios
      .get(`admin/v1/countries/`, {
        params: payload,
      })
      .then((response) => {
        let data = response.data.data.rows;
        setCountryList(data)
        setLoading(false);
      })
      .catch((error) => {
        });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value.replace(/\s/g, "").length || value === "")
      setFormValues({ ...formValues, [name]: value });
  };

  const handleChangeUserName = (e) => {
    const regex = /[^A-Za-z0-9]+/;
    const { name, value } = e.target;
    if (name === "username" && (value === "" || !regex.test(value))) {
      usernameCall(value);
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleChangeEmail = (e) => {
    const { name, value } = e.target;
    if (value.replace(/\s/g, "").length || value === "") {
      if (EMAIL_REGEX.test(value)) emailCall(value);
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleFirstLastName = (e) => {
    const regex = /[^a-zA-Z]/;
    const { name, value } = e.target;
    if (value === "" || !regex.test(value)) {
      setFormValues({ ...formValues, [name]: capitalizeFirstLetter(value) });
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const isUsernameExist = (value) => {
    axios
      .put(`api/v1/user/verifyUsername?username=${value}`, {})
      .then((response) => {
        if (response.data.statusCode === 200) {
          checkUsername(true);
        }
      })
      .catch((error) => {
        checkUsername(false);
        const toasterError = {
          type: "error",
          message: error.response.data.message,
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      });
  };

  const isEmailExist = (value) => {
    const payload = {
      email: value,
    };
    axios
      .put(`api/v1/user/checkEmail`, payload)
      .then((response) => {
        if (response.data.statusCode === 200) {
          checkEmail(true);
        }
      })
      .catch((error) => {
        checkEmail(false);
        const toasterError = {
          type: "error",
          message: error.response.data.message,
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      });
  };

  const usernameCall = useCallback(debounce(isUsernameExist), []);
  const emailCall = useCallback(debounce(isEmailExist), []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const toggleCheckbox = (item) => {
    item = !item;
    setFormValues({ ...formValues, termsAndConditions: item });
  };

  const ShowError = (values) => {
    if (isSubmit || values.email) {
      let error = "";
      if (!values.email) {
        error = "Email is required";
      } else if (!EMAIL_REGEX.test(values.email)) {
        error = "Please enter valid email address.";
      }
      return <p className="error">{error}</p>;
    }
  };

  const firstNameError = (values) => {
    let error = "";
    if (isSubmit || values.firstName) {
      if (!values.firstName) {
        error = "First Name is required.";
      } else if (values.firstName.length < 2) {
        error =
          "Min. length of first name 2 characters and max. length 20 characters.";
        return error ? (
          <p className="error" style={{ color: "green" }}>
            {error}
          </p>
        ) : (
          ""
        );
      } else if (values.firstName.length > 20) {
        error =
          "Min. length of first name 2 characters and max. length 20 characters.";
        return error ? (
          <p className="error" style={{ color: "green" }}>
            {error}
          </p>
        ) : (
          ""
        );
      }
      return error ? <p className="error">{error}</p> : "";
    }
  };

  const lastNameError = (values) => {
    if (isSubmit || values.lastName) {
      let error = "";
      if (!values.lastName) {
        error = "Last Name is required.";
      } else if (values.lastName.length < 2) {
        error =
          "Min. length of last name 2 characters and max. length 20 characters.";
        return error ? (
          <p className="error" style={{ color: "green" }}>
            {error}
          </p>
        ) : (
          ""
        );
      } else if (values.lastName.length > 20) {
        error =
          "Min. length of last name 2 characters and max. length 20 characters.";
        return error ? (
          <p className="error" style={{ color: "green" }}>
            {error}
          </p>
        ) : (
          ""
        );
      }
      return error ? <p className="error">{error}</p> : "";
    }
  };

  const userNameError = (values) => {
    if (isSubmit || values.username) {
      const errors = {};
      if (!values.username) {
        errors.username = "Username is required.";
      } else if (values.username.length > 10) {
        // checkUsername(false);
        errors.username = "Username cannot exceed  more than 10 characters.";
      
        return (<>         
          <p className="error">
            {errors.username}
          </p>
          </>

        );
      }
      return <p className="error">{errors.username}</p>;
    }
  };

  const PasswordError = (values) => {
    let error = "";
    if (isSubmit || values.password) {
      if (!values.password) {
        error = "Password is required.";
      } else if (!PASSWORD_REGEX.test(values.password)) {
        error =
          "Password must be at least 8 characters & maximum 20 characters long with 1 uppercase, 1 lowercase, 1 special character & 1 numeric character";
      } else if (values.password.length < 8) {
        error = "Password must be more than 8 characters.";
      } else if (values.password.length > 20) {
        error = "Password cannot exceed  more than 20 characters.";
      }
    }
    if (!PASSWORD_REGEX.test(values.password)) {
      error =
        "Password must be at least 8 characters & maximum 20 characters long with 1 uppercase, 1 lowercase, 1 special character & 1 numeric character";
    }
    return (
      <p className="error" style={{ color: "green" }}>
        {error}
      </p>
    );
  };

  const PasswordErrorDown = (values) => {
    let error = "";
    if (isSubmit || values.password) {
      if (!values.password) {
        error = "Password is required.";
      }
    }
    return <p className="error">{error}</p>;
  };

  const confirmPasswordError = (values) => {
    if (isSubmit || values.confirmPassword) {
      let error = "";
      if (!values.confirmPassword) {
        error = "Confirm Password is required.";
      } else if (values.password !== values.confirmPassword) {
        error =
          "Confirm Password should be same as password entered in Set Password field.";
      }
      return <p className="error">{error}</p>;
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "First Name is required.";
    } else if (values.firstName.length < 2) {
      errors.firstName =
        "Min. length of first name 2 characters and max. length 20 characters.";
    } else if (values.firstName.length > 20) {
      errors.firstName =
        "Min. length of first name 2 characters and max. length 20 characters.";
    }
    if (!values.lastName) {
      errors.lastName = "Last Name is required.";
    } else if (values.lastName.length < 2) {
      errors.lastName =
        "Min. length of last name 2 characters and max. length 20 characters.";
    } else if (values.lastName.length > 20) {
      errors.lastName =
        "Min. length of last name 2 characters and max. length 20 characters.";
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!EMAIL_REGEX.test(values.email)) {
      errors.email = "Please enter valid email address.";
    }
    if (!values.password) {
      errors.password = "Password is required.";
    } else if (!PASSWORD_REGEX.test(values.password)) {
      errors.password = "Password should be in required format.";
    } else if (values.password.length < 8) {
      errors.password = "Password must be more than 8 characters.";
    } else if (values.password.length > 20) {
      errors.password = "Password cannot exceed  more than 20 characters.";
    }
    if (!values.username) {
      errors.username = "Username is required.";
    } else if (values.username.length > 10) {
      errors.username = "Username cannot exceed  more than 10 characters.";
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm Password is required.";
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword =
        "Confirm Password should be same as password entered in Set Password field.";
    }
    if (!values.termsAndConditions) {
      errors.termsAndConditions =
        "Please agree to the Terms & Conditions and Privacy Policy.";
    }

    return errors;
  };

  const togglePassword = (item) => {
    item = !item;
    setPasswordShow(item);
  };

  const toggleConfirmPassword = (item) => {
    item = !item;
    setConfirmPasswordShow(item);
  };

  const showGreenTickEmail = (value) => {
    return (
      <div className="green_icon">
        {!value?.props?.children ||
          (isValidEmail == true && !value?.props?.children ? (
            <i className="fa fa-check"></i>
          ) : (
            ""
          ))}
        {value?.props?.children || !isValidEmail || isValidEmail == false ? (
          <i className="fa fa-times"></i>
        ) : (
          ""
        )}
      </div>
    );
  };

  const showGreenTick = (value) => {
    return (
      <div className="green_icon">
        {!value?.props?.children && <i className="fa fa-check"></i>}
        {value?.props?.children && <i className="fa fa-times"></i>}
      </div>
    );
  };

  const inputFieldBlur = (name) => {
    setBlurField({ ...blurField, [name]: true });
  };

  const showGreenTickPassword = (value, formValue) => {
    return (
      <div className={formValue ? "green_icon icon-left" : "green_icon"}>
        {!value?.props?.children && <i className="fa fa-check"></i>}
        {value?.props?.children && <i className="fa fa-times"></i>}
      </div>
    );
  };

  // submit signup
  const signUpSubmit = () => {
    setLoading(true);
    const body = document.getElementsByTagName("body")[0];
    body?.classList.add("fixed-loader");
    const payload = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      password: formValues.confirmPassword,
      countryCode: formValues.country.code,
      countryName: countryName,
      platformType: "WEB",
      deviceToken: "1234567",
      userType: 1,
      userName: formValues.username,
    };
    axios
      .post(`api/v1/user/sign-up`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          body?.classList.remove("fixed-loader");
          setLoading(false);
          setVerificationPage("verifyEmail");
        }
      })
      .catch((error) => {   
        const toasterError = {
          type: "error",
          message: error.response.data.message,
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
        setLoading(false);
        body?.classList.remove("fixed-loader");
      });
  };

  return (
    <div>
      <LoadingOverlay active={loading} spinner={true} text="Loading...">
        <section className="flat-title-page inner ">
          <div className="overlay"></div>
          <div className="themesflat-container">
            <div className="page-title-heading mg-bt-12">
              <h1 className="heading text-center">
                {verificationPage === "signup"
                  ? "Signup"
                  : "Registration Successful"}
              </h1>
            </div>
          </div>
        </section>
        {verificationPage === "signup" && (
          <section className="tf-login tf-section darkGray">
            <div className="themesflat-container">
              <div className="row">
                <div className="col-12">
                  <div className="flat-form box-login-email">
                    <p className=" fnt-16 mb-3">
                      Fields marked with * are mandatory.
                    </p>
                    <div className="form-inner">
                      <form id="contactform" onSubmit={handleSubmit}>
                        <div className="form-group position-relative">
                          <input
                            id="name"
                            name="firstName"
                            tabIndex="1"
                            autoFocus
                            value={formValues.firstName}
                            onChange={handleFirstLastName}
                            onBlur={() => inputFieldBlur("firstNameBlur")}
                            type="text"
                            placeholder="First Name *"
                          />
                          {blurField.firstNameBlur &&
                            formValues.firstName &&
                            showGreenTick(firstNameError(formValues))}
                          {firstNameError(formValues)}
                        </div>
                        <div className="form-group position-relative">
                          <input
                            id="name"
                            name="lastName"
                            tabIndex="1"
                            value={formValues.lastName}
                            onChange={handleFirstLastName}
                            onBlur={() => inputFieldBlur("lastNameBlur")}
                            type="text"
                            placeholder="Last Name *"
                          />
                          {blurField.lastNameBlur &&
                            formValues.lastName &&
                            showGreenTick(lastNameError(formValues))}
                          {lastNameError(formValues)}
                        </div>
                        <div className="form-group position-relative">
                          <input
                            id="email"
                            name="email"
                            tabIndex="2"
                            value={formValues.email}
                            onChange={handleChangeEmail}
                            onBlur={() => inputFieldBlur("emailBlur")}
                            type="text"
                            placeholder="Email Address *"
                          />
                          <div className="green_icon">
                            {formValues.email &&
                              EMAIL_REGEX.test(formValues.email) &&
                              isValidEmail &&
                              isValidEmail == true && (
                                <i className="fa fa-check"></i>
                              )}
                            {formValues.email &&
                              EMAIL_REGEX.test(formValues.email) &&
                              !isValidEmail &&
                              isValidEmail == false && (
                                <i className="fa fa-times"></i>
                              )}
                          </div>
                          {blurField.emailBlur &&
                            formValues.email &&
                            showGreenTickEmail(ShowError(formValues))}
                          {ShowError(formValues)}
                        </div>
                        <div className="form-group position-relative">
                          <input
                            id="email"
                            name="username"
                            tabIndex="2"
                            maxLength="10"
                            value={formValues.username}
                            onBlur={() => inputFieldBlur("usernameBlur")}
                            onChange={handleChangeUserName}
                            type="text"
                            placeholder="Username *"
                          />

                          <div className="green_icon">
                            {formValues.username &&
                              isValidUserName &&
                              isValidUserName == true && (
                                <i className="fa fa-check"></i>
                              )}
                            {formValues.username &&
                              !isValidUserName &&
                              isValidUserName == false && (
                                <i className="fa fa-times"></i>
                              )}
                          </div>

                          {userNameError(formValues)}
                        </div>
                        <div className="form-group">
                          <select
                            className="w-100"
                            name="country"
                            placeholder="Select Country"
                            value={countryName}
                            onChange={handleChangeSelect}
                          >
                            <option value="" selected disabled>Select Country</option>
                            {countryList &&
                              countryList.map((item) => (
                                <option value={item.name} key={item.id}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {formValues.password &&
                          // <p className="error-des">
                          //   Password must be at least 8 characters & maximum 20
                          //   characters long with 1 uppercase, 1 lowercase, 1
                          //   special character & 1 numeric character.
                          // </p>
                          PasswordError(formValues)}
                        <div className="form-group position-relative">
                          <input
                            id="pass"
                            name="password"
                            tabIndex="3"
                            value={formValues.password}
                            onChange={handleChange}
                            type={passwordShow ? "text" : "password"}
                            onBlur={() => inputFieldBlur("passwordBlur")}
                            placeholder="Set Password *"
                          />
                          {formValues.password && (
                            <i
                              className={
                                passwordShow
                                  ? "fa fa-eye pointer"
                                  : "fa fa-eye-slash pointer"
                              }
                              aria-hidden="true"
                              onClick={() => togglePassword(passwordShow)}
                            ></i>
                          )}
                          {blurField.passwordBlur &&
                            formValues.password &&
                            showGreenTickPassword(
                              PasswordError(formValues),
                              formValues.password
                            )}
                          {!formValues.password &&
                            PasswordErrorDown(formValues)}
                        </div>
                        <div className="form-group position-relative">
                          <input
                            id="pass"
                            name="confirmPassword"
                            tabIndex="3"
                            value={formValues.confirmPassword}
                            onBlur={() => inputFieldBlur("confirmPasswordBlur")}
                            onChange={handleChange}
                            type={confirmPasswordShow ? "text" : "password"}
                            placeholder="Confirm Password *"
                          />
                          {formValues.confirmPassword && (
                            <i
                              className={
                                confirmPasswordShow
                                  ? "fa fa-eye pointer"
                                  : "fa fa-eye-slash pointer"
                              }
                              aria-hidden="true"
                              onClick={() =>
                                toggleConfirmPassword(confirmPasswordShow)
                              }
                            ></i>
                          )}
                          {blurField.confirmPasswordBlur &&
                            formValues.confirmPassword &&
                            showGreenTickPassword(
                              confirmPasswordError(formValues),
                              formValues.confirmPassword
                            )}
                          {confirmPasswordError(formValues)}
                        </div>

                        <div className="row-form d-block position-relative">
                          <div>
                            <label>
                              I agree to the
                              <Link
                                className="forgot-pass"
                                to="/terms-Condition"
                                target="_blank"
                              >
                                &nbsp;Terms & Conditions
                              </Link>
                              &nbsp;and
                              <Link
                                className="forgot-pass"
                                to="/privacy-policy"
                                target="_blank"
                              >
                                &nbsp;Privacy Policy *
                              </Link>
                              <input
                                name="termsAndConditions"
                                type="checkbox"
                                className="terms-conditions"
                                checked={formValues.termsAndConditions}
                                onChange={() =>
                                  toggleCheckbox(formValues.termsAndConditions)
                                }
                              />
                              <span className="btn-checkbox terms-conditions-checkbox"></span>
                            </label>
                          </div>
                          <p className="error terms-conditions-error">
                            {!formValues.termsAndConditions &&
                              formErrors.termsAndConditions}
                          </p>
                        </div>
                        <div className="row-form style-1 d-block txt">
                          <p>
                            We do not allow citizens or residents of Bahamas to sign up on our platform.
                          </p>
                        </div>

                        <button className="submit">Register</button>
                        <div className="text-center mt-3">
                          <a className="forgot-pass">
                            Already have an account?
                          </a>
                          <Link
                            to="/login"
                            className="forgot-pass create-account"
                          >
                            &nbsp;Login
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {verificationPage === "verifyEmail" && (
          <VerifyEmailOrPassword
            type="email"
            errorDescription="Please click on the verification link sent to your email address
                to verify your account"
          />
        )}
      </LoadingOverlay>
    </div>
  );
};

export default SignUp;