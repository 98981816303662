import Home06 from "./Home06";
import Home08 from "./Home08";
import ItemDetails01 from "./ItemDetails01";
import WalletConnect from "./WalletConnect";
import CreateItem from "./CreateItem";
import EditProfile from "./EditProfile";
import Ranking from "./Ranking";
import KycDetails from "../components/layouts/my-collection/kyc/Kyc-form";
import Truliookyc from "../components/layouts/my-collection/kyc/trulioo-kyc";
import Login from "./Login";
import SignUp from "./SignUp";
import NoResult from "./NoResult";
import FAQ from "./FAQ";
import ComingSoon from "./ComingSoon";
import VerifyEmailOrPassword from "./VerifyEmailOrPassword";
import Release from "./Release";
import ItemDetails from "./ItemDetails";
import MyCollection from "./MyCollection";
import Brands from "./Brands";
import Airdrop from "./Airdrop";
import Aboutus from "./About";
import AirdropItemDetails from "./AirdropItemDetails";
import MarketplaceDetails from "./MarketplaceDetails";
import MarketPlaceItemDetails from "./MarketPlaceItemDetails";
import LatestNews from "./LatestNews";
import RoadMap from "./roadmap";
import ReleaseDetails from "./ReleaseDetails";
import MarketDetails from "./MarketDetails";
import Terms from "./Terms";
import PrivacyPolicy from "./PrivacyPolicy";

const routes = [
  { path: "/", component: <Home06 /> },
  { path: "/home-06", component: <Home06 /> },
  { path: "/marketplace", component: <Home08 /> },
  { path: "/market-details", component: <MarketDetails /> },
  { path: "/item-details-01", component: <ItemDetails01 /> },
  { path: "/bottle-details", component: <ItemDetails01 /> },
  { path: "/item-details", component: <ItemDetails /> },
  { path: "/marketPlace-item-details", component: <MarketPlaceItemDetails /> },
  { path: "/airdropitem-details", component: <AirdropItemDetails /> },
  { path: "/marketPlace-details", component: <MarketplaceDetails /> },
  { path: "/wallet-connect", component: <WalletConnect /> },
  { path: "/create-item", component: <CreateItem /> },
  { path: "/profile", component: <EditProfile /> },
  { path: "/favorites", component: <Ranking /> },
  { path: "/login", component: <Login /> },
  { path: "/sign-up", component: <SignUp /> },
  { path: "/no-result", component: <NoResult /> },
  { path: "/kyc", component: <KycDetails /> },
  { path: "/trulioo-kyc", component: <Truliookyc /> },
  { path: "/release", component: <Release /> },
  { path: "/brands/:id", component: <Brands /> },
  { path: "/my-collection", component: <MyCollection /> },
  // { path: "/marketplace", component: <ComingSoon /> },
  { path: "/about-us", component: <Aboutus /> },
  { path: "/terms-Condition", component: <Terms /> },
  { path: "/privacy-policy", component: <PrivacyPolicy /> },
  { path: "/latest-news", component: <LatestNews /> }, 
  { path: "/verify", component: <VerifyEmailOrPassword /> },
  { path: "/airdrop", component: <Airdrop /> },
  { path: "/faq", component: <FAQ /> },
  { path: '/roadmap', component: <RoadMap /> },
  { path: '/releaseDetails/:id', component: <ReleaseDetails /> }
];

export default routes;