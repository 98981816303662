import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import img1 from "../assets/images/avatar/avt-3.jpg";
import { useLocation } from "react-router-dom";
import { ethers } from "ethers";
import { connectMetamask, connectCoinbase } from "../Helpers/contractMethods";
import {  contractAddressEscrow,  getContract,  etherScanUrl,  getContractEscrow,contractAddress} from "../Helpers/contract";
import axios from "../axiosinstance";
import LoadingOverlay from "react-loading-overlay";
import Context from "../store/context";
import * as moment from "moment";
import ListToMarketPlace from "../components/layouts/ListToMarketplace";
import OfferMade from "./OfferMade";
import {s3Url} from "../store/baseUrl";

const MarketplaceDetails = () => {
  LoadingOverlay.propTypes = undefined
  const location = useLocation();
  let navigate = useNavigate();
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch } = commonGlobalState;
  let [loading, setLoading] = useState(true);
  const [web3Library, setWeb3Library] = React.useState();
  const [web3Account, setWeb3Account] = React.useState();
  const [releaseBottleDetail, setReleaseBottleDetails] = useState([]);
  const [bottleId, setBottleId] = useState();
  const [modalShow, setModalShow] = useState(false);

  React.useEffect(() => {
    const [a, id] = location.search.split("?id=");
    setBottleId(id);
    getReleaseBottelsDetails(id);
  }, []);

  const getReleaseBottelsDetails = (id) => {
    axios
      .get(`api/v1/user/owned/${id}`)
      .then((response) => {

        setReleaseBottleDetails(response.data.data);
        setLoading(false);


      })
      .catch((error) => {

      });
  };

  const saveWalletDetails = (walletType, account) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `api/v1/user/connectUserWallet?walletType=${walletType}&walletId=${account}&detail=description`,
          {}
        )
        .then((response) => {

          if (response.data.statusCode == 200) {
            localStorage.setItem("activeWallet", walletType);
            resolve(true);
          }
        })
        .catch((error) => {

          resolve(true);
        });
    });
  };

  const connectWalletHandler = async (id) => {
    try {
      const res = await connectMetamask();
      setWeb3Account(res.account);
      setWeb3Library(res.library);
      const activeWallet = localStorage.getItem("activeWallet");
      removeTokenToMarketPlace(res.library, res.account, id);
    } catch (e) {

    }
  };

  const mintBottleNFT = async (library, account, id, oldtoken, price) => {
    setLoading(true);
    try {
      const myContract = getContract(library, account);
      const overrides = {
        value: ethers.utils.parseEther(price),
      };
      const response = await myContract.mint(
        "1",
        ethers.utils.parseEther("0.01"),
        overrides
      );
      const receipt = await response.wait();
      const receiptTokenId = Math.round(
        parseFloat(
          ethers.utils.formatUnits(receipt.events[0].args.tokenId["_hex"], 18)
        ) *
        10 ** 18
      );
      payment(receipt, 0.01, receiptTokenId, id, oldtoken);
    } catch ({ ex }) {
      const error = JSON.parse(JSON.stringify(ex));

      if (error.code === "INSUFFICIENT_FUNDS") {
        const toasterError = {
          type: "error",
          message:
            "Your wallet balance is low, please add sufficient ETH to mint",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      } else {
        const toasterError = {
          type: "error",
          message: "User rejected the request.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      }
      setLoading(false);
    }
  };

  const approveContract = async (library, account, isApprove) => {
    try {
      const myContract = getContract(library, account);
      const overrides = {
        // gasLimit: 230000,
        value: ethers.utils.parseEther("0.01"),
      };
      const response = await myContract.setApprovalForAll(
        contractAddressEscrow,
        "1"
      );
      const receipt = await response.wait();

      const receiptTokenId = Math.round(
        parseFloat(
          ethers.utils.formatUnits(receipt.events[0].args.tokenId["_hex"], 18)
        ) *
        10 ** 18
      );
      // payment(receipt, 0.01, receiptTokenId, id, oldtoken);
    } catch (ex) {
      const error = JSON.parse(JSON.stringify(ex));
      if (error.code === "INSUFFICIENT_FUNDS") {
        const toasterError = {
          type: "error",
          message:
            "Your wallet balance is low, please add sufficient ETH to mint",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      } else {
        const toasterError = {
          type: "error",
          message: "User rejected the request.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      }
      setLoading(false);
    }
  };

  const removeTokenToMarketPlace = async (library, account, id) => {
    try {
      const myContract = getContractEscrow(library, account);
      const response = await myContract.cancelSell(releaseBottleDetail.blockChainNFTId.toString())
      const receipt = await response.wait();
      payment(0.01, id);
    } catch (ex) {
      const error = JSON.parse(JSON.stringify(ex));
      if (error.code === "INSUFFICIENT_FUNDS") {
        const toasterError = {
          type: "error",
          message:
            "Your wallet balance is low, please add sufficient ETH to mint",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      } else {
        const toasterError = {
          type: "error",
          message: "User rejected the request.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      }
      setLoading(false);
    }
  };

  const mintNFT = async (id) => {
    setLoading(true);
    connectWalletHandler(id);
  };

  const formatTime = (time) => {
    return moment(time).format("MMM DD, yyyy");
  };

  const payment = (price, id) => {
    const payload = {
      productItemId: id,
    };
    axios
      .put(`/api/v1/user/owned/removeToMarketPlace`, payload)
      .then((response) => {

        setLoading(false);
        navigate('/my-collection')
        const toasterError = {
          type: "success",
          message: `NFT #${releaseBottleDetail.blockChainNFTId}  removed from marketplace successfully.`,
           
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
        // this.toastr.success('Item has been removed from marketplace successfully', '')
      })
      .catch((error) => {

        setLoading(false);
      });
  };

  const ETHtoUSDPrice = (value) => {
    // const ethPrice = localStorage.getItem("priceInETH") || 0;
    const ethPrice = localStorage.getItem("priceInUSD") || 0;
    const price = value * ethPrice;
    return price.toFixed(6);
  };

  const getData = (data) => {

    // navigate(route);
    if (data) {
      setModalShow(false);
      mintNFT(bottleId);
    }
  };
  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };

  const closePopup = () => {
    setModalShow(false);
  };

  return (
    <div className="item-details ProductDetailsOne withoutHeader">
      <LoadingOverlay active={loading} spinner={true} text="Loading...">
        <div className="tf-section">
          <div className="themesflat-container">
            <div className="row">
              <div className="col-xl-6 col-md-12">
                <div className="content-left">
                  <div className="imgBox">
                    {/* <video width="750" height="500" autoPlay muted>
                      <source src={video} type="video/mp4" />
                    </video> */}
                    <img
                      src={`${s3Url}icons/${releaseBottleDetail &&
                        releaseBottleDetail.product &&
                        releaseBottleDetail.product.imageUrl
                        }`}
                      alt="Axies"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-12">
                <div className="contBox">
                  <div className="sc-item-details">
                    <h2 className="style2">
                      {releaseBottleDetail &&
                        releaseBottleDetail.product &&
                        releaseBottleDetail.product.brandName} {releaseBottleDetail &&
                          releaseBottleDetail.product &&
                          releaseBottleDetail.product.title}
                    </h2>

                    <p>
                      {releaseBottleDetail &&
                        releaseBottleDetail.product &&
                        releaseBottleDetail.product.description}
                    </p>
                    <div className="meta-item-details style2">
                      <div className="row">
                        <div className="col-6">
                          <div className="item meta-price w-100">
                            <span className="heading">Price</span>
                            <div className="price">
                              <div className="price-box d-flex align-items-center">
                                <h5>
                                  {releaseBottleDetail && releaseBottleDetail.sellingAmount} ETH
                                </h5>
                                {/* <span>= $12.246</span> */}
                                {/* <span>
                              (
                              {ETHtoUSDPrice(
                                releaseBottleDetail &&
                                  releaseBottleDetail.product &&
                                  releaseBottleDetail.product.price
                              )}
                              &nbsp;ETH)
                            </span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="item meta-price w-100">
                            <div className="price">
                              <div className="price-box d-flex align-items-center">
                                <h5>
                                  NFT ID #{releaseBottleDetail.blockChainNFTId}
                                </h5>
                              </div>
                            </div>
                            <a className="viewBtnEth" href={`${etherScanUrl}${contractAddress}?a=${releaseBottleDetail.blockChainNFTId}`} target="_blank" tr> View on Ethersan</a>
                          </div>
                        </div>
                      </div>



                    </div>

                    <button
                      onClick={() => mintNFT(bottleId)}
                      className="roundBtn widthAuto mr-5"
                    >
                      <span>Remove from Marketplace</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ListToMarketPlace
          show={modalShow}
          sendPrice={getData}
          closeModal={closePopup}
        // show={modalShow}
        // onHide={() => setModalShow(false)}
        />
      </LoadingOverlay>
    </div>
  );
};

export default MarketplaceDetails;
