import React, { useState } from "react";
import axios from "../axiosinstance";
import { Link, useParams } from "react-router-dom";
import apiUrl from "../store/globalapiurl"
import HowtoWorkVideo from "../components/layouts/home-6/HowtoWorkVideo";
import PaiverseCommunity from "../components/layouts/home-6/PaiverseCommunity";
import LoadingOverlay from "react-loading-overlay";
import MarketPlace from "../components/layouts/home-6/MarketPlace";
import img from "../../src/assets/images/brands/img_penderyn.png";
import bot from "../../src/assets/images/box-item/bottle1.jpg";
import img1 from "../../src/assets/images/slider/bottle1.jpg";
import img2 from "../../src/assets/images/slider/bottle2.jpeg";
import img3 from "../../src/assets/images/slider/bottle3.jpg";
import awards from "../../src/assets/images/slider/awards.jpg";
import tast from "../../src/assets/images/product_details/img_peneryn.png";

const Brands = () => {
  LoadingOverlay.propTypes = undefined
  const { id } = useParams();
  let [loading, setLoading] = useState(true);
  const [brandData, setBrandData] = useState([]);
  const [data] = useState([
    {
      img: tast,
      title: "Penderyn",
    },


  ]);
  React.useEffect(() => {
   // getBrandList();
  }, []);

  const getBrandList = () => {
    const payload = {};
    axios
      .get(apiUrl._getBrandDetails + id, {
        params: payload,
      }).then((response) => {
        setBrandData(response.data.data);
        setLoading(false);
    
      })
      .catch((error) => {
     
      });
  };

  return (
    <div className="home-8 brands">
      <section className=' flat-title-page  inner brandBg'>
        {/* <h1 className="heading text-white">
          {brandData.name}</h1>
        <p>{brandData.bannerDescription}</p> */}
        <h1 className="heading text-white">Penderyn</h1>
        <p>The first distillery to manufacture a whisky out of<br/> Wales in over a century</p>
      </section>
      <section className='tf-section text-white'>
        <div className="themesflat-container">
          <div className="row ">
            <div className="col-md-4 col-lg-5 col-12">
              <div className="imgBox brandImg">
                <img src={img} />
              </div>
            </div>
            <div className="col-md-8 col-lg-7 col-12">
              <div className="brandInfotxt text-justify">
                <p>Historically the whisky industry established itself in a few countries around the world, with Scotland being the heartland of single malt whisky production. Since the turn of the new millennium, there has been an explosion in the opening of single malt whisky distilleries the world over, leading to the creation of the World Whisky category (supplementing the existing Scotch, American, Irish & Japanese categories). One of the pioneers in this emerging whisky category is Penderyn Distillery, which started distilling in 2000 and is now recognised internationally for its award-winning Welsh Single Malt Whiskies, which are now exported from Wales to over 50 countries.</p>

                <p>Alongside being one of the pioneers in the World Whisky category, they've also challenged the traditional art of distillation, through the use of an innovative single copper pot still, the Penderyn Faraday still, a process unlike any other in the single malt whisky industry.</p>

                <p>The Penderyn Faraday still produces a light, fruity and exceptionally clean spirit, at an industry high strength of around 92% abv. This along with the exceptional casks chosen for their whisky maturation, maximises the full flavour qualities in their whiskies.</p>

                <p>As the World Whisky category matures and comes into its own, it is apt to start releasing some of the oldest whisky available. Due to the age of this category, there are very few World Whisky producers who are able to release whisky older than 10 years. However, as Penderyn has been distilling since 2000, they have a limited number of casks which have been maturing for 20 years, making them rare and extremely desirable.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="tf-section pb-10">
            <h2 className="tf-title text-left pb-24">Releases</h2>
            <div className="row">
              {data.map((item) => (
                <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                  <div className="sc-card-product">
                    <div className="card-media whiteBg">
                      <Link >
                        <img src={item.img} alt="axies" />
                      </Link>
                    </div>
                    <div className="card-title d-flex">
                      <div className="left">
                        <h3>
                          <Link >
                            {item.title}
                          </Link>
                        </h3>
                      </div>
                      <div className="button-place-bid">
                        <Link className="tags more">
                          Explore More
                        </Link>
                      </div>
                    </div>

                  </div>
                </div>
              ))}
            </div>

          </div> */}
        </div>
      </section>
      <section className='background'> </section>
      <section className='faraday'>
        <div className="themesflat-container">
          <div className="faradayContent text-justify">
            <h2 className="heading text-center text-white mb-5">Faraday Still</h2>
            <p className="fst-italic">It was designed by Dr David Faraday, a distant relative of Victorian physicist, Michael Faraday.This magical process not only imbues our raw spirit with great complexity, depth and finesse, but also removes many of the undesirable chemical compounds - something that a conventional pot still system cannot achieve. Arriving at the spirit safe at an industry high of 92% abv, our spirit is virtually free from these chemical compounds and this becomes crucial during cask ageing. Scotland can't use this a single column still as their Geographical Indicators state that 2 pot stills must be used. </p>
          </div>
        </div>
      </section>
      <HowtoWorkVideo />
      <div className="awardsWrap mg-t-40">
        <img src={awards} alt="axies" />
      </div>
      {/* <MarketPlace /> */}
      <div className="darkGray tf-section">
        <PaiverseCommunity />
      </div>

    </div>

  );
};

export default Brands;
