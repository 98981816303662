import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import * as moment from "moment";
const ListToMarketPlace = (props) => {
  const initialValues = {
    price: "",
    days: "",
  };
  let date = new Date().toLocaleString('en-US', { timeZone: 'America/New_York', });
  let newDate = moment(date).add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
  let minDate = moment(newDate).format("YYYY-MM-DD");
  const [formValues, setFormValues] = useState(initialValues);
  const [bidType, setBidType] = useState(0);
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState();
  const [amountError, setAmountError] = useState();
  const [dateError, setDateError] = useState();
  useEffect(() => {
    if (props.show === false) {
      setBidType(0);
      setFormValues(initialValues);
      setFormErrors("");
      setAmountError("");
    }
  }, [props]);

  const handleDaysInput = (e) => {
    setDateError("");
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleFixedChange = (type) => {
    setBidType(type);

  };

  const bidError = () => {
    if (isSubmit) {
      let error = "";
      if (bidType == null) {
        error = "Bid type is required.";
      }
      return error ? <p className="errorPrice m-0">{error}</p> : "";
    }
  };

  const handlePriceInput = (event) => {
    const { name, value } = event.target;
    setFormErrors("")
    setFormValues({ ...formValues, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formValues.price) {
      setAmountError("Price is required.")
      return false;
    }
    if (!formValues.days) {
      setDateError("Expiry date is required.");
      return false;
    }

    const numStr = String(formValues.price);
    if (numStr.includes('.')) {
      const priceLenght = numStr.split('.')[1].length;
      if (priceLenght > 8) {
        setFormErrors("You can enter only 8 digits after the decimal")
        return false
      }
    };
    setIsSubmit(true);
    if (formValues.price && formValues.days && bidType != null) {
      console.log("avtar")
      if (formValues.price > 0) {
        setFormErrors("")
        props.sendPrice({
          price: formValues.price,
          days: formValues.days,
          type: bidType,
        });

      }else{
        if(!amountError){
          setFormErrors("Please enter valid price")
        }
      }
    }
  };



  return (
    <Modal show={props.show} onHide={props.closeModal}>
      <Modal.Header closeButton></Modal.Header>
      <form id="marketPlaceForm" onSubmit={handleSubmit}>
        <div className="modal-body space-y-20 pd-40 pt-0">
          <h3>List item on Marketplace</h3>
          <div className="d-flex">
            <div className="d-flex align-items-center position-relative">
              <input
                type="radio"
                id="fixed"
                name="price"
                checked={bidType === 0}
                onClick={() => handleFixedChange(0)}
              />
              <label htmlFor="fixed" className="price-label">
                Fixed price
              </label>
            </div>

            <div className="ml-5 d-flex align-items-center position-relative disable">
              <input disabled type="radio" id="bidding" name="price" onClick={() => handleFixedChange(1)} />
              <label  htmlFor="bidding" className="price-label">
                Bidding
                <span className="smallTxt"> (Coming soon)</span> 
              </label>
            </div>
          </div>
     

          <p>{bidType ? "Minimum Price" : "Price"}</p>
          <input  name="price"  type="number"  step="any" className="form-control" value={formValues.price}  onChange={handlePriceInput}  placeholder={bidType ? "00.00 WETH " : "00.00 ETH"}
          />
          
          {amountError && (<p className="errorPrice">{amountError}</p>)}
          {formErrors && (<p className="errorPrice">{formErrors}</p>)}
          <p>Expiry</p>
          <input  name="days"  type="date" min={minDate}  value={formValues.days}  onChange={handleDaysInput}  id="bid_expiration_date" className="form-control" />
          {dateError && (<p className="errorPrice">{dateError}</p>)}
          <div className="hr"></div>
          <button className="roundBtn width100" type="submit">
            Add to Marketplace
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ListToMarketPlace;