import React, { useState, Fragment, useRef, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import axios from "../../../axiosinstance";
import {s3Url} from "../../../store/baseUrl";
import { ethers } from "ethers";
import {
  connectMetamask,
  connectCoinbase,
} from "../../../Helpers/contractMethods";
import { getContract, ipfsUrl } from "../../../Helpers/contract";
import LoadingOverlay from "react-loading-overlay";
import Context from "../../../store/context";
import SuccessModal from "../SuccessModal";

const ItemContent = () => {
  LoadingOverlay.propTypes = undefined
  let [releaseBottles, setReleaseBottles] = useState([]);
  const [web3Library, setWeb3Library] = React.useState();
  const [web3Account, setWeb3Account] = React.useState();
  const [activeFilter, setActiveFilter] = React.useState();
  const [showModal, setShowModal] = React.useState(false);
  let [closeModal, setCloseModal] = React.useState(false);
  let [skip, setSkip] = React.useState(0);
  let navigate = useNavigate();
  const location = useLocation();
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch } = commonGlobalState;

  let [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  React.useEffect(() => {
    setLoading(true);
    releaseBottles = [];
    skip = 0;
    setReleaseBottles(releaseBottles);
    setSkip(skip);
    getReleaseBottels();
    return () => {

      releaseBottles = [];
      skip = 0;
      setSkip(skip);
      setReleaseBottles(releaseBottles);
      setShowModal(false);
      setVisible(false);
    };
  }, [location.pathname]);

  React.useEffect(() => {
    setLoading(true);
    if (globalState.searchQuery || globalState.searchQuery == "") {
      releaseBottles = [];
      skip = 0;
      setReleaseBottles(releaseBottles);
      setSkip(skip);
      getReleaseBottels();
    }

    return () => {
      setReleaseBottles([]);
      setSkip(0);
      setShowModal(false);
      setVisible(false);
    };
  }, [globalState.searchQuery]);

  const getReleaseBottels = (sortDirection, orderby) => {
    const payload = {
      search: globalState.searchQuery ? globalState.searchQuery : "",
      sortDirection: sortDirection,
      skip: skip,
      limit: 9,
    };

    if (orderby) payload["orderby"] = orderby;
    axios
      .get(`api/v1/liveStore?isUpComing=0`, {
        params: payload,
      })
      .then((response) => {
        releaseBottles = [...releaseBottles, ...response.data.data.rows];
        setReleaseBottles(releaseBottles);
        if (releaseBottles.length < response.data.data.count) {
          setVisible(true);
        } else {
          setVisible(false);
        }
        setLoading(false);

      })
      .catch((error) => {
   
      });
  };

  const loadMore = () => {
    skip = skip + 9;
    setSkip(skip);
    getReleaseBottels();
  };

  const connectWalletHandler = async (item) => {
    try {
      const res = await connectMetamask();

      setWeb3Account(res.account);
      setWeb3Library(res.library);
      const resolve = await saveWalletDetails("MetaMask", res.account);
      // const resolve = true;

      if (resolve) {
        axios
          .get(`api/v1/liveStore/priceAndToken/${item.id}?buyType=BUY`)
          .then((response) => {

            if (response.data.statusCode == 200) {
              redeemNFT(res.library, res.account, item, response.data.data.token, response.data.data.price.toString());

            }
          })
          .catch((error) => {
    
            setLoading(false);
          });
      }
    } catch (e) {

      const toasterError = {
        type: "error",
        message: "Please connect a different wallet address",
      };
      globalDispatch({ type: "TOASTER", state: toasterError });
    }
  };

  const saveWalletDetails = (walletType, account) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `api/v1/user/connectUserWallet?walletType=${walletType}&walletId=${account}&detail=description`,
          {}
        )
        .then((response) => {

          if (response.data.statusCode == 200) {
            localStorage.setItem("activeWallet", walletType);
            resolve(true);
          }
        })
        .catch((error) => {

          reject(error);
        });
    });
  };

  const mintNFT = (item) => {
    if (globalState.isLoggedIn) {
      connectWalletHandler(item);
    } else {
      navigate(`/login`, { state: location.pathname });
    }
  };


  // store the blockchain transaction
  const payment = (paymentData, dollarPrice, token, id, oldNFTToken) => {
    const payload = {
      token: oldNFTToken,
      receiptTokenId: token,
      id: id,
      blockChainTransactionId: paymentData.transactionHash,
      blockChainTransactionStatus: paymentData.status ? "SUCCESS" : "FAIL",
      from: paymentData.from,
      currency: "ETH",
      to: paymentData.to,
      gasAmount: 2300,
      conversionAmount: dollarPrice,
    };
    axios
      .post(`api/v1/liveStore/payment`, payload)
      .then((response) => {

        if (response.data.statusCode == 200) {
          setShowModal(true);
          setLoading(false);
        }
      })
      .catch((error) => {
 
        setLoading(false);
      });
  };

  const filterData = (sortType, orderType, activeFilter) => {
    releaseBottles = [];
    skip = 0;
    setReleaseBottles(releaseBottles);
    setSkip(skip);
    setLoading(true);
    setActiveFilter(activeFilter);
    getReleaseBottels(sortType, orderType);
  };


  // mint the NFT with main contract
  const redeemNFT = async (library, account, item, token) => {
    setLoading(true);
    try {
      const priceUSD = localStorage.getItem("priceInUSD") || 0;
      const PriceInETH = item.price * priceUSD;

      const overrides = {
        value: ethers.utils.parseEther("0.01"),
      };
      let voucher = {
        uri: ipfsUrl+ item.fileHash,
        minPrice: item.price,
        signature: item.signature,
      };
      const myContract = getContract(library, account);
      const response = await myContract.safeMint(account, voucher);
      // const response = await myContract.burn(3);
      const receipt = await response.wait();
      const receiptTokenId = Math.round(
        parseFloat(
          ethers.utils.formatUnits(receipt.events[0].args.tokenId["_hex"], 18)
        ) *
        10 ** 18
      );
      setLoading(false);
      payment(receipt, 0.01, receiptTokenId, item.id, token);
    } catch (ex) {

      const error = JSON.parse(JSON.stringify(ex));

      if (error.code === "INSUFFICIENT_FUNDS") {
        const toasterError = {
          type: "error",
          message:
            "Your wallet balance is low, please add sufficient ETH to mint",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      } else {
        const toasterError = {
          type: "error",
          message: "User rejected the request.",
        };
        globalDispatch({ type: "TOASTER", state: toasterError });
      }
      setLoading(false);
    }
  };

  // conversion from ETH to USD
  const ETHtoUSDPrice = (value) => {
    // const ethPrice = localStorage.getItem("priceInETH") || 0;
    const ethPrice = localStorage.getItem("priceInUSD") || 0;
    const price = value * ethPrice;
    return price.toFixed(6);
  };

  const getData = (route) => {
    navigate(route);
  };

  const closePopup = () => {
    setShowModal(false);
  };

  const release = () => {
    return (
      <div className="content-item open">
        <SuccessModal
          show={showModal}
          onHide={closeModal}
          redirect={getData}
          closeModal={closePopup}
        />
        {releaseBottles.map((item, key) => (
          <div key={key} className="col-item">
            <div className="sc-card-product menu_card style-h7">
              <div className="meta-info style">
                <button className="wishlist-button heart wishlist-button-release"></button>
              </div>
              <div className="card-media">
                <Link to={`/bottle-details?id=${item.id}`}>
                  <img
                    src={`${s3Url}icons/${item.imageUrl}`}
                    alt="Axies"
                  />
                </Link>
                <div className="button-place-bid">
                  <button
                    onClick={() => mintNFT(item)}
                    disabled={!item.inStock}
                    className={`sc-button style-place-bid style bag fl-button pri-3 ${!item.inStock && `color-red`
                      }`}
                  >
                    <span>{item.inStock ? "Mint" : "Sold Out"} </span>
                  </button>
                </div>
              </div>
              <div className="card-title">
                <h5>
                  <Link to={`/bottle-details?id=${item.id}`}>{item.title}</Link>
                </h5>
              </div>
              <div className="meta-info">
                <div className="author">
                  <div className="info d-flex">
                    <span>Price&nbsp;</span>
                    <h6 className="pricing">{item.price}&nbsp;USD</h6>
                    <span>&nbsp;({ETHtoUSDPrice(item.price)} ETH) </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {visible && (
          <div className="col-md-12 wrap-inner load-more text-center btn-auction item center">
            <Link
              to="#"
              className="sc-button loadmore fl-button pri-3"
              onClick={loadMore}
            >
              <span>Load More</span>
            </Link>
          </div>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <LoadingOverlay active={loading} spinner={true} text="Loading...">
        {/* <div className="flat-tabs items release-item w-100 release-height"   ``> */}
        <div
          className={`flat-tabs items release-item w-100 ${!releaseBottles?.length && `release-height`
            }`}
        >
          <div className="react-tabs">
            <div className="react-tabs__tab-panel react-tabs__tab-panel--selected">
              <div className="option d-block">
                {/* <h2 className="title">1,000 Items</h2> */}
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="title">{"Releases"}</h2>
                  {releaseBottles.length > 0 && !loading && (
                    <div className="view">
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-basic"
                          className="btn-sort-by dropdown"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3 7H21"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                            ></path>
                            <path
                              d="M6 12H18"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                            ></path>
                            <path
                              d="M10 17H14"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                            ></path>
                          </svg>
                          <span>{activeFilter ? activeFilter : "Sort by"}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ margin: 0 }}>
                          {/* <Dropdown.Item
                        href="#"
                        onClick={() =>
                          filterData("ASC", "price", "priceLowToHigh")
                        }
                      >
                        Sort by Release date (Old to New)
                      </Dropdown.Item> */}
                          <Dropdown.Item
                            href="#"
                            className={
                              activeFilter == "High to Low" && "activeFilter"
                            }
                            onClick={() =>
                              filterData("DESC", "price", "High to Low")
                            }
                          >
                            High to Low
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#"
                            className={
                              activeFilter == "Low to High" && "activeFilter"
                            }
                            onClick={() =>
                              filterData("ASC", "price", "Low to High")
                            }
                          >
                            Low to High
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                </div>
                {releaseBottles.length > 0 && release()}
              </div>
            </div>
            {releaseBottles && !releaseBottles?.length && !loading && (
              <div className="noData">
                No data.
              </div>
            )}
          </div>
        </div>
        {/* <CardModal show={modalShow} onHide={() => setModalShow(false)} /> */}
      </LoadingOverlay>
    </Fragment>
  );
};

export default ItemContent;
