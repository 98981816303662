import React, { useEffect, useState, useContext } from "react";
import { Accordion } from "react-bootstrap-accordion";
import apiUrl from "../../../store/globalapiurl"
import axios from "../../../axiosinstance";
import { Scrollbars } from 'react-custom-scrollbars';
import Context from "../../../store/context";
const SideBar = ({ handleClick, handleAge, handleRegion, priceData }) => {
  let [alldata, setData] = useState();
  let [loading, setLoading] = useState(false);
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch } = commonGlobalState;
  const [statusData, setStatusData] = useState(3);
  const initialFormState = {
    min: "",
    max: "", 
  };
  const [form, setForm] = useState(initialFormState);
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const [priceError, setPriceError] = useState();


  const [age, setAge] = useState([]);
  const [cask, setCask] = useState([]);
  const [region, setRegion] = useState([]);
  let [ageLenght, setAgeLenght] = useState();
  let [regionLength, setRegionLength] = useState();
  let [caskLeLength, setCaskLeLength] = useState();



  React.useEffect(() => {
    getFilter();
  }, []);

  useEffect(() => {
    if (age.length != 0) {
      handleAge(age);
    }
    if (ageLenght === 1) {
      handleAge(age);
    }

    if (cask.length != 0) {
      handleClick(cask);
    }
    if (caskLeLength === 1) {
      handleClick(cask);
    }
    if (region.length != 0) {
      handleRegion(region);
    }
    if (regionLength === 1) {
      handleRegion(region);
    }
  }, [age, region, cask, ageLenght, regionLength, caskLeLength]);

  const handleChange = (e) => {
    const value = e.target.value;
    setCask((prev) =>
      cask.includes(value)
        ? prev.filter((cur) => cur !== value)
        : [...prev, e.target.value]
    );
    if (e.target.checked) {
      setCaskLeLength(0)
    } else {
      setCaskLeLength(1)
    }


    // const value = e.target.value;
    // let caskData = [];
    // if (e.target.checked) {
    //   caskData.push(value);
    // } else {
    //   var index = caskData.indexOf(value);
    //   if (index > -1) {
    //     caskData.splice(index, 1);
    //   }
    // }
    // handleClick(caskData);
  };
  const handleAgeFilter = (e) => {
    const value = e.target.value;
    setAge((prev) =>
      age.includes(value)
        ? prev.filter((cur) => cur !== value)
        : [...prev, e.target.value]
    );
    if (e.target.checked) {
      setAgeLenght(0)
    } else {
      setAgeLenght(1)
    }


    // const value = e.target.value;
    // let age = []
    // if (e.target.checked) {
    //   age.push(value);
    // } else {
    //   var index = age.indexOf(value);
    //   if (index > -1) {
    //     age.splice(index, 1);
    //   }
    // }
    // handleAge(age);

  };
  const handleRegionFilter = (e) => {
    const value = e.target.value;
    setRegion((prev) =>
      region.includes(value)
        ? prev.filter((cur) => cur !== value)
        : [...prev, e.target.value]
    );
    if (e.target.checked) {
      setRegionLength(0)
    } else {
      setRegionLength(1)
    }
  };
  // const handleStatusFilter = (e) => {
  //   const value = e.target.value;
  //   setStatusData(value)
  //   handleStatus(value);
  // }
  const submitPrice = (event) => {
    let price = { 'min': minPrice, 'max': maxPrice }
    priceData(price);
  };

  const handleMinPrice = event => {
    let price = event.target.value;
    if (price > 99) {
      setPriceError('You cannot enter a price above 99')
      setMinPrice('');
    }
    else {
      setPriceError('')
      setMinPrice(event.target.value);
    }
  };
  const handleMaxPrice = event => {
    let price = event.target.value;
    if (price > 99) {
      setMinPrice('');
      setPriceError('You cannot enter a price above 99')
    }
    else {
      if (price >= minPrice) {
        setPriceError('')
        setMaxPrice(event.target.value);
      } else {
        setPriceError('You cannot enter a price above 99')
        setMaxPrice('');
      }

    }
  };

  // const handlePrice = (event) => {
  //   //const result = event.target.value.replace(/\D/g, '');
  //   // setMinPrice(result)
  //   const name = event.target.name;
  //   const value = event.target.value
  //   setInputs(values => ({ ...values, [name]: value }));
  // }
  // const submitPrice = () => {
  //   priceData(inputs);
  // }
  const getFilter = () => {
    const payload = {};
    axios
      .get(apiUrl._getFilter, {
        params: payload,
      }).then((res) => {
        let data = res.data.data
        setData(data);

      })
      .catch((error) => {
       });
  };
  return (
    <div id="side-bar" className="side-bar style-3 m-0 item">
      <div className="widget widget-filter style-1 mgbt-0">
        <div className="header-widget-filter">
          <h4 className="title-widget">Filter</h4>
        </div>
      </div>
      <div className="divider"></div>
      <Scrollbars className="mainScroll" style={{ height: 425 }}>
        <div className="wrap-category">
          {/* <div className="widget widget-category filter boder-bt">
            <Accordion title="Status " show={true}>
              <label>
                <span>Buy Now</span>
                <span className="pst-re">
                  <input type="radio" name="status" value="0" onChange={handleStatusFilter} />
                  <span className="btn-checkbox"></span>
                </span>
              </label>
              <label>
                <span>On Auction</span>
                <span className="pst-re">
                  <input type="radio" name="status" value="1" onChange={handleStatusFilter} />
                  <span className="btn-checkbox"></span>
                </span>
              </label>
            </Accordion>
          </div> */}
          <div className="widget widget-category filter boder-bt">
            <Accordion title="Age" show={true}>
              <Scrollbars style={{ height: 150 }}>
                {alldata?.age.map((item, index) => (
                  <label key={index}>
                    <span>{item.age}</span>
                    <span className="pst-re">
                      <input type="checkbox" value={item.age} onChange={handleAgeFilter} />
                      <span className="btn-checkbox"></span>
                    </span>
                  </label>
                ))}
              </Scrollbars>
            </Accordion>
          </div>
          <div className="widget widget-category boder-bt">
            <Accordion title="Price (in ETH)" show={false}>
              <label className="pricebox"><span>Minimum</span>
                <div className="textBoxWrap">
                  <input
                    type="number"
                    className="textbox"
                    placeholder=""
                    name="min"
                    maxLength="2"
                    // min="0" step="0.01"
                    onChange={handleMinPrice}
                    value={minPrice}
                  // value={form.min}
                  // onChange={(event) => {
                  //   const { value } = event.target;
                  //   setForm({
                  //     ...form,
                  //     min: normalizePrice(value, initialFormState.min)
                  //   });
                  // }}
                  />

                </div>
              </label>

              <label className="pricebox"><span>Maximum</span>
                <div className="textBoxWrap">
                  <input
                    type="number"
                    className="textbox"
                    placeholder=""
                    name="max"
                    maxLength="2"
                    // min="0" step="0.01"
                    onChange={handleMaxPrice}
                    value={maxPrice}

                  // onChange={(event) => {
                  //   const { value } = event.target;
                  //   setForm({
                  //     ...form,
                  //     max: normalizePrice(value, initialFormState.max)
                  //   });
                  // }}
                  />
                </div>
              </label>


              <label className="pricebox">
                {priceError ? <span className="priceError">{priceError}</span> : ''}
                <button className="smallBtn" onClick={() => submitPrice()}>Update</button>
                {/* <button  className="smallBtn">Update</button> */}
              </label>


              {/* <input type="s" value="Reset" /> */}








            </Accordion>
          </div>
          <div className="widget filter widget-category boder-bt">
            <Accordion title="Region" show={false}>
              <Scrollbars style={{ height: 200 }}>
                {alldata?.region.map((item, index) => (
                  <label key={index}>
                    <span>{item.region}</span>
                    <span className="pst-re">
                      <input type="checkbox" value={item.region} onChange={handleRegionFilter} />
                      <span className="btn-checkbox"></span>
                    </span>
                  </label>
                ))}
              </Scrollbars>
            </Accordion>
          </div>
          <div className="widget filter widget-category">
            <Accordion title="Cask" show={false}>
              <Scrollbars style={{ height: 200 }}>
                {alldata?.cask.map((item, index) => (
                  <label key={index}>
                    <span>{item.cask}</span>
                    <span className="pst-re">
                      <input type="checkbox" value={item.cask} onChange={handleChange} />
                      <span className="btn-checkbox"></span>
                    </span>
                  </label>
                ))}
              </Scrollbars>
            </Accordion>
          </div>

        </div>
      </Scrollbars>
    </div>
  );
};

export default SideBar;
