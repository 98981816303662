import { useReducer } from "react";

const reducer = (state, action) => {
  const userName = localStorage.getItem("userName");
  const isLoggedIn = localStorage.getItem("accessToken");
  switch (action.type) {
    case "LOGIN":
      return {
        isLoggedIn: true,
        userName: userName,
      };
    case "LOGOUT":
      return {
        isLoggedIn: false,
      };
    case "search":
      return {
        isLoggedIn: isLoggedIn ? true : false,
        userName: userName,
        searchQuery: action.state,
      };
    case "Timer":
        return {
          isLoggedIn: isLoggedIn ? true : false,
          userName: userName,
          relTime: action.state, 
          // serverTime: action.state, 
        };  

    case "TOASTER":
      return {
        isLoggedIn: isLoggedIn ? true : false,
        userName: userName,
        toastError: action.state,
      };
    case "USERNAME":
      return {
        userName: action.state,
      };
    case "empty":
      return {
        searchQuery: "",
      };  
    default:
      return { state };
  }
};
const loaderReducer = (state, action) => {
  switch (action.type) {
    case "LOADER":
      return {
        isLoading: action.state,
      };
    default:
      return { state };
  }
};


const UseGlobalState = () => {
  const [globalState, globalDispatch] = useReducer(reducer, {
    isLoggedIn: null,
    searchQuery: null, 
    relTime: null, 
    serverTime: null, 
    userName: "",
    toastError: {
      type: "",
      message: "",
    },
  });
  return { globalState, globalDispatch };
};

const UseGlobalLoaderState = () => {
  const [globalLoaderState, globalLoaderDispatch] = useReducer(loaderReducer, {
    isLoading: false,   
  });
  return { globalLoaderState, globalLoaderDispatch };
};

export { UseGlobalState, UseGlobalLoaderState };
