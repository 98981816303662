import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import { Accordion } from "react-bootstrap-accordion";
import axios from "../axiosinstance";
import LoadingOverlay from "react-loading-overlay";
import Context from "../store/context";
import facebook from "./../../src/assets/images/icon/ic_facebook.svg"
import instagram from "./../../src/assets/images/icon/ic_instagram.svg"
import linkdien from "./../../src/assets/images/icon/ic_linkdien.svg"
import telegram from "./../../src/assets/images/icon/ic_telegram.svg"
import youtube from "./../../src/assets/images/icon/ic_you_tube.svg"
import twitter from "./../../src/assets/images/icon/ic_twitter.svg"
import tikTok from "./../../src/assets/images/icon/IC_tik_tok.svg"

const MarketFaq = ({ alldata }) => {
     LoadingOverlay.propTypes = undefined
    const location = useLocation();
    let [releaseBottles, setReleaseBottles] = useState([]);
    const [releaseBottleDetail, setReleaseBottleDetails] = useState([]);
    const [bottleId, setBottleId] = useState();

    React.useEffect(() => {
        const [a, id] = location.search.split("?id=");
        setBottleId(id);
        getReleaseBottelsDetails(id);
    }, []);
    const getReleaseBottelsDetails = (id) => {
        axios
            .get(`api/v1/marketPlace/${id}`)
            .then((response) => {
                setReleaseBottleDetails(response.data.data);
               
            })
            .catch((error) => {
           
            });
    };

   
    return (
        <>
            <div className="flat-accordion2">
                <Accordion title="Description" show={true}>
                    <p>
                        This is a very limited release from Penderyn. Only 25 bottles from one of Penderyn’s rarest ex-bourbon single malt casks from 2002. This is the oldest Penderyn whisky ever released from its collection of the first few batches of casks that were laid.</p>

                    <p>The NFT route for this exceptional collector's release is an obvious choice, given Penderyn’s global brand recognition which allows equal access to everyone in a safe, secure and traceable manner.</p>

                </Accordion>
                <Accordion title="Properties" show={true}>
                    <p><strong>Region:</strong> {releaseBottleDetail?.product?.region} </p>
                    <p><strong>Age:  </strong>{releaseBottleDetail?.product?.age} </p>
                    <p><strong>Cask: </strong> {releaseBottleDetail?.product?.cask} </p>
                    <p><strong>Finish: </strong> {releaseBottleDetail?.product?.tastingNotesFinish} </p>
                    <p><strong>Taste: </strong> {releaseBottleDetail?.product?.tastingNotesTaste} </p>
                    <p><strong>Nose: </strong> {releaseBottleDetail?.product?.tastingNotesNose} </p>
                </Accordion>
                <Accordion title="About Penderyn" show={true}>
                    <p>Historically the whisky industry established itself in a few countries around the world, with Scotland being the heartland of single malt whisky production. Since the turn of the new millennium, there has been an explosion in the opening of single malt whisky distilleries the world over, leading to the creation of the World Whisky category (supplementing the existing Scotch, American, Irish & Japanese categories). One of the pioneers in this emerging whisky category is Penderyn Distillery, which started distilling in 2000 and is now recognised internationally for its award-winning Welsh Single Malt Whiskies, which are now exported from Wales to over 50 countries.</p>
                    <p>Alongside being one of the pioneers in the World Whisky category, they've also challenged the traditional art of distillation, through the use of an innovative single copper pot still, the Penderyn Faraday still, a process unlike any other in the single malt whisky industry.</p>
                    <p>The Penderyn Faraday still produces a light, fruity and exceptionally clean spirit, at an industry high strength of around 92% abv. This  along with the exceptional casks chosen for their whisky maturation, maximises the full flavour qualities in their whiskies.</p>

                    <div className="social-media">
                        <a href="https://www.instagram.com/penderyn.distillery/" rel="home" className="socialLogo" target="_blank">
                            <img src={instagram} alt="nft-gaming" />
                        </a>
                        <a href="https://twitter.com/PenderynWhisky" rel="home" className="socialLogo" target="_blank">
                            <img src={twitter} alt="nft-gaming" />
                        </a>
                        <a href="https://www.facebook.com/penderyn" target="_blank" rel="home" className="socialLogo" >
                            <img src={facebook} alt="nft-gaming" />
                        </a>

                        <a href="https://www.youtube.com/user/penderynwhisky" rel="home" className="socialLogo" target="_blank">
                            <img src={youtube} alt="nft-gaming" />
                        </a>
                        {/* <a href="https://www.tiktok.com/@_paiverse" rel="home" className="socialLogo" target="_blank">
                            <img src={tikTok} alt="nft-gaming" />
                        </a> */}


                    </div>
                </Accordion>
                {/* <Accordion title="Details" show={true}>
                    <p>Since 2016 Penderyn has won over 35 Goldenawards and numerous silvers and almost all this has been achieved with their no age statement spirits! What with their success, the distillery has decided to share a very limited and collectable 25 bottles from one of its rarest ex-bourbon single malt casks from 2002, making this the oldest Penderyn whisky ever released from its collection of the first few batches of casks that were laid.</p><p>
                        The NFT route for this exceptional collector’s release is an obvious choice, given Penderyn’s global brand recognition which allows equal access to everyone in a safe, secure and traceable manner.</p>
                </Accordion> */}


            </div>
        </>
    )
}

export default MarketFaq