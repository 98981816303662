import React from "react";
import SliderStyle3 from "../components/slider/SliderStyle3";
import heroSliderData from "../assets/fake-data/data-slider";
import MarketPlace from "../components/layouts/home-6/MarketPlace";
import Paiverse from "../components/layouts/home-6/Paiverse";
import HomeBrand from "../components/layouts/home-6/HomePageBrand";
import HomeActiveity from "../components/layouts/home-6/homeActiveity";
import FaqAccordion from "./FaqAccordion";
import PaiverseCommunity from "../components/layouts/home-6/PaiverseCommunity";
import HowtoWorks from "../components/layouts/home-6/HowtoWorks";
import HomeFaq from "../components/layouts/home-6/HomeFaq";
import HowtoWorkVideo from "../components/layouts/home-6/HowtoWorkVideo";
const Home06 = () => {
  return (
    <div className='home-6'>
      <SliderStyle3 data={heroSliderData} />
      {/* <MarketPlace /> */}
      <HowtoWorks /> 
      <HomeBrand />
      <Paiverse />
    
      {/* 
      <div className="themesflat-container">
        <HowtoWorkVideo />
      </div>*/}
      <HomeActiveity />
      <HomeFaq />
    </div>
  );
}

export default Home06;