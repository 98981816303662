import React, { useState, Fragment } from "react";
import video from '../../../assets/images/slider/penderyn loop.mp4'
const HowtoWorkVideo = () => {
    const Video = () => {
        return (
            <video autoPlay loop playsInline muted src={video} />
        );
    };

    return (
        <Fragment>
            <div className="howtoWorkVideo">
                <Video />
            </div>
        </Fragment>
    );
};

export default HowtoWorkVideo;
