import React from "react";
import ItemContent from "../components/layouts/home-8/ItemContent";
const Home08 = () => {
  return (
    <div className="home-8">
      <section className='flat-title-page inner'>
        <h1 className="heading text-white">Marketplace</h1>
      </section>
      <div className="darkGray">
        <div className="tf-section">
          <div className="themesflat-container">
            <ItemContent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home08;
