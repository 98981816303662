import React, { useState, Fragment, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "../../../axiosinstance";
import { ethers } from "ethers";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { connectMetamask, connectCoinbase, } from "../../../Helpers/contractMethods";
import { getContract } from "../../../Helpers/contract";
import LoadingOverlay from "react-loading-overlay";
import {s3Url} from "../../../store/baseUrl";
import Context from "../../../store/context";
const RedeemedEditions = () => {
    LoadingOverlay.propTypes = undefined
    let [burnData, setBurnData] = useState([]);
    let [skip, setSkip] = React.useState(0);
    let navigate = useNavigate();
    const location = useLocation();
    const { commonGlobalState } = useContext(Context);
    const { globalState, globalDispatch } = commonGlobalState;
    let [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);

    React.useEffect(() => {
        setLoading(true);
        burnData = [];
        skip = 0;
        setBurnData(burnData);
        setSkip(skip);
        getBurnData();
        return () => {
            burnData = [];
            skip = 0;
            setSkip(skip);
            setBurnData(burnData);
            setVisible(false);
        };
    }, [location.pathname]);

    React.useEffect(() => {
        setLoading(false);
        if (globalState.searchQuery || globalState.searchQuery == "") {
            burnData = [];
            skip = 0;
            setBurnData(burnData);
            setSkip(skip);
            //getBurnData();
        }

        return () => {
            setBurnData([]);
            setSkip(0);
            // setShowModal(false);
            setVisible(false);
        };
    }, [globalState.searchQuery]);

    const getBurnData = () => {
        const payload = {
            search: globalState.searchQuery ? globalState.searchQuery : "",
            skip: skip,
            limit: 9,
            isBurned: 1
        };
        axios
            .get(`/api/v1/user/owned`, {
                params: payload,
            })
            .then((response) => {
                let data = response.data.data.rows
                setBurnData(data);
                if (burnData.length > response.data.data.count) {
                    setVisible(true);
                } else {
                    setVisible(false);
                }
                setLoading(false);
            })
            .catch((error) => {
            });
    };

    const loadMore = () => {
        skip = skip + 9;
        setSkip(skip);
        getBurnData();
    };


    const ETHtoUSDPrice = (value) => {
        const ethPrice = localStorage.getItem("priceInUSD") || 0;
        const price = value * ethPrice;
        return price.toFixed(6);
    };

    const release = () => {
        return (
            <div className="airDropWrap">
                <div className="themesflat-container">
                    <div className="row">
                        {burnData.map((item, key) => (
                            <div key={key} className="col-xl-3 col-sm-6 col-12">
                                <div className="sc-card-product">
                                    <div className="card-media">
                                        <Link to={`/item-details?id=${item.id}?burned=1`}>
                                            <img
                                                src={`${s3Url}icons/${item.product.imageUrl}`}
                                                alt="Axies"
                                            />
                                        </Link>
                                    </div>
                                    <div className="card-title">
                                        <h3>
                                            <Link to={`/item-details?id=${item.id}?burned=1`}>
                                                {item.product.brandName} {item.product.title}
                                            </Link>
                                        </h3>
                                       
                                    </div>
                                    <p className="grayOut">Redeemed</p>
                                </div>
                            </div>

                        ))}
                    </div>
                    {visible && (
                        <div className="col-md-12 wrap-inner load-more text-center btn-auction item center">
                            <Link
                                to="#"
                                className="sc-button loadmore fl-button pri-3"
                                onClick={loadMore}
                            >
                                <span>Load More</span>
                            </Link>
                        </div>
                    )}

                    {burnData && !burnData?.length && !loading && (
                        <div className="noData">
                            There are no NFTs
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            <LoadingOverlay active={loading} spinner={true} text="Loading...">
                <div
                    className={`flat-tabs items release-item w-100 ${!burnData?.length && `release-height`
                        }`}
                >
                    <div className="option d-block">
                        {burnData.length > 0 && release()}
                    </div>
                    {burnData && !burnData?.length && !loading && (

                        <div className="noData">
                            No data.
                        </div>
                    )}
                </div>
            </LoadingOverlay>
        </Fragment>
    );
};

export default RedeemedEditions;
