import React from "react";
import {UseGlobalState , UseGlobalLoaderState} from "./useGlobalState";
import Context from "./context";

const GlobalStateProvide = ({ children }) => {
  return (
    <Context.Provider value={{ commonGlobalState: UseGlobalState(), loaderState: UseGlobalLoaderState() }}> {children}</Context.Provider>
  );
};

export default GlobalStateProvide;
