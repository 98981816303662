const PageNoteFound = () => {
  return (
    <div className="page-height1">

      <section className='flat-title-page inner'>
        <h1 className="heading text-white">Not Found</h1>
        {/* <p>Take a look at some unique and rare collectible assets available through our platform.</p> */}
      </section>
      <section className="darkGray releases">
      
          <div className=" themesflat-container">
            <div className="noData">
              No result found
           
          </div>
        </div>
      </section>
    </div>
  );
};

export default PageNoteFound;
