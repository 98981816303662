import React, { useState, Fragment, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "../../../axiosinstance";
import { ethers } from "ethers";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { connectMetamask, connectCoinbase, } from "../../../Helpers/contractMethods";
import { getContract } from "../../../Helpers/contract";
import LoadingOverlay from "react-loading-overlay";
import Context from "../../../store/context";
import * as moment from 'moment'
const MyOfferListing = () => {
  LoadingOverlay.propTypes = undefined
  const [myOfferTab] = useState([
    {
      id: 1,
      title: "Offers on my listings",
    },
    {
      id: 2,
      title: "Offers I made",
    },
  ]);
  let [myoffers, setMyoffers] = useState([]);
  let [myBid, setMyBid] = useState([]);
  let [skip, setSkip] = React.useState(0);
  let navigate = useNavigate();
  const location = useLocation();
  const { commonGlobalState } = useContext(Context);
  const { globalState, globalDispatch } = commonGlobalState;
  let [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  React.useEffect(() => {
    // setLoading(true);
    getMyOffers();
    offerImade();

  }, []);


  const getMyOffers = () => {
    const payload = {
      search: globalState.searchQuery ? globalState.searchQuery : "",
      // skip: skip,
      // limit: 10, 
    };
    axios
      .get(`api/v1/bid/recivedBids`)
      .then((response) => {
        setLoading(false);
        let data = response.data.data
        // myoffers = [response.data.data];
        setMyoffers(data);
        // if (myoffers.length < response.data.data.count) {
        //   setVisible(true);
        // } else {
        //   setVisible(false);
        // }
        setLoading(false);
      })
      .catch((error) => {
      });
  };

  const loadMore = () => {
    skip = skip + 9;
    setSkip(skip);
    getMyOffers();
  };


  const ETHtoUSDPrice = (value) => {
    const ethPrice = localStorage.getItem("priceInUSD") || 0;
    const price = value * ethPrice;
    return price.toFixed(6);
  };
  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }


  const offerImade = () => {
    const payload = {
      // search: globalState.searchQuery ? globalState.searchQuery : "",
      // skip: skip,
      // limit: 10, 
    };
    axios
      .get(`api/v1/bid/myBids`)
      .then((response) => {
        let data = response.data.data
        setMyBid(data);
        setLoading(false);
      })
      .catch((error) => {
      });
  };

  const acceptBid = async (item) => {
    setLoading(true);
    const payload = {
      bidId: item.id
    };
    axios
      .put(`/api/v1/bid`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          bidTransfer(item, response.data?.data?.transaction.id)
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  const bidTransfer = async (item, transactionID) => {
    const res = await connectMetamask();
    const myContract = getContract(res.library, res.account);

    try {
      const tokentTransfer = await myContract['safeTransferFrom(address,address,uint256)'](res.account.toString(), item.fromAddress.toString(), item?.productItem.blockChainNFTId)

      if (tokentTransfer) {
        bidDone(item.id, transactionID, tokentTransfer)


      }
    } catch (ex) {
      const error = JSON.parse(JSON.stringify(ex));
      setLoading(false);
    }
  }

  const bidDone = async (id, transactionID, data) => {
    setLoading(true);
    const payload = {
      bidId: id,
      transactionId: transactionID,
      tokenTransferHash: data.hash
    };
    axios
      .post(`/api/v1/bid/transferToken`, payload)
      .then((response) => {
        if (response.data.statusCode == 200) {
          setLoading(false);
          navigate(`/marketplace`);
          const toasterError = {
            type: "success",
            message: "Offer has been accepted.",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          getMyOffers();
          offerImade();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  const cancelBid = async (item) => {
    setLoading(true);
    axios
      .delete(`/api/v1/bid`, { data: { 'bidId': item.id } })
      .then((response) => {
        if (response.data.statusCode == 200) {
          setLoading(false);
          const toasterError = {
            type: "success",
            message: "Bid Cancelled.",
          };
          globalDispatch({ type: "TOASTER", state: toasterError });
          // getReleaseBottelsDetails(bottleId);
          // getOfferDetails(bottleId);

        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }


  return (
    <Fragment>
      <LoadingOverlay active={loading} spinner={true} text="Loading...">
        <div className="airDropWrap">

          <div className="themesflat-container">
            <Tabs className="myCollectionTab">
              <TabList>
                {myOfferTab.map((data) => (
                  <Tab key={data.id}>{data.title}</Tab>
                ))}
              </TabList>
              <TabPanel>
                <div className="mycollactionHTML collection">
                  <div className="tableWrapper">
                    <table>
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Owner</th>
                          <th>Price</th>
                          <th>Expiration</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {myoffers.map((item, key) => (
                          <tr key={key}>
                            <td>
                              {capitalizeFirstLetter(item?.productItem?.product.title)}
                            </td>
                            <td>
                              {capitalizeFirstLetter(item?.productBidUser.userName)}
                            </td>
                            <td>
                              {item.amount} ETH
                            </td>
                            <td>
                              {moment(item?.expiredAt).format('YYYY-MM-DD')}
                            </td>
                            <td>
                              <button className="offerBtn" onClick={() => acceptBid(item)}>Make an offer</button>

                            </td>
                          </tr>
                        ))}

                      </tbody>
                    </table>
                    {!myoffers?.length && !loading && (

                      <div className="noData">
                        No data.
                      </div>
                    )}
                  </div>

                </div>
              </TabPanel>
              <TabPanel>
                <div className="mycollactionHTML">
                  <div className="tableWrapper">
                    <table>
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Owner</th>
                          <th>Price</th>
                          <th>Expiration</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {myBid.map((item, key) => (
                          <tr key={key}>
                            <td>
                              {capitalizeFirstLetter(item?.productItem?.product?.title)}
                            </td>
                            <td>
                              {capitalizeFirstLetter(item?.productBidUser.userName)}
                            </td>
                            <td>
                              {item.amount} ETH
                            </td>
                            <td>
                              {moment(item?.expiredAt).format('YYYY-MM-DD')}
                            </td>
                            <td>
                              <button className="cancelBtn" onClick={() => cancelBid(item)}>Cancel</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {!myoffers?.length && !loading && (
                      <div className="noData">
                        No data.
                      </div>
                    )}
                  </div>
                </div>
              </TabPanel>
            </Tabs>

          </div>
        </div>
      </LoadingOverlay>
    </Fragment>
  );
};

export default MyOfferListing;
