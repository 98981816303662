import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo/img_logo.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import CardModal from "../CardModal";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";



const HomeBrand = () => {
    const [data] = useState([
        {
            title: "Hamlet Contemplates ...",
            tags: "bsc",

            nameAuthor: "SalvadorDali",
            price: "4.89 ETH",
            wishlist: "100",
        },

    ]);

    const [modalShow, setModalShow] = useState(false);

    return (
        <Fragment>
            <section className="tf-section homeBrand">
                <div className="themesflat-container">
                <h2 className="tf-title pb-24 text-center">Brands</h2>
                    <div className="row">

                        <div className="col-md-12">
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={30}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    767: {
                                        slidesPerView: 1,
                                    },
                                    991: {
                                        slidesPerView: 1,
                                    },
                                }}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                            >
                                {data.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="swiper-container show-shadow carousel auctions">
                                            <div className="swiper-wrapper">
                                                <div className="swiper-slide">
                                                    <Link to="/brands/15" className="slider-item">
                                                       <img src={logo}/>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <CardModal show={modalShow} onHide={() => setModalShow(false)} />
        </Fragment>
    );
};

export default HomeBrand;
