import React from 'react';
import icon1 from "./../../../assets/images/icon/Ic_01.svg"
import icon2 from "./../../../assets/images/icon/ic_02.svg"
import icon3 from "./../../../assets/images/icon/ic_03.svg"
import icon4 from "./../../../assets/images/icon/ic_04.svg"
import icon5 from "./../../../assets/images/icon/ic_05.svg"
import icon6 from "./../../../assets/images/icon/Ic_06.svg"
import icon7 from "./../../../assets/images/icon/Ic_07.svg"
const HowtoWorks = () => {
    return (
        <>
            <div className='HowtoWorks tf-section'>
                <div className="themesflat-container">
                    <h2 className="tf-title pb-24 text-center">How to get a Paiverse NFT</h2>
                    <div className='row justify-content-md-center'>
                        <div className='col-12 col-sm-6 col-lg-3'>
                            <div className='contentBox'>
                                <span className='count'><img src={icon1} /></span>
                                <h6> Sign up </h6>
                                <p>Click sign up. Super simple. Takes less than 60 seconds.</p>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-3'>
                            <div className='contentBox'>
                                <span className='count'><img src={icon2} /></span>
                                <h6>Get a Wallet </h6>
                                <p>Get a wallet. You can use MetaMask or Coinbase on our platform. We suggest MetaMask. <br/> <a href="https://www.youtube.com/watch?v=U0GoMFUnZrk&t=369s" target="_blank"> Watch the video  </a></p>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-3'>
                            <div className='contentBox'>
                                <span className='count'><img src={icon3} /></span>
                                <h6>Connect your wallet </h6>
                                <p>Once you have signed up and signed in click connect your wallet.</p>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-3'>
                            <div className='contentBox'>
                                <span className='count'><img src={icon4} /></span>
                                <h6>Buy Ether (ETH)</h6>
                                <p>MetaMask has the feature to buy ETH, using fiat currency (there are several options).</p>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-lg-3'>
                            <div className='contentBox'>
                                <span className='count'><img src={icon5} /></span>
                                <h6>Getting comfortable </h6>
                                <p>We advise you purchase a small amount of ETH ($10) right away, using MetaMask. This will get you comfortable with the process. We advise using Wyre but others are available. <br/>  <a href="https://www.youtube.com/watch?v=zvU-529VaMU" target="_blank">Watch the video </a></p>
                            </div>
                        </div>

                        <div className='col-12 col-sm-6 col-lg-3'>
                            <div className='contentBox'>
                                <span className='count'><img src={icon6} /></span>
                                <h6>Loading your wallet  </h6>
                                <p>4-7 days before launch buy necessary ETH needed for the drop. Buy a little more ETH than the website price, to cover network fees and potential moves in the ETH derived price (Fixed US$2000 but price will change in ETH terms) <br/>  <a href="https://www.youtube.com/shorts/ZNBTMEesCEs" target="_blank">Watch the video </a></p>
                            </div>
                        </div>

                        <div className='col-12 col-sm-6 col-lg-3'>
                            <div className='contentBox'>
                                <span className='count'><img src={icon7} /></span>
                                <h6>Mint   </h6>
                                <p>On the day (Either 12th Dec: White listed or 15th Dec). Login to your Paiverse account, connect your wallet and mint your Penderyn NFT<br/><a href="https://www.youtube.com/watch?v=xb2OEaSazTk" target="_blank">Watch the video </a></p>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HowtoWorks