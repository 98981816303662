import React from 'react'
import { Link } from 'react-router-dom';
import PaiverseCommunity from "../components/layouts/home-6/PaiverseCommunity";

const PrivacyPolicy = () => {
    return (
        <>
            <section className='flat-title-page inner'>
                <h1 className="heading text-white">Privacy Policy </h1>
                <p>Last Updated: September 2022</p>
            </section>
            <section className='paiverseTeam  text-left darkGray '>
                <div className="themesflat-container  tf-section">
                    <div className='termsWrap'>
                        <p>Paiverse is a platform available at <Link to={'https://Paiverse.io'} target="_blank" className="blueText"> https://Paiverse.io </Link> (together with its related websites and social media pages “Website”) that provides users with the opportunity to purchase, collect, and showcase digital
                            blockchain collectibles containing exclusive rights to redeem a physical object (collectively, the “Platform”). The Pl tform is proprietary to Paladin Digital Assets, Inc. , Inc. (referred to in this privacy policy as “Paiverse”, “Paladin” “we”, “us”, “platform” or “our”).</p>

                        <p> Paiverse is committed to protecting and respecting your privacy. This privacy policy (this “Policy”) sets out how we collect and process personal information about you in connection with our services, including when you visit our Website, when you sign up for or use the App, or when you otherwise do business or make contact with us (collectively, the “Services”). </p>

                        <p> What information do we collect? </p>
                        <p>A. SUMMARY <br /> We collect data to enable us to perform our Services effectively, and to provide you with the best
                            experience on the Platform. You provide some of this data to us directly, such as when you register to use the Platform, subscribe to our marketing materials, respond to a survey, make an inquiry through our Website, contact us for support, or contact us as a prospective user, vendor, supplier, or consultant. We get some of your data by recording how you interact with the Platform by, for example, using technologies like cookies. We also obtain and process data in the context of making the Platform available to you. </p>

                        <p> B. LEARN MORE <br /> You have choices about the data we collect. When you are asked to provide personal information, you may decline. But if you choose not to provide data that is necessary to enable us to make the Platform available to you or otherwise provide our Services (in a manner that may include personal information), you may not be able to sign up for or use the Platform. The data we collect depends on the context of your interactions with PAIVERSE, and the choices you make (including your privacy settings). The data we collect can include the following: </p>

                        <p> Information You Give Us. <br /> Personal information we may collect from you includes: <br /> contact information, such as first name, last name, phone number, email address and address; login credentials, such as username and password; wallet address; date of birth; personal information included in any proof of financials or government identification documents requested; usage information, such as information about how you use the service and interact with us; any other information you voluntarily submit to us, e.g. during a support call, correspondence, survey or testimonial. [This may include information about other people; to the extent you provide us with information about other people, you agree that you have obtained their consent to do so]; information that is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with you, your household, or your device; and information that may be requested by our partners, in order for you to redeem our NFTs and/or redeem experiences related to your Paiverse NFT. </p>

                        <p> <strong>Information Automatically Collected: </strong> </p>
                        <p> We may collect data about your device and how you and your device interact with our Platform. For example, we may collect your interactions on our Website, your feature usage patterns, location data, and your interactions with us. We may also collect data about your device and the network you use to connect to our Platform; this may include data such as your IP address, browser type, operating system, and referring URLs. </p>


                        <p> <strong> Information We Will Never Collect: </strong> <br /> We will never ask you to share your private keys, passwords or wallet seed phrase. Never trust anyone or any site that asks you to share your private keys, passwords or wallet seed. </p>

                        <p><strong> What do we use your information for?</strong> </p>
                        <p>
                            A. SUMMARY <br />
                            We use the data we collect to operate our business and to make the Platform available to you. This includes using the data to improve our Platform and to personalize your experiences. We may also use the data to communicate with you to,
                            among other things, inform you about your account, provide security updates, and give you information about the Platform. We may also use the data to manage your subscriptions with us, improve the relevance and security of our Website,
                            respond to user enquiries, and send you periodic marketing communications about our Platform. We may also use the data to provide to our partners, in order for you to redeem or unlock experiences about your Paiverse NFTs, although we
                            will not provide this information without your prior consent.
                        </p>

                        <p>
                            B. LEARN MORE <br />
                            We use data to provide the Platform to you, to improve the Platform, and to perform essential business operations and the Services we provide to you. This includes operating the Platform, maintaining and improving the performance of the
                            Platform, developing new features, conducting research, providing customer support, and to enforce our Terms of Service. Examples of such uses include the following:
                        </p>

                        <p>
                            Providing the Platform and Services <br />
                            To carry out your transactions with us and to make the Platform available to you. In certain cases, the Platform may include personalized features and recommendations to enhance your enjoyment, and automatically tailor your experience
                            based on the data we have about you.
                        </p>

                        <p>
                            Technical support <br />
                            To diagnose problems, and to provide customer care and other support services. Improving the Platform. To continually improve the Platform, including system administration, system security (including fraud or bot detection), and adding
                            new features or capabilities.
                        </p>

                        <p>
                            Business Operations <br />
                            To develop aggregate analyses and business intelligence that enable us to operate, protect, make informed decisions, and report on the performance of our business.
                        </p>

                        <p>
                            Promotions <br />
                            To administer, through us and our partners, contests, promotions, experiences, surveys, or other site features.
                        </p>

                        <p>
                            Improving Advertising Campaigns <br />
                            To improve our advertising campaigns, primarily in an effort to prevent targeting you with advertisements that are not relevant to you.
                        </p>

                        <p>
                            Sending Periodic Emails <br />
                            To send you periodic emails. Depending on the marketing preferences you select on your privacy dashboard, we may send you occasional marketing emails about our Platform, which you can unsubscribe from at any time using the link provided
                            in the message.
                        </p>

                        <p>
                            Communications <br />
                            To communicate with you, and to personalize our communications with you. For example, we may contact you to discuss your account, to remind you about features of the Platform that are available for your use, to update you about a
                            support request, or to invite you to participate in a survey.
                        </p>

                        <p>
                            Security and compliance with laws <br />
                            As we believe necessary or appropriate to: operate and maintain the security or integrity of our Platform and PAIVERSE property, including to prevent or stop an attack on our computer systems or networks or investigating possible
                            wrongdoing in connection with the Platform; protect the rights, interests or property of PAIVERSE or third parties; enforce our Terms of Service; and comply with applicable laws, lawful requests and legal process, such as to respond to
                            subpoenas or requests from government or regulatory authorities.
                        </p>

                        <p>
                            Business <br />
                            When we do a business deal, or negotiate a business deal together with our advisors, involving the sale or transfer of all or a part of our business or assets. These deals can include any merger, financing, acquisition, reorganization
                            or bankruptcy transaction or proceeding.
                        </p>

                        <p>
                            Consent <br />
                            Purposes for which we have obtained your consent, as required by applicable laws.
                        </p>

                        <p>
                            <strong> How do we protect your information? </strong> <br />
                            We implement a variety of appropriate technical and organizational security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. For instance, we offer the use of a
                            secure server. All supplied sensitive information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our gateway providers database only to be accessible by those authorized with special access rights to
                            such systems, and are required to keep the information confidential.
                        </p>

                        <p>How do we ensure that our processing systems remain confidential, resilient, and available?</p>

                        <p>
                            A. SUMMARY <br />
                            We implement a variety of measures to ensure that our processing systems remain confidential, resilient, and available. Specifically, we have implemented processes to help ensure high availability, business continuity, and prompt
                            disaster recovery. We commit to maintaining strong physical and logical access controls, and conduct regular penetration testing to identify and address potential vulnerabilities.
                        </p>

                        <p>
                            B. LEARN MORE <br />
                            High Availability. Every part of the Platform utilizes properly-provisioned, redundant servers (e.g., multiple load balancers, web servers, replica databases) in case of failure. We take servers out of operation as part of regular
                            maintenance, without impacting availability.
                        </p>

                        <p>
                            Business Continuity <br />
                            We keep encrypted backups of data daily in multiple regions on Amazon Web Services. While never expected, in the case of production data loss (i.e., primary data stores loss), we will restore organizational data from these backups.
                        </p>

                        <p>
                            Disaster Recovery <br />
                            In the event of a region-wide outage, we will bring up a duplicate environment in a different Amazon Web Services region. Our operations team has extensive experience performing full region migrations.
                        </p>

                        <p>
                            Physical Access Controls <br />
                            The Platform is hosted on Amazon Web Services, whose data centers feature a layered security model, including extensive safeguards such as custom-designed electronic access cards, alarms, vehicle access barriers, perimeter fencing,
                            metal detectors, and biometrics. PAIVERSE employees do not have physical access to these data centers, servers, network equipment, or storage.
                        </p>

                        <p>
                            Logical Access Controls <br />
                            Only designated, authorized operations team members have access to configure the infrastructure on an as-needed basis behind a two-factor authenticated virtual private network. Specific private keys are required for individual servers,
                            and keys are stored in a secure and encrypted location.
                        </p>

                        <p>
                            <strong> Do we use cookies? </strong> A. SUMMARY <br />
                            Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture
                            and remember certain information. You can choose to disable cookies, but if you do, your ability to use or access certain parts of the Platform may be affected.
                        </p>

                        <p>
                            B. LEARN MORE <br />
                            We use cookies and other similar identifiers to understand and save your preferences for future visits, to advertise to you on other sites, and to compile aggregate data about site traffic and site interaction so that we can offer
                            better site experiences and tools in the future
                        </p>

                        <p>
                            You may refuse to accept cookies by activating the setting on your browser that allows you to refuse the setting of cookies. You can find information on popular browsers and how to adjust your cookie preferences at the following
                            websites:
                        </p>

                        <p>
                            Microsoft Internet Explorer <br />
                            Mozilla Firefox <br />
                            Google Chrome
                        </p>

                        <p>
                            Apple Safari <br />
                            However, if you choose to disable cookies, you may be unable to access certain parts of our Website. A banner asking you to accept our cookies policy will be displayed upon the first visit to our Website (or the first visit after you
                            delete your cookies). Unless you have adjusted your browser setting so that it will refuse cookies and/or you have not accepted our cookies policy, our system will issue cookies when you log on to our Website.
                        </p>

                        <p>
                            Our web pages may contain electronic images known as web beacons (also called single-pixel gifs) that we use to help deliver cookies on our Websites and to count users who have visited those Websites. We may also include web beacons in
                            our promotional email messages or newsletters, to determine whether and when you open and act on them.
                        </p>

                        <p>
                            In addition to placing web beacons on our own Websites, we sometimes work with other companies to place our web beacons on their websites or in their advertisements. This helps us to develop statistics on how often clicking on an
                            advertisement on a PAIVERSE Website results in a purchase or other action on the advertiser's website.
                        </p>

                        <p>
                            Finally, our Platform may contain web beacons or similar technologies from third-party analytics providers that help us compile aggregated statistics about the effectiveness of our promotional campaigns or other operations. These
                            technologies enable the analytics providers to set or read their own cookies or other identifiers on your device, through which they can collect information about your online activities across applications, websites or other products.
                        </p>

                        <table className="tableWrap">
                            <tr>
                                <th>Name of Cookie/Identifier</th>
                                <th>Purpose</th>
                                <th>1st/3rd Party</th>
                                <th>Persistent or Session Duration/Retention</th>
                            </tr>
                            <tr>
                                <td>Yandex Metrica</td>
                                <td>Optimisation and marketing</td>
                                <td>3rd Party (Yandex LLC)</td>
                                <td>Session 30 minutes</td>
                            </tr>
                            <tr>
                                <td>Meta Pixel</td>
                                <td>Optimisation and marketing</td>
                                <td>3rd Party (Meta Platforms, Inc.)</td>
                                <td>Session 3 months</td>
                            </tr>
                            <tr>
                                <td>Google Analytics</td>
                                <td>Optimisation and marketing</td>
                                <td>3rd Party (Google LLC)</td>
                                <td>Session 3 months</td>
                            </tr>
                            <tr>
                                <td>Google AdSense</td>
                                <td>Optimisation and marketing</td>
                                <td>3rd Party (Google LLC)</td>
                                <td>Session 3 months</td>
                            </tr>
                        </table>

                        <p>
                            <strong> Do we disclose any information to outside parties? </strong> <br />
                            A. SUMMARY <br />
                            We share your personal information with your consent, or as necessary to make the Platform available to you. We also share your data with our brand partners, vendors working on our behalf, when required by law or to respond to legal
                            process, to protect our customers, to maintain the security and integrity of our Platform, and to protect our rights or our property; for the purposes of our legitimate interests.
                        </p>

                        <p>
                            B. LEARN MORE <br />
                            We share your personal information with your consent, or as necessary to make the Platform available to you. We share your data with our brand partners, since they are some of the major content providers for the Platform.
                        </p>

                        <p> We also share personal information with vendors, advisors, agents working on our behalf or other third
                            parties for the purposes described in this Policy. For example, companies we have hired to provide cloud
                            hosting services, off-site backups, and customer support may need access to personal information to
                            provide those functions. In such cases, these companies are required to abide by our data privacy and
                            security requirements and are not allowed to use personal information they receive from us for any
                            other purpose. If you have questions or concerns about any of our vendors, feel free to contact us at <Link to={'mailto:contactus@paladininvesting.com'} target="_blank" className="blueText"> contactus@paladininvesting.com </Link> </p>

                        <p>We share personal information including users' digital wallet address with Fireblocks Inc. as necessary for
                            the performance of our contract with you, i.e. to create a digital wallet for you when you purchase goods
                            from us without providing an existing digital wallet address. You can find their privacy policy here:
                            <Link to={'https://www.fireblocks.com/privacy-policy/'} target="_blank" className="blueText"> https://www.fireblocks.com/privacy-policy/ </Link>.</p>


                        <p> We share personal information including personal wallet address with Chainalysis Inc. for our legitimate
                            interests in carrying out compliance checks on wallet addresses. You can find their privacy policy here:
                            <Link to={'https://www.chainalysis.com/privacy-policy/'} target="_blank" className="blueText"> https://www.chainalysis.com/privacy-policy/ </Link></p>

                        <p> We use and share aggregated non-personal information with third parties for marketing, advertising, and
                            analytics purposes. We also use Yandex Metrica to help us offer you an optimized user experience. You
                            can find more information about Yandex Metrica's use of your personal information here:
                            <Link to={'https://metrica.yandex.com/about/info/privacy-policy '} target="_blank" className="blueText"> https://metrica.yandex.com/about/info/privacy-policy </Link>. </p>

                        <p> <strong> We do not sell or trade your personal information to third parties. </strong> <br /> How to Access and Control Your Personal Information <br /> A. SUMMARY You can view, access, edit, delete, or request a copy of your personal information for many
                            aspects of the Platform via your user dashboard. You can also make choices about PAIVERSE's collection
                            and use of your data. You can always choose whether you want to receive marketing communications
                            from us. You can also opt out from receiving marketing communications from us by using the opt-out link
                            on the communication, or by visiting your user dashboard. </p>

                        <p> B. LEARN MORE <br />
                            Data Access. <br />
                            You can access your personal information on your account's user dashboard. <br />
                            Data Correction. <br />
                            You can modify your personal information via your account's user dashboard. Note that since some of
                            the data we collect is specific to you - for example, your electronic wallet address - you may not be able
                            to modify this data without needing to create a new user profile.
                        </p>

                        <p>
                            Third Party Links <br />
                            Occasionally, at our discretion, we may include or offer third party products or services on our Website
                            or through our Platform. If you access other websites using the links provided, the operators of these
                            websites may collect information from you that will be used by them in accordance with their privacy
                            policies. These third party sites have separate and independent privacy policies. We, therefore, have no
                            responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to
                            protect the integrity of our site and welcome any feedback about these sites.
                        </p>

                        <p> Where we Store and Process Personal Information; International Transfers <br />
                            Personal information collected by PAIVERSE may be transferred to, stored and processed in the United
                            States or in any other country where PAIVERSE or its affiliates, subsidiaries or service providers maintain
                            facilities. The storage location(s) are chosen in order to operate efficiently, to improve performance, and
                            to create redundancies in order to protect the data in the event of an outage or other problem. We take
                            steps to ensure that the data we collect is processed according to the provisions of this Policy and the
                            requirements of applicable law wherever the data is located, including the transfer of data in accordance
                            with a valid data transfer mechanism.
                        </p>

                        <p>
                            Data Retention <br />
                            A. SUMMARY <br />
                            We may retain your personal information as long as you continue to use the Platform, have an account
                            with us, or for as long as is otherwise necessary to fulfil the purposes outlined in this Policy. You can ask
                            to close your account by contacting us as described above, and we will delete your personal information
                            on request. We may, however, retain personal information for an additional period as is permitted or
                            required under applicable laws, for legal, tax, or regulatory reasons, or for legitimate and lawful business
                            purposes.
                        </p>

                        <p> B. LEARN MORE <br />
                            We will retain your personal information for as long as necessary to make the Platform available to you,
                            or for other essential purposes such as complying with our legal obligations, resolving disputes, and
                            enforcing our agreements. Because these needs can vary for different types of data, actual retention
                            periods can vary significantly. The criteria we use to determine the retention periods include:
                        </p>

                        <p>
                            How long is the personal information needed to make the Platform available to you and/or operate our
                            business? This includes such things such as maintaining and improving the performance of the Platform,
                            keeping our systems secure, and maintaining appropriate business and financial records. This is the
                            general rule that establishes the baseline for most data retention periods. Is there an automated control,
                            such as in your user dashboard, that enables you to access and delete the personal information at any
                            time? If there is not, a shortened data retention time will generally be adopted.
                        </p>

                        <p> Is the personal information of a sensitive type? If so, a shortened retention time would generally beappropriate. </p>
                        <p> Is PAIVERSE subject to a legal, contractual, or similar obligation to retain the data? Examples can include mandatory data retention laws in the applicable jurisdiction, government orders to preserve data relevant to an investigation, or data that must be retained for the purposes of litigation. </p>

                        <p> Minors <br /> The Platform is intended for users age 21 and older. We do not knowingly collect information from
                            children. If we discover that we have inadvertently collected information from anyone younger than the age of 18, we will delete that information. If you believe we might have any information from or about a child under the age of 18, please contact us at contactus@paladininvesting.com. </p>

                        <p> California Resident Rights If you are a resident of the state of California, you have the rights set forth in this section, in compliance with the California Consumer Privacy Act of 2018 (“CCPA”) and any terms defined in the CCPA have the same meaning when used herein. Please see the “Exercising Your Rights” section below for instructions
                            regarding how to exercise these rights. If there are any conflicts between this section and any other
                            provision of this Privacy Policy and you are a California resident, the portion that is more protective of
                            your personally-identifiable data will control to the extent of such conflict. This section does not apply to
                            workforce-related personal information collected from California-based employees, job applicants,
                            contractors, or similar individuals, which is set forth in other sections of this Privacy Policy. </p>

                        <p> We collect information personal information as set forth in this Privacy Policy. We may share your personal information by disclosing it to a third party for a business purpose, as set forth in this Privacy Policy. </p>

                        <p> The following chart demonstrates how we have collected in particular, we have collected the following categories of personal information from consumers within the last twelve (12) months: </p>

                        <p> Category Examples Collected Business Purpose Disclosures </p>
                        <p> A. Identifiers <br />
                            A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address,
                            email address, account name.Social Security number, driver's license number, passport number, or other
                            similar identifiers might also be asked for upon redeeming your NFT. We only use these for KYC to
                            ensure right individuals get access to their underlying asset. We purge any such info obtained within 72
                            hours of receiving. </p>

                        <p> Yes Operating systems and platforms <br /> B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code §
                            1798.80(e)) <br /> A name, signature, Social Security number, physical characteristics or description, address, telephone
                            number, passport number, driver's license or state identification card number, insurance policy number,
                            education, employment, employment history, bank account number. Yes Operating systems and
                            platforms
                        </p>

                        <p> C. Protected classification characteristics under California or federal law <br /> Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or
                            military status, genetic information (including familial genetic information). No</p>


                        <p> D. Commercial information <br />
                            Records of personal property, products or services purchased, obtained, or considered, or other
                            purchasing or consuming histories or tendencies. Yes Operating systems and platforms</p>

                        <p> E. Biometric information <br />
                            Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a
                            template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints,
                            iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data. No
                            None
                        </p>

                        <p> F. Internet or other similar network activity <br />
                            Browsing history, search history, information on a consumer's interaction with a website, application, or
                            advertisement. Yes Data analytics providers </p>

                        <p> G. Geolocation data <br /> Physical location or movements.No None </p>

                        <p> H. Sensory data <br />
                            Audio, electronic, visual, thermal, olfactory, or similar information. No None <br />
                            I. Professional or employment-related information <br />
                            Current or past job history or performance evaluations. Yes Operating systems and platforms <br />
                            J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section
                            1232g, 34 C.F.R. Part 99))

                        </p>

                        <p> Education records directly related to a student maintained by an educational institution or party acting
                            on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes,
                            student financial information, or student disciplinary records. No None </p>

                        <p> K. Inferences drawn from other personal information <br />
                            Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior,
                            attitudes, intelligence, abilities, and aptitudes. No None </p>

                        <p> We obtain the categories of personal information listed above from the following categories of sources: <br />
                            Directly from you, as described in this Privacy Policy. <br />
                            Indirectly from you, as described in this Privacy Policy </p>

                        <p> We may use or disclose the personal information we collect for one or more of the purposes set forth in
                            this Privacy Policy. We will not collect additional categories of personal information or use the personal
                            information we collected for materially different, unrelated, or incompatible purposes without providing
                            you notice.
                        </p>

                        <p> We do not sell personal information. Our personal information sales do not include information about
                            individuals we know are under age 18. In the preceding twelve (12) months, Company has not sold
                            personal information to the categories of third parties indicated in the chart above. </p>

                        <p> Access
                            You have the right to request certain information about our collection and use of your personal
                            information over the past 12 months. In response to a Valid Request (as detailed below), we will provide
                            you with the following information:
                        </p>

                        <p> The categories of personal information that you requested and that we have collected about you. <br />
                            The categories of sources from which that personal information was collected. <br />
                            The business or commercial purpose for collecting your personal information. <br />
                            The categories of third parties with whom we have shared your personal information. <br />
                            The specific pieces of personal information that you explicitly requested and that we have collected <br />
                            about you.
                        </p>

                        <p> If we have disclosed your personal information to any third parties for a business purpose over the past
                            twelve (12) months, we will identify the categories of personal information shared with each category of
                            third party recipient. </p>

                        <p> Deletion <br />
                            You have the right to request that we delete the personal information that we have collected about you.
                            Under the CCPA, this right is subject to certain exceptions: for example, we may need to retain your
                            personal information to provide you with access to the Platform or complete a transaction or other
                            action you have requested. If your deletion request is subject to one of these exceptions, we may deny
                            your deletion request.
                        </p>

                        <p>We Do Not Sell Your Personal Information <br />
                            In this section, we use the term ‘sell’ as it is defined in the CCPA. We do not sell your personal
                            information. <br />
                            We Will Not Discriminate Against You for Exercising Your Rights Under the CCPA </p>


                        <p> We will not discriminate against you for exercising your rights under the CCPA. We will not deny you our
                            goods or services, charge you different prices or rates, or provide you a lower quality of goods and
                            services if you exercise your rights under the CCPA. However, we may offer different tiers of our products
                            or services as allowed by applicable data privacy laws (including the CCPA) with varying prices, rates or
                            levels of quality of the goods or services you receive related to the value of personal information that we
                            receive from you.
                        </p>



                        <p> California Shine the Light Law <br />
                            California's "Shine the Light" law (Civil Code Section § 1798.83) permits users of our Platform that are
                            California residents to request certain information regarding our disclosure of personal information to
                            third parties for their direct marketing purposes. To make such a request, please send an email to
                            contact@Paiverse.com or write us at the mailing address below. </p>


                        <p> European Economic Area (“EEA”), U.K., or Switzerland Resident Rights<br />
                            Legal Bases for Processing of Personal Data <br />
                            With respect to EEA, U.K. and Swiss data subjects, “personal information,” as used in this Policy, is
                            equivalent to “personal data,” as that term is defined in the EU General Data Protection Regulation, for
                            the purposes described in “What do we use your information for?” above. For each purpose of
                            processing (see table below), we may use one or more of the following legal bases: (1) specific and
                            informed consent; (2) necessary for the performance of our agreement to provide Services; (3) to fulfil a
                            legal obligation or (4) as necessary for our legitimate interests in providing the Services, so long as those
                            interests do not override your fundamental rights and freedoms related to data privacy (unless we have
                            your consent or are otherwise required or permitted to by applicable law). </p>


                        <p> Purpose Legal Basis <br />
                            Provide the Platform and Services Necessary for the performance of our agreement
                            Technical support Legitimate interests, necessary for the performance of our agreement (when
                            related to provision of the App)
                        </p>

                        <p> Improving the App Legitimate interests <br />
                            Business operations Legitimate interests <br />
                            Promotions Consent (when you have validly consented to use for a specific promotion), legitimate
                            interests <br />
                            Improving advertising campaigns Legitimate interests <br />
                            Sending periodic emails Consent (when consented to such marketing), legitimate interests</p>



                        <p>
                            Communications Consent (when consented to such communications), legitimate interests <br />
                            Security and legal compliance Legal obligation, legitimate interests, necessary for the performance of
                            our agreement <br />
                            Business Legitimate interests, legal obligation (when communicating with EEA, U.K. and Swiss
                            regulatory bodies)<br />
                            Consent Consent <br />
                            Where we rely on your consent, you have the right to withdraw it anytime (though this will not affect the
                            lawfulness of any processing undertaken before your withdrawal). To withdraw consent or request
                            information about the legal bases under which we process your personal information, please contact us
                            at contact@Paiverse.com. </p>

                        <p> International Transfers of Personal Data <br />
                            Information we collect may be transferred to, stored and processed in, the United States, or any other
                            country in which we or our affiliates or subcontractors maintain facilities, as described above. To the
                            extent we may transfer personal data to or from the EEA, U.K., or Switzerland, we will take steps that are
                            reasonably necessary to ensure that your personal information is treated securely and appropriately
                            safeguarded in accordance with this Policy and applicable data protection or privacy laws, such as the
                            use of standard contractual clauses. </p>

                        <p> Please contact us at contactus@paladininvesting.com if you want further information on the specific
                            mechanism used by us when transferring your personal information out of the EEA, U.K. or Switzerland. </p>

                        <p> Your Rights <br />
                            If you are in the EEA, U.K., or Switzerland, you have certain rights. You may ask us to take the following
                            actions in relation to the personal information we hold: <br />
                            Opt-out. <br />
                            Stop sending you direct marketing communications which you have previously consented to receive. We
                            may continue to send you Service-related and other non-marketing communications. </p>


                        <p> Access. <br />
                            Provide you with information about our processing of your personal information and give you access to
                            your personal information. </p>

                        <p> Correct. <br />
                            Update or correct inaccuracies in your personal information. </p>

                        <p> Delete. <br />
                            Delete your personal information. <br />
                            Transfer. <br />
                            Transfer a machine-readable copy of your personal information to you or a third party of your choice. <br />
                            Restrict. <br />
                            Restrict the processing of your personal information. <br />
                            Object.
                        </p>

                        <p>
                            Object to our reliance on our legitimate interests as the basis of our processing of your personal
                            information that impacts your rights.
                        </p>

                        <p> If you would like to exercise any rights afforded to you under applicable data protection or privacy laws,
                            or submit a complaint about our use of your personal information or response to your requests
                            regarding your personal information please contact us. Please see the “Exercising Your Rights” section
                            below for instructions regarding how to exercise these rights. </p>

                        <p> You may also submit a complaint directly to the data protection regulator in your jurisdiction. You can
                            find your data protection regulator here. </p>

                        <p> Exercising Your Rights <br /> Please follow the instructions and requirements described below when submitting your requests.
                            Requests that fail to comply with any of these instructions and requirements may result in delayed or no
                            response.
                        </p>

                        <p> To exercise the rights described above, you must send us a request (1) that provides sufficient
                            information to allow us to verify that (i) you are the person about whom we have collected personal
                            data, (ii) you, as the requester, are the same person as the data subject for whose information you’re
                            requesting (or such person’s parent/guardian), (2) that describes your request in sufficient detail to allow
                            us to understand, evaluate and respond to it, (3) that declares, under the penalty of perjury, that you’re
                            exercising your rights (stating which jurisdiction and applicable laws the relevant rights are governed by)
                            solely for lawful purposes, and (4) in a way that does not and would not unduly burden or otherwise
                            abuse our data request system and/or our App. Each request that meets all of these criteria, and which is
                            confirmed via email verification, will be considered a “Valid Request.” We may not respond to requests
                            that do not meet these criteria. We will use commercially reasonable efforts to determine whether a
                            request may be for harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or
                            otherwise objectionable purposes, and we reserve the right not to respond to such request. We will only
                            use your personal information provided in a Valid Request to verify your identity and complete your
                            request. You do not need an account on the Platform to submit a Valid Request. </p>

                        <p> We will work to respond to your Valid Request without undue delay and within the timeframes set out in
                            applicable data protection or privacy laws. We will not charge you a fee for making a Valid Request
                            unless your Valid Request(s) is excessive, repetitive or manifestly unfounded. If we determine that your
                            Valid Request warrants a fee, we will notify you of the fee and explain that decision before completing
                            your request.
                        </p>

                        <p> You may submit a Valid Request using the following methods: <br />
                            Email us at: contactus@paladininvesting.com cYou may also authorize an agent (an “Authorized Agent”)
                            to exercise your rights on your behalf. To do this, you must provide your Authorized Agent with written
                            permission to exercise your rights on your behalf, and we may request a copy of this written permission
                            from your Authorized Agent when they make a request on your behalf.
                        </p>

                        <p> Changes to our Privacy Policy <br />
                            We will update this Policy when necessary to reflect customer feedback and changes to our Platform or
                            Services. When we post changes to this statement, we will revise the "last updated" date at the top of
                            the statement. If there are material changes to the Policy or in how PAIVERSE will use your personal
                            information, we will notify you either by prominently posting a notice of such changes before they take
                            effect or by sending you a notification directly. We encourage you to periodically review this privacy
                            statement to learn how PAIVERSE is protecting your information.
                        </p>

                        <p> How to Contact Us <br />
                            If you have a technical or support question, please contact <Link to={'mailto:contactus@paladininvesting.com'} target="_blank" className="blueText"> contactus@paladininvesting.com.</Link></p>



                    </div>
                    <div className='mt-40'>
                        <PaiverseCommunity />
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy