import React, { useEffect, useState } from "react";
import axios from "../axiosinstance";
import PaiverseCommunity from "../components/layouts/home-6/PaiverseCommunity";
import BannerVideo from "../components/layouts/home-6/BannerVideo";
import ItemContent from "../components/layouts/Release/ItemContent";
import apiUrl from "../store/globalapiurl";
import { s3Url } from "../store/baseUrl";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";

// import ReactPaginate from 'react-paginate';

const Release = () => {
  LoadingOverlay.propTypes = undefined
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  let [skip, setSkip] = React.useState(0);
  const [visible, setVisible] = useState(false);
  const [alldata, setData] = useState([]);
  let [loading, setLoading] = useState(true);
  React.useEffect(() => {
    setLoading(true);
    skip = 0;
    setSkip(skip);
    getList();
    return () => {
      skip = 0;
      setSkip(skip);
      setVisible(false);
    };
  }, []);

  const getList = () => {
    const payload = {
      skip: skip,
      limit: 10,
      isUpComing: 0
    };
    axios
      .get(apiUrl._liveStore, { params: payload }).then((res) => {
        let data = res.data.data
        setData(data);
        setVisible(true);
        if ((skip + 10) < res.data.data.count) {
          setVisible(true);
        } else {
          setVisible(false);
        }
        setPageCount(Math.ceil(res.data.data.count / perPage))
        setLoading(false);
      })
      .catch((error) => {

      });
  };

  const loadMore = () => {
    skip = skip + 10;
    setSkip(skip);
    setLoading(true);
    window.scrollTo(0, 0)
    getList();
  };
  return (
    <>
      <section className='flat-title-page inner'>
        <h1 className="heading text-white">Releases</h1>
        <p>Take a look at some unique and rare collectible assets available through our platform.</p>
      </section>
      <div className="darkGray releases">
        <div className="tf-section">
          <div className=" themesflat-container">
            <LoadingOverlay active={loading} spinner={true} text="Loading...">
              {alldata.count &&
                alldata.rows.map((item, index) => ( 
                  <div  key={index} className="productCard releaseProduct">
                    <div className="d-flex align-items-center ">
                      <div className="leftPenal">
                        <h3>{item.brandName} {item.title}</h3>
                        <Link to={`/releaseDetails/${item.id}`} className="exploreMore">
                          Explore
                        </Link>
                      </div>

                      <div className="leftPenal">
                        {item.video ? (
                          <div className="videoBox">
                            <div className="customLoader"></div>
                            <BannerVideo videoPath={item.video} />
                          </div>
                        ) : (
                          <img src={`${s3Url}icons/${item.imageUrl}`} alt="release" />
                        )}
                      </div>
                    </div>
                  </div>
                  // 
                ))}
            </LoadingOverlay>
            {visible && (
              <div className="d-flex justify-content-center">
                <Link
                  className="exploreMore"
                  onClick={loadMore}
                >
                  <span>Load More</span>
                </Link>
              </div>
            )}
            {/* {!alldata?.rows.length && !loading && (
              <div className="noData">
                There are no NFTs
              </div>
            )} */}

          </div>
          <div className='mt-40'>
            <PaiverseCommunity />
          </div>
        </div>
      </div>
    </>

  );
};

export default Release;
